import { useAppSelector } from "@/app/hooks"
import { ImageCarousel } from "@/components/Layouts/ImageCarousel"
import { NewModal } from "@/components/Layouts/NewModal"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { selectUserPermissions } from "@/features/User/userSlice"
import { MODULE_PERMISSIONS } from "@/models/Permission"
import { Project } from "@/models/Project"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { useTranslation } from "react-i18next"
import { ProjectForm } from "../Forms/ProjectForm"

interface DetailsProjectWidgetProps {
    project: Project
}

export const DetailsProjectWidget: React.FC<DetailsProjectWidgetProps> = ({
    project,
}) => {
    const { t } = useTranslation()
    const status = useAppSelector((state) => state.projects.status)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const userPermissions = useAppSelector(selectUserPermissions)
    return (
        <div className="shadow-lg w-full h-72 relative">
            <WithLoader
                statuses={[status.read]}
                title={t("loading_project_details")}
                className="!w-full !h-72"
            >
                {userPermissions.hasOneOfPermissions([
                    MODULE_PERMISSIONS.Project.Edit,
                ]) && (
                    <div
                        onClick={() => setShowEditModal(true)}
                        className="absolute z-10 right-5 top-5 cursor-pointer bg-secondary-100 flex justify-center items-center p-1 rounded-full w-8 h-8 group transition hover:bg-primary-300"
                    >
                        <FontAwesomeIcon
                            icon={"edit"}
                            className=" text-primary-300 text-lg group-hover:text-secondary-100"
                        />
                    </div>
                )}

                <ImageCarousel
                    images={project.pictures.map((photo) => photo.path)}
                    title={project.name}
                    className="h-full w-full"
                    childContainerClassName="bottom-0 left-0 text-secondary-100"
                >
                    <div className="bg-primary-300 bg-opacity-70 to-transparent w-full p-3 px-5 text-secondary-100">
                        <div className="flex justify-between w-full h-full">
                            <div>
                                <div className="flex items-center gap-4">
                                    <h1 className="text-4xl  border-b border-b-secondary-100 w-fit font-bold mb-3 sm:text-xl">
                                        {project.name}
                                    </h1>
                                    <div className="">
                                        {(project.progress ||
                                            project.progress === 0) && (
                                            <div className="flex-col justify-center items-center gap-2 hidden sm:flex">
                                                <CircularProgressbar
                                                    className=" text-secondary-100 sm:h-12"
                                                    value={project.progress}
                                                    text={`${project.progress}%`}
                                                    styles={{
                                                        path: {
                                                            stroke: "#EBEBD3",
                                                        },
                                                        trail: {
                                                            stroke: "rgb(100 100 100)",
                                                        },
                                                        text: {
                                                            fill: "#EBEBD3",
                                                            fontSize: "1.5rem",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <span className="flex items-center gap-2">
                                    <FontAwesomeIcon icon="quote-left" />
                                    <p>{project.description}</p>
                                </span>
                                <span className="flex items-center gap-2">
                                    <FontAwesomeIcon icon="map-marker-alt" />
                                    <p>{project.address}</p>
                                </span>
                            </div>
                            <div className="h-full sm:hidden">
                                {(project.progress ||
                                    project.progress === 0) && (
                                    <div className="h-full flex flex-col justify-center items-center gap-2">
                                        <CircularProgressbar
                                            className="text-secondary-100 h-24"
                                            value={project.progress}
                                            text={`${project.progress}%`}
                                            styles={{
                                                path: {
                                                    stroke: "#EBEBD3",
                                                },
                                                trail: {
                                                    stroke: "rgb(100 100 100)",
                                                },
                                                text: {
                                                    fill: "#EBEBD3",
                                                    fontSize: "1.5rem",
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ImageCarousel>
            </WithLoader>
            <NewModal
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <ProjectForm
                    project={project}
                    closeModal={() => setShowEditModal(false)}
                />
            </NewModal>
        </div>
    )
}
