import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../app/hooks"
import { selectIsLogged } from "../features/Authentication/authSlice"
import { Navbar } from "../features/Navbar/Navbar"
import { Sidebar } from "../features/Sidebar/Sidebar"

export const Dashboard = () => {
    const isLogged = useAppSelector(selectIsLogged)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const location = useLocation()
    useEffect(() => {
        if (pathname.includes("/dashboard")) {
            if (!isLogged) {
                navigate("/auth/login?redirect=" + location.pathname)
            } else {
                if (pathname === "/dashboard/") {
                    navigate("/dashboard/projects/")
                }
            }
        }
    }, [isLogged, navigate, pathname])
    const sideBarItems = [
        [
            // {
            //     name: "home",
            //     icon: "home" as IconProp,
            //     route: "/dashboard/home/",
            // },
            // {
            //     name: "profile",
            //     icon: "user" as IconProp,
            //     route: "/dashboard/profile/",
            // },
            {
                name: "notifications",
                icon: "bell" as IconProp,
                route: "/dashboard/notifications/",
            },
        ],
        [
            {
                name: "projects",
                icon: "folder" as IconProp,
                route: "/dashboard/projects/",
            },
        ],
    ]
    return (
        <div className="flex flex-col w-full h-full z-10">
            <Navbar></Navbar>
            <div className="flex min-h-0 h-full">
                <Sidebar items={sideBarItems}></Sidebar>
                <div className="w-full h-full p-2 sm:p-0 overflow-hidden">
                    <div className="w-full h-full bg-secondary-300 rounded-lg text-primary-300 p-2 overflow-hidden box-border">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}
