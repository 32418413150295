import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { getGeneralStats } from "../adminSlice"

interface AdminHeaderProps {}

export const AdminHeader: React.FC<AdminHeaderProps> = () => {
    const { t } = useTranslation()
    const generalStats = useAppSelector((state) => state.admin.generalStats)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getGeneralStats({}))
    }, [])
    return (
        <div className="flex justify-between items-center gap-8">
            <div className="bg-[#2b75d4] text-secondary-100 rounded-lg shadow-lg flex justify-between w-full p-4 items-center">
                <FontAwesomeIcon icon="user" className="text-4xl" />
                <div className="text-center">
                    <p className="text-3xl font-bold">
                        {generalStats.userCount}
                    </p>
                    <p className="font-bold">{t("users")}</p>
                </div>
            </div>
            <div className="bg-green-400 text-secondary-100 rounded-lg shadow-lg flex justify-between w-full p-4 items-center">
                <FontAwesomeIcon icon="folder" className="text-4xl" />
                <div className="text-center">
                    <p className="text-3xl font-bold">
                        {generalStats.projectCount}
                    </p>
                    <p className="font-bold">{t("projects")}</p>
                </div>
            </div>
            <div className="bg-highlight-100 text-secondary-100 rounded-lg shadow-lg flex justify-between w-full p-4 items-center">
                <FontAwesomeIcon icon="list-check" className="text-4xl" />
                <div className="text-center">
                    <p className="text-3xl font-bold">N/A</p>
                    <p className="font-bold">{t("issues")}</p>
                </div>
            </div>
        </div>
    )
}
