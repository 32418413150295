import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
    HierarchyDropDown,
    HierarchyOptionProps,
} from "@/components/Inputs/HierarchyDropDown"
import { Form, SubmitType } from "@/features/Form/Form"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useParams } from "react-router-dom"
import {
    getProjectGroups,
    selectProjectGroups,
} from "../UserManagement/userManagementSlice"
// import { createTask } from "../Plannings/Tasks/taskSlice"
import { ResourcesIds, UsersManager } from "@/app/common"
import { SliceStatus } from "@/common/types"
import { CalendarInput } from "@/components/Inputs/CalendarInput"
import { Input } from "@/components/Inputs/Input"
import { TagsInput } from "@/components/Inputs/TagsInput"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { UsersOrGroupsInput } from "@/components/Inputs/UsersOrGroupsInput"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Tag } from "@/models/Tag"
import { Task, TaskDependency, TaskJsonInterface } from "@/models/Task"
import Slider from "rc-slider"
import { updateTaskById } from "../Plannings/planningSlice"
import {
    getProjectTasksTreesById,
    getProjectUsersById,
    selectProjectTasksTree,
    selectProjectUsers,
} from "../projectsSlice"
import { TaskDependenciesTable } from "./TaskDependenciesTable"
import {
    addTaskAssigneesById,
    addTaskDependencies,
    addTaskFollowersById,
    addTaskReviewsById,
    clearErrors,
    createTask,
    getTaskAssigneesById,
    getTaskById,
    getTaskDependenciesById,
    getTaskFollowersById,
    getTaskReviewsById,
    getTaskTags,
    removeTaskAssigneesById,
    removeTaskDependencies,
    removeTaskFollowersById,
    removeTaskReviewsById,
    selectTask,
    setTaskTags,
} from "./tasksSlice"

interface TaskFormProps {
    edit?: boolean
    editTaskId?: string
    parentTask?: Task
    createCallback?: (task: TaskJsonInterface) => void
    updateCallback?: (task: TaskJsonInterface) => void
}

const initialTaskUsers: UsersManager = {
    value: {
        users: [],
        groups: [],
    },
    add: {
        users: [],
        groups: [],
    },
    remove: {
        users: [],
        groups: [],
    },
}

export const TaskForm: React.FC<TaskFormProps> = ({
    edit = false,
    editTaskId,
    parentTask,
    createCallback,
    updateCallback,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const taskId = useParams<{ taskId: string }>().taskId ?? editTaskId ?? "-1"
    const planningId = useParams<{ planningId: string }>().planningId ?? "-1"
    const status = useAppSelector((state) => state.tasks.status)
    const planningStatus = useAppSelector((state) => state.plannings.status)
    const tagsStatus = useAppSelector((state) => state.tags.status)
    const task = useAppSelector(selectTask)
    const errors = useAppSelector((state) => state.tasks.errors)

    const [selectedParentTask, setSelectedParentTask] = useState<{
        value: string
        label: string
    }>({ value: "-1", label: "" })
    const [dependenciesToAdd, setDependenciesToAdd] = useState<
        TaskDependency[]
    >([])
    const [dependenciesToRemove, setDependenciesToRemove] = useState<
        TaskDependency[]
    >([])
    const [tags, setTags] = useState<Tag[]>([])
    const [assignees, setAssignees] = useState<UsersManager>(initialTaskUsers)
    const [reviewers, setReviewers] = useState<UsersManager>(initialTaskUsers)
    const [followers, setFollowers] = useState<UsersManager>(initialTaskUsers)
    const [localTask, setLocalTask] = useState<Task>(new Task())
    const [tasksHierarchyOptions, setTasksHierarchyOptions] = useState<
        HierarchyOptionProps[]
    >([])

    const projectTasks = useAppSelector(selectProjectTasksTree)
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const parentId =
            selectedParentTask.value !== "-1"
                ? selectedParentTask.value
                : undefined
        const payload = localTask.createUpdatePayload(parentId)
        if (edit) {
            const actions = [
                updateTaskById({
                    projectId: projectId,
                    taskId,
                    updatedPayload: payload,
                }),
            ]
            if (
                assignees.add.users.length > 0 ||
                assignees.add.groups.length > 0
            ) {
                actions.push(
                    addTaskAssigneesById({
                        projectId,
                        taskId,
                        assignees: assignees.add.users,
                        groups: assignees.add.groups,
                    }),
                )
            }
            if (
                assignees.remove.users.length > 0 ||
                assignees.remove.groups.length > 0
            ) {
                actions.push(
                    removeTaskAssigneesById({
                        projectId,
                        taskId,
                        assignees: assignees.remove.users,
                        groups: assignees.remove.groups,
                    }),
                )
            }
            if (
                reviewers.add.users.length > 0 ||
                reviewers.add.groups.length > 0
            ) {
                actions.push(
                    addTaskReviewsById({
                        projectId,
                        taskId,
                        reviewers: reviewers.add.users,
                        groups: reviewers.add.groups,
                    }),
                )
            }
            if (
                reviewers.remove.users.length > 0 ||
                reviewers.remove.groups.length > 0
            ) {
                actions.push(
                    removeTaskReviewsById({
                        projectId,
                        taskId,
                        reviewers: reviewers.remove.users,
                        groups: reviewers.remove.groups,
                    }),
                )
            }
            if (
                followers.add.users.length > 0 ||
                followers.add.groups.length > 0
            ) {
                actions.push(
                    addTaskFollowersById({
                        projectId,
                        taskId,
                        followers: followers.add.users,
                        groupFollowers: followers.add.groups,
                    }),
                )
            }
            if (
                followers.remove.users.length > 0 ||
                followers.remove.groups.length > 0
            ) {
                actions.push(
                    removeTaskFollowersById({
                        taskId,
                        followers: followers.remove.users,
                        groupFollowers: followers.remove.groups,
                    }),
                )
            }
            if (dependenciesToAdd.length > 0) {
                actions.push(
                    addTaskDependencies({
                        projectId,
                        taskId,
                        dependencies: dependenciesToAdd.map((dep) =>
                            dep.createPayload(),
                        ),
                    }),
                )
            }
            if (dependenciesToRemove.length > 0) {
                actions.push(
                    removeTaskDependencies({
                        projectId,
                        taskId,
                        dependencies: dependenciesToRemove.map((dep) => dep.id),
                    }),
                )
            }
            if (tags.map((tag) => tag.id) !== task.tags.map((tag) => tag.id)) {
                actions.push(
                    setTaskTags({
                        projectId,
                        taskId,
                        tags: tags.map((tag) => tag.id),
                    }),
                )
            }
            Promise.all(actions.map((action) => dispatch(action))).then(
                async () => {
                    if (updateCallback) {
                        updateCallback(task.toJson())
                    }
                },
            )
        } else {
            dispatch(
                createTask({
                    projectId,
                    planningId,
                    payload,
                }),
            ).then(async (payload: any) => {
                if (
                    assignees.value.users.length > 0 ||
                    assignees.value.groups.length > 0
                ) {
                    dispatch(
                        addTaskAssigneesById({
                            projectId,
                            taskId: payload.payload.data.data.id,
                            assignees: assignees.value.users,
                            groups: assignees.value.groups,
                        }),
                    )
                }
                if (
                    reviewers.value.users.length > 0 ||
                    reviewers.value.groups.length > 0
                ) {
                    dispatch(
                        addTaskReviewsById({
                            projectId,
                            taskId: payload.payload.data.data.id,
                            reviewers: reviewers.value.users,
                            groups: reviewers.value.groups,
                        }),
                    )
                }
                if (
                    followers.value.users.length > 0 ||
                    followers.value.groups.length > 0
                ) {
                    dispatch(
                        addTaskFollowersById({
                            projectId,
                            taskId: payload.payload.data.data.id,
                            followers: followers.value.users,
                            groupFollowers: followers.value.groups,
                        }),
                    )
                }
                if (dependenciesToAdd.length > 0) {
                    dispatch(
                        addTaskDependencies({
                            projectId,
                            taskId: payload.payload.data.data.id,
                            dependencies: dependenciesToAdd.map((dep) =>
                                dep.createPayload(),
                            ),
                        }),
                    )
                }
                if (
                    tags.map((tag) => tag.id) !== task.tags.map((tag) => tag.id)
                ) {
                    dispatch(
                        setTaskTags({
                            projectId,
                            taskId: payload.payload.data.data.id,
                            tags: tags.map((tag) => tag.id),
                        }),
                    )
                }
                if (createCallback) {
                    const { type, payload: successPayload } = await dispatch(
                        getTaskById({
                            projectId,
                            taskId: payload.payload.data.data.id,
                        }),
                    )
                    if (type === getTaskById.fulfilled.type) {
                        createCallback(successPayload.data.data)
                    }
                }
            })
        }
    }

    useEffect(() => {
        dispatch(getProjectTasksTreesById(projectId))
        dispatch(getProjectGroups(projectId))
        dispatch(getProjectUsersById(projectId))
        if (edit) {
            dispatch(getTaskById({ projectId, taskId }))
            dispatch(getTaskAssigneesById({ projectId, taskId }))
            dispatch(getTaskReviewsById({ projectId, taskId }))
            dispatch(getTaskFollowersById({ projectId, taskId }))
            dispatch(getTaskDependenciesById({ projectId, taskId }))
            dispatch(getTaskTags({ projectId, taskId }))
        }
    }, [])

    useEffect(() => {
        setTasksHierarchyOptions(
            projectTasks.map((task) => ({
                value: task.id,
                label: task.name,
                expanded: false,
                item: task,
            })),
        )
        if (parentTask) {
            setSelectedParentTask({
                value: parentTask.id,
                label: `${parentTask.wbs} - ${parentTask.name}`,
            })
        }
    }, [projectTasks])

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLocalTask(
            new Task({
                ...localTask.toJson(),
                [e.target.name]:
                    e.target.type === "number"
                        ? parseInt(e.target.value)
                        : e.target.value,
            }),
        )
    }

    useEffect(() => {
        if (edit) {
            setLocalTask(new Task(task.toJson()))
            setAssignees({
                add: { users: [], groups: [] },
                remove: { users: [], groups: [] },
                value: {
                    users: task.assignees.map((user) => user.id),
                    groups: task.groupsAssigned.map((group) => group.id),
                },
            })
            setReviewers({
                add: { users: [], groups: [] },
                remove: { users: [], groups: [] },
                value: {
                    users: task.reviews.map((review) => review.reviewer.id),
                    groups: [],
                },
            })
            setFollowers({
                add: { users: [], groups: [] },
                remove: { users: [], groups: [] },
                value: {
                    users: task.followers.map((user) => user.id),
                    groups: task.groupFollowers.map((group) => group.id),
                },
            })
            setTags(task.tags)
            if (task.parent) {
                setSelectedParentTask({
                    value: task.parent.id,
                    label: `${task.parent.wbs} - ${task.parent.name}`,
                })
            }
        }
    }, [task])

    useEffect(() => {
        setAssignees({
            ...assignees,
            add: {
                users: assignees.value.users.filter(
                    (id) => !task.assignees.map((user) => user.id).includes(id),
                ),
                groups: assignees.value.groups.filter(
                    (id) =>
                        !task.groupsAssigned
                            .map((group) => group.id)
                            .includes(id),
                ),
            },
            remove: {
                users: task.assignees
                    .map((user) => user.id)
                    .filter((id) => !assignees.value.users.includes(id)),
                groups: task.groupsAssigned
                    .map((group) => group.id)
                    .filter((id) => !assignees.value.groups.includes(id)),
            },
        })
    }, [assignees.value])

    useEffect(() => {
        setReviewers({
            ...reviewers,
            add: {
                users: reviewers.value.users.filter(
                    (id) =>
                        !task.reviews
                            .map((review) => review.reviewer.id)
                            .includes(id),
                ),
                groups: reviewers.value.groups.filter(
                    (id) =>
                        !task.reviews
                            .map((review) => review.reviewer.id)
                            .includes(id),
                ),
            },
            remove: {
                users: task.reviews
                    .map((review) => review.reviewer.id)
                    .filter((id) => !reviewers.value.users.includes(id)),
                groups: task.reviews
                    .map((review) => review.reviewer.id)
                    .filter((id) => !reviewers.value.groups.includes(id)),
            },
        })
    }, [reviewers.value])

    useEffect(() => {
        setFollowers({
            ...followers,
            add: {
                users: followers.value.users.filter(
                    (id) => !task.followers.map((user) => user.id).includes(id),
                ),
                groups: followers.value.groups.filter(
                    (id) =>
                        !task.groupFollowers
                            .map((group) => group.id)
                            .includes(id),
                ),
            },
            remove: {
                users: task.followers
                    .map((user) => user.id)
                    .filter((id) => !followers.value.users.includes(id)),
                groups: task.groupFollowers
                    .map((group) => group.id)
                    .filter((id) => !followers.value.groups.includes(id)),
            },
        })
    }, [followers.value])

    return (
        <div className="w-full ">
            <Form
                onSubmit={handleSubmit}
                statuses={[status.create, status.update, planningStatus.read]}
                loadingTitle={
                    planningStatus.read === SliceStatus.LOADING
                        ? t("loading_plannings")
                        : edit
                          ? t("updating_task")
                          : t("creating_task")
                }
                submitType={edit ? SubmitType.Save : SubmitType.Create}
                clearErrors={clearErrors}
                className="overflow-hidden"
            >
                {!edit ? (
                    <h1 className="font-bold text-lg mb-5">
                        {t("create_new_task")}:
                    </h1>
                ) : (
                    <h1 className="font-bold text-lg mb-5">
                        {t("edit_task")}: <span></span>
                    </h1>
                )}
                <Scrollable height="100%">
                    <Input
                        label={t("name")}
                        name="name"
                        type="text"
                        value={localTask.name}
                        onChange={handleChange}
                        placeholder={t("task_name") + "..."}
                        errors={errors.name}
                    />
                    <div className="mt-4">
                        <TextAreaInput
                            label={t("description")}
                            name="description"
                            value={localTask.description}
                            onChange={handleChange}
                            className="w-full"
                        />
                    </div>
                    {(!edit || task.children.length === 0) && (
                        <div className="my-4 flex flex-col items-center">
                            <div className="flex justify-center items-center gap-2">
                                <p className="font-bold">{t("progress")}</p>
                                <p>{localTask.progress}%</p>
                            </div>
                            <div className="w-[90%]">
                                <Slider
                                    value={localTask.progress}
                                    onChange={(value) => {
                                        setLocalTask(
                                            new Task({
                                                ...localTask.toJson(),
                                                progress: value as number,
                                            }),
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="mt-4">
                        <p className="font-bold">{t("parent_task")}:</p>
                        <HierarchyDropDown
                            value={selectedParentTask}
                            disabled={parentTask !== undefined}
                            placeholder={t("select_parent_task")}
                            onChange={
                                setSelectedParentTask as Dispatch<
                                    SetStateAction<{
                                        value: string | number
                                        label: string
                                    }>
                                >
                            }
                            options={tasksHierarchyOptions}
                            name={t("parent_task")}
                        ></HierarchyDropDown>
                    </div>
                    <div className="flex justify-between mt-4 border-y  py-4 flex-wrap">
                        <h1 className="w-full font-bold text-lg mb-4">
                            {t("dates")}:
                        </h1>
                        <div className="w-2/5">
                            <CalendarInput
                                label={t("start_date")}
                                name="startDate"
                                value={localTask.startDateInput}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-2/5">
                            <CalendarInput
                                label={t("end_date")}
                                name="endDate"
                                value={localTask.endDateInput}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="mt-4 border-y py-4">
                        <h1 className="w-full font-bold text-lg mb-4">
                            {t("resources")}:
                        </h1>
                        <div className="flex gap-4 justify-evenly flex-col items-center">
                            <div className="w-full">
                                <p className="font-bold mb-2">
                                    {t("assignees")}
                                </p>
                                <UsersOrGroupsInput
                                    value={assignees.value}
                                    setValue={(value: ResourcesIds) => {
                                        setAssignees({
                                            ...assignees,
                                            value,
                                        })
                                    }}
                                    users={projectUsers}
                                    groups={projectGroups}
                                />
                            </div>
                            <div className="w-full">
                                <p className="font-bold mb-2">
                                    {t("reviewers")}
                                </p>
                                <UsersOrGroupsInput
                                    value={reviewers.value}
                                    setValue={(value: ResourcesIds) => {
                                        setReviewers({
                                            ...reviewers,
                                            value,
                                        })
                                    }}
                                    users={projectUsers}
                                    groups={projectGroups}
                                />
                            </div>
                            <div className="w-full">
                                <p className="font-bold mb-2">
                                    {t("followers")}
                                </p>
                                <UsersOrGroupsInput
                                    value={followers.value}
                                    setValue={(value: ResourcesIds) => {
                                        setFollowers({
                                            ...followers,
                                            value,
                                        })
                                    }}
                                    users={projectUsers}
                                    groups={projectGroups}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border-y py-4">
                        <h1 className="w-full font-bold text-lg mb-4">
                            {t("dependencies")}:
                        </h1>
                        <div className="flex gap-4">
                            <TaskDependenciesTable
                                edit={edit}
                                setDependenciesToAdd={setDependenciesToAdd}
                                setDependenciesToRemove={
                                    setDependenciesToRemove
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-4 border-y py-4">
                        <h1 className="w-full font-bold text-lg mb-4">
                            {t("tags")}:
                        </h1>
                        <div className="flex gap-4">
                            <TagsInput value={tags} onChange={setTags} />
                        </div>
                    </div>
                </Scrollable>
            </Form>
        </div>
    )
}
