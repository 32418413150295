import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, ProjectFile } from "./File"
import { Project, ProjectJsonInterface } from "./Project"

export interface IfcEntityInterface extends BaseModelInterface {
    globalId: string
    progress: number
}

export class IfcEntity extends BaseModel {
    public globalId: string
    public progress: number

    constructor(data?: IfcEntityInterface) {
        super(data)
        this.globalId = data?.globalId ?? ""
        this.progress = data?.progress ?? 0
    }

    public toJson(): IfcEntityInterface {
        return {
            ...super.toJson(),
            globalId: this.globalId,
            progress: this.progress,
        }
    }
}

export interface IfcModelInterface extends BaseModelInterface {
    name: string
    description: string
    project: ProjectJsonInterface
    entities: IfcEntityInterface[]
    files: FileJsonInterface[]
}

export class IfcModel extends BaseModel {
    public name: string
    public description: string
    public project: Project
    public entities: IfcEntity[]
    public files: ProjectFile[]

    constructor(data?: IfcModelInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.project =
            data && data.project ? new Project(data.project) : new Project()
        this.entities =
            data?.entities?.map((entity) => new IfcEntity(entity)) ?? []
        this.files = data?.files?.map((file) => new ProjectFile(file)) ?? []
    }

    public toJson(): IfcModelInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            project: this.project.toJson(),
            entities: this.entities.map((entity) => entity.toJson()),
            files: this.files.map((file) => file.toJson()),
        }
    }
}
