import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Form, SubmitType } from "@/features/Form/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FragmentIdMap } from "@thatopen/fragments"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getProjectTasksTreesById } from "../../projectsSlice"
import { IfcHandler } from "../IfcHandler"
import {
    clearErrors,
    selectIfcEntities,
    updateIfcEntitiesProgress,
} from "../ifcSlice"

interface SetEntityProgressFormProps {
    ifcHandler: IfcHandler | null
    selection: FragmentIdMap | null
    ifcFileId: string
    closeModal: () => void
}

export const SetEntityProgressForm: FC<SetEntityProgressFormProps> = ({
    ifcHandler,
    selection,
    ifcFileId,
    closeModal,
}) => {
    const [selectedEntities, setSelectedEntities] = useState<
        {
            name: string
            guid: string
            expressId: number
            progress: number
        }[]
    >([])
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const ifcEntities = useAppSelector(selectIfcEntities)
    const status = useAppSelector((state) => state.ifcViewer.status)
    const [localStatus, setLocalStatus] = useState<SliceStatus>(
        SliceStatus.IDLE,
    )
    const [setAllProgress, setSetAllProgress] = useState<number>(100)
    const [localEntitiesProgress, setLocalEntitiesProgress] = useState<
        Record<string, number>
    >({})
    useEffect(() => {
        dispatch(getProjectTasksTreesById(projectId))
    }, [])
    useEffect(() => {
        if (ifcHandler && selection) {
            setLocalStatus(SliceStatus.LOADING)
            ifcHandler.getSelectionEntities(selection).then((entities) => {
                const selectedEntities = entities
                    // filter undefined and duplicate entity.guid
                    .filter(
                        (entity, index, self) =>
                            entity &&
                            index ===
                                self.findIndex((t) => t.guid === entity.guid),
                    )

                    .map((entity) => ({
                        ...entity,
                        progress: 0,
                    }))
                setSelectedEntities(selectedEntities)
                setLocalStatus(SliceStatus.IDLE)
            })
        }
    }, [selection])

    useEffect(() => {
        const selectedEntitiesGuid = selectedEntities.map(
            (entity) => entity.guid,
        )
        const entities = ifcEntities.filter((entity) =>
            selectedEntitiesGuid.includes(entity.globalId),
        )
        const entitiesRecord: Record<string, number> = {}
        for (const entity of entities) {
            entitiesRecord[entity.id] = entity.progress
        }
        setLocalEntitiesProgress(entitiesRecord)
    }, [ifcEntities, selectedEntities, selection])

    return (
        <Form
            statuses={[status.update, localStatus]}
            loadingTitle={t("updating_entities")}
            className="w-full"
            onCancel={closeModal}
            onSubmit={async () => {
                const { type } = await dispatch(
                    updateIfcEntitiesProgress({
                        projectId,
                        entities: localEntitiesProgress,
                    }),
                )
                if (type === updateIfcEntitiesProgress.fulfilled.type) {
                    closeModal()
                }
            }}
            clearErrors={clearErrors}
            submitType={SubmitType.Save}
        >
            <h1 className="font-bold text-lg mb-5">
                {t("entities_progress")}:
            </h1>
            <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center gap-2 w-full justify-end">
                    <Input
                        type="number"
                        value={setAllProgress}
                        name={t("set_all_progress")}
                        className="!m-0"
                        onChange={(e) => {
                            const allProgress = parseInt(e.target.value)
                            if (allProgress > 100 || allProgress < 0) return
                            setSetAllProgress(parseInt(e.target.value))
                        }}
                    ></Input>
                    <p>%</p>
                    <Button
                        className="!bg-green-400 !border-green-400 !text-secondary-100 hover:!bg-green-600 hover:!border-green-600 transition"
                        onClick={() => {
                            setLocalEntitiesProgress((prev) => {
                                const newProgress: Record<string, number> = {}
                                for (const key in prev) {
                                    newProgress[key] = setAllProgress
                                }
                                return newProgress
                            })
                        }}
                        name={t("set_all_progress")}
                    ></Button>
                </div>
                <Scrollable height="300px" className="w-full">
                    {selectedEntities.map((entity) => {
                        const entityModel = ifcEntities.find(
                            (ifcEntity) => ifcEntity.globalId === entity.guid,
                        )
                        const localEntityProgress =
                            localEntitiesProgress[entityModel?.id ?? ""]
                        return (
                            entityModel && (
                                <div
                                    key={entityModel.id}
                                    className="flex items-center gap-2 w-full"
                                >
                                    <h2 className="w-1/3">{entity.name}</h2>
                                    <div className="p-2 px-4 w-2/3 flex items-center justify-center gap-4">
                                        <Slider
                                            value={localEntityProgress}
                                            className=""
                                            onChange={(value) => {
                                                setLocalEntitiesProgress({
                                                    ...localEntitiesProgress,
                                                    [entityModel.id]:
                                                        value as number,
                                                })
                                            }}
                                        />
                                        <p>{localEntityProgress}%</p>
                                        <FontAwesomeIcon
                                            icon="times"
                                            className="text-red-400 transitions hover:text-red-600 cursor-pointer"
                                            onClick={() => {
                                                setLocalEntitiesProgress({
                                                    ...localEntitiesProgress,
                                                    [entityModel.id]: 0,
                                                })
                                            }}
                                        ></FontAwesomeIcon>
                                    </div>
                                </div>
                            )
                        )
                    })}
                </Scrollable>
            </div>
        </Form>
    )
}
