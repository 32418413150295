import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { ArrayFilter } from "./ArrayFilter"
import { DateFilter } from "./DateFilter"
import {
    BimFilesFilterFields,
    CommonFilterFields,
    FilterFields,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters"
import { NumberFilter } from "./NumberFilter"
import { StringFilter } from "./StringFilter"

interface FilterContainerProps {
    filters: FilterValuesType<FilterFields>
    field: FilterFields
    setFilters: ActionCreatorWithPayload<FilterValuesType<FilterFields>, any>
}

const FilterFieldMapping = {
    [CommonFilterFields.Name]: StringFilter,

    [TaskFilterFields.TaskStatus]: ArrayFilter,
    [TaskFilterFields.WBS]: StringFilter,
    [TaskFilterFields.StartDate]: DateFilter,
    [TaskFilterFields.EndDate]: DateFilter,
    [TaskFilterFields.Duration]: NumberFilter,
    [TaskFilterFields.Progress]: NumberFilter,
    [TaskFilterFields.Tags]: ArrayFilter,
    [TaskFilterFields.Assignees]: ArrayFilter,
    [TaskFilterFields.Reviewers]: ArrayFilter,
    [TaskFilterFields.StatusDetail]: ArrayFilter,
    [TaskFilterFields.DelayStatus]: ArrayFilter,
    [TaskFilterFields.StartDelay]: NumberFilter,
    [TaskFilterFields.EndDelay]: NumberFilter,

    [BimFilesFilterFields.Task]: ArrayFilter,
    [BimFilesFilterFields.Project]: ArrayFilter,
    [BimFilesFilterFields.Originator]: ArrayFilter,
    [BimFilesFilterFields.FunctionalBreakdown]: ArrayFilter,
    [BimFilesFilterFields.SpatialBreakdown]: ArrayFilter,
    [BimFilesFilterFields.Form]: ArrayFilter,
    [BimFilesFilterFields.Discipline]: ArrayFilter,
    [BimFilesFilterFields.Number]: ArrayFilter,
    [BimFilesFilterFields.Phase]: ArrayFilter,
    [BimFilesFilterFields.Assignee]: ArrayFilter,
    [BimFilesFilterFields.Reviewers]: ArrayFilter,
    [BimFilesFilterFields.BimFileStatus]: ArrayFilter,
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
    filters,
    field,
    setFilters,
}) => {
    const FilterComponent = FilterFieldMapping[field]
    const containerRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const [hidden, setHidden] = useState<boolean>(true)
    const [cords, setCords] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    })
    useEffect(() => {
        if (containerRef.current) {
            const parent = containerRef.current.parentElement
            if (parent) {
                const table = parent.parentElement
                if (table) {
                    const rect = parent.getBoundingClientRect()
                    const currentRect =
                        containerRef.current.getBoundingClientRect()
                    const tableRect = table.getBoundingClientRect()
                    if (currentRect.right > tableRect.right) {
                        setCords({
                            x: 0 - currentRect.width,
                            y: rect.height,
                        })
                    } else {
                        setCords({
                            x: 0,
                            y: rect.height,
                        })
                    }
                }
                setHidden(false)
            }
        }
    }, [containerRef])
    return (
        <div
            className={`absolute z-50 bg-secondary-100 text-primary-300 p-2 flex flex-col gap-2 shadow-lg  ${hidden ? "opacity-0" : ""}`}
            ref={containerRef}
            style={{ top: cords.y, left: cords.x, minWidth: "200px" }}
        >
            <FontAwesomeIcon
                className="self-end cursor-pointer text-lg"
                icon="times"
                onClick={() =>
                    dispatch(
                        setFilters({
                            ...filters,
                            [field]: {
                                ...filters[field],
                                isShown: false,
                            },
                        }),
                    )
                }
            />
            <FilterComponent
                filters={filters}
                field={field}
                setFilters={setFilters}
            />
            <div>
                <Button
                    className="ml-auto"
                    name={t("clear")}
                    onClick={() => {
                        dispatch(
                            setFilters({
                                ...filters,
                                [field]: {
                                    ...filters[field],
                                    value: "",
                                    isShown: false,
                                },
                            }),
                        )
                    }}
                ></Button>
            </div>
        </div>
    )
}
