import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import {
    ContextMenuItem,
    WithContextMenu,
} from "@/components/Menus/WithContextMenu"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { ProjectFile, ProjectFileTypeEnum } from "@/models/File"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"
import { clearErrors, deleteFile, renameFile } from "../fileManagerSlice"
import { FileManagerPermissionForm } from "../Forms/FileManagerPermissionForm"
import { BimNominationCellMap } from "./BrowserBimFileTable"

interface BrowserBimFileTableRowProps {
    file: ProjectFile
}

export const BrowserBimFileTableRow: React.FC<BrowserBimFileTableRowProps> = ({
    file,
}) => {
    const { t } = useTranslation()
    const projectId = useParams<{ projectId: string }>().projectId ?? ""

    const [showPermissionsModal, setShowPermissionsModal] =
        useState<boolean>(false)
    const userPermissions = useAppSelector(selectUserPermissions)
    const errors = useAppSelector((state) => state.fileManager.errors)
    const location = useLocation()
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [actions, setActions] = useState<ContextMenuItem[]>([])
    const [showImage, setShowImage] = useState<boolean>(false)
    const [newName, setNewName] = useState<string>(file.name)
    const status = useAppSelector((state) => state.fileManager.oneStatus)
    const downloadRef = useRef<HTMLAnchorElement>(null)
    const dispatch = useAppDispatch()
    const bimTableColumns = useAppSelector(
        (state) => state.fileManager.bimTableColumns,
    )
    useEffect(() => {
        const actionsItems: ContextMenuItem[] = []
        if (userPermissions.hasAllPermissions([`file.${file.id}.read`])) {
            if (
                file.type === ProjectFileTypeEnum.PHOTO ||
                file.type === ProjectFileTypeEnum.PROJECT_PICTURE
            ) {
                actionsItems.push({
                    name: t("view"),
                    icon: "eye" as IconProp,
                    onClick: () => {
                        setShowImage(true)
                    },
                })
            }
            actionsItems.push({
                name: t("copy_link"),
                icon: "link" as IconProp,
                onClick: () => {
                    navigator.clipboard.writeText(
                        `${import.meta.env.VITE_FRONTEND_URL}${location.pathname}?resource=${file.id}`,
                    )
                },
            })
            actionsItems.push({
                name: t("download"),
                icon: "download" as IconProp,
                onClick: () => {
                    downloadRef.current?.click()
                },
            })
        }
        if (userPermissions.hasAllPermissions([`file.${file.id}.write`])) {
            actionsItems.push({
                name: t("rename"),
                icon: "edit" as IconProp,
                onClick: () => {
                    setShowRenameModal(true)
                },
            })
            actionsItems.push({
                name: t("delete"),
                icon: "trash" as IconProp,
                onClick: () => {
                    setShowDeleteModal(true)
                },
            })
            actionsItems.push({
                name: t("permissions"),
                icon: "user-group" as IconProp,
                onClick: () => {
                    setShowPermissionsModal(true)
                },
            })
        }
        setActions(actionsItems)
    }, [userPermissions])
    return (
        <WithContextMenu items={actions}>
            <div className="flex items-center border-b hover:bg-primary-100 hover:text-secondary-100 transition p-1">
                {Object.values(bimTableColumns).map((column) =>
                    BimNominationCellMap[column].getComponent(
                        file,
                        BimNominationCellMap[column].width,
                        projectId,
                    ),
                )}
            </div>
            <NewModal
                isShown={showImage}
                closeModal={() => setShowImage(false)}
            >
                <div>
                    <img
                        src={file.path}
                        alt={file.name}
                        className="m-auto object-cover w-2/4"
                    />
                </div>
            </NewModal>
            <NewModal
                isShown={showPermissionsModal}
                closeModal={() => setShowPermissionsModal(false)}
            >
                <FileManagerPermissionForm
                    resource={file}
                    closeModal={() => setShowPermissionsModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showRenameModal}
                closeModal={() => setShowRenameModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("renaming_file")}
                        statuses={[status.update]}
                        onSubmit={async (e) => {
                            e.preventDefault()
                            const { type } = await dispatch(
                                renameFile({
                                    projectId,
                                    fileId: file.id,
                                    name: newName,
                                }),
                            )
                            if (type === renameFile.fulfilled.type) {
                                setShowRenameModal(false)
                            }
                        }}
                        onCancel={() => setShowRenameModal(false)}
                        submitType={SubmitType.Save}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("renaming_file")} `}
                                <span className="italic font-normal">
                                    {file.name}
                                </span>
                            </h1>
                            <Input
                                type="text"
                                name={t("new_name")}
                                label={t("new_name")}
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                className="!m-0"
                                errors={errors.name}
                            />
                        </div>
                    </Form>
                </div>
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("deleting_file")}
                        statuses={[status.delete]}
                        onSubmit={() => {
                            dispatch(
                                deleteFile({ fileId: file.id, projectId }),
                            ).then(() => {
                                setShowDeleteModal(false)
                            })
                        }}
                        submitType={SubmitType.Delete}
                        onCancel={() => setShowDeleteModal(false)}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("deleting_file")} `}
                                <span className="italic font-normal">
                                    {file.name}
                                </span>
                            </h1>
                            <p>
                                {t("are_you_sure_you_want_to_delete_this_file")}
                            </p>
                        </div>
                    </Form>
                </div>
            </NewModal>
            <Link
                className="hidden"
                ref={downloadRef}
                to={file.path}
                download={true}
                target="_blank"
                rel="noopener noreferrer"
            ></Link>
        </WithContextMenu>
    )
}
