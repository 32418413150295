import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { Budget } from "@/models/Finance"
import { Task } from "@/models/Task"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

interface BudgetPerTaskTableProps {
    budget: Budget
}

export const BudgetPerTaskTable: React.FC<BudgetPerTaskTableProps> = ({
    budget,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const columns = useMemo<MRT_ColumnDef<Task>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("task"),
            },
            {
                header: t("description"),
                Cell: ({ row }) => {
                    const task = row.original
                    return <span>{task.description}</span>
                },
            },
            {
                header: t("total_articles"),
                Cell: ({ row }) => {
                    const task = row.original
                    return <span>{task.articles.length}</span>
                },
            },
            {
                header: t("total_price"),
                Cell: ({ row }) => {
                    const task = row.original
                    return (
                        <span>
                            {task.articles.reduce(
                                (acc, article) =>
                                    acc + parseInt(article.totalPrice),
                                0,
                            )}{" "}
                            {t(budget.currency)}
                        </span>
                    )
                },
            },
            {
                accessorKey: "status",
                header: t("task_status"),
                Cell: ({ row }) => {
                    const task = row.original
                    return (
                        <span>
                            <TaskStatusBar
                                taskStatus={task.status}
                            ></TaskStatusBar>
                        </span>
                    )
                },
            },
        ],
        [],
    )
    const table = useMantineReactTable<Task>({
        columns,
        data: budget.tasks,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                window.open(
                    `/dashboard/projects/${projectId}/tasks/${row.original.id}`,
                )
            },
            sx: {
                cursor: "pointer", //you might want to change the cursor too when adding an onClick
            },
        }),
        positionPagination: "top",
    })

    return <MantineReactTable table={table} />
}
