import { axiosAuthenticated, axiosPublic } from "@/common/axios"
import { NotificationInterface } from "@/models/Notification"
import {
    UserInterface,
    UserRegisterInterface,
    UserUpdatePayload,
} from "@/models/User"
import { setUploadPercentage } from "./authSlice"

declare interface AuthLoginResponse {
    data: {
        user: UserInterface
        accessToken: string
    }
    notification: NotificationInterface
}

export const authLogin = (email: string, password: string) => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) =>
        axiosPublic
            .post("/auth/login", {
                email,
                password,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}

export const authRegister = (registerPayload: UserRegisterInterface) => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) =>
        axiosPublic
            .post("/auth/register", registerPayload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}

export const authValidate = () => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) => {
        const token = localStorage.getItem("accessToken")

        axiosPublic
            .post(
                "/auth/validate",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token || ""}`,
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const verifyEmailApi = (token: string) => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) =>
        axiosPublic
            .post("/auth/verify-email", { token })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}

export const updateUserApi = (userId: string, user: UserUpdatePayload) => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) =>
        axiosAuthenticated()
            .put(`/users/${userId}`, user)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}

export const changeUserProfilePictureApi = (
    userId: string,
    payload: FormData,
    dispatch: any,
) => {
    return new Promise<{ data: AuthLoginResponse }>((resolve, reject) =>
        axiosAuthenticated()
            .put(`/users/${userId}/picture`, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        dispatch(setUploadPercentage(progress))
                    }
                },
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            }),
    )
}
