import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Form, SubmitType } from "../Form/Form"
import { clearErrors, login } from "./authSlice"

interface isValid {
    [key: string]: boolean
}

export const Login = () => {
    const [email, setEmail] = React.useState<string>("")
    const [password, setPassword] = React.useState<string>("")
    const [isValid, setIsValid] = React.useState<isValid>({ email: false })
    const status = useAppSelector((state) => state.auth.status)
    const errors = useAppSelector((state) => state.auth.errors)

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [queryParams] = useSearchParams()
    const navigate = useNavigate()

    const setValid = (key: string, value: boolean) => {
        setIsValid((prevState) => ({ ...prevState, [key]: value }))
    }
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { type } = await dispatch(login({ email, password }))
        if (type === login.fulfilled.type) {
            const redirect = queryParams.get("redirect")
            if (redirect) {
                navigate(redirect)
            }
        }
    }
    return (
        <div className="text-primary-300 bg-secondary-100 w-[500px] px-12 py-8 h-full flex items-center justify-center lg:w-full">
            <div className="w-96 h-full flex justify-center items-center flex-col">
                <div className="hidden lg:block">
                    <div className="w-full lg:flex items-center gap-2 justify-center my-8 ">
                        <BimpackLogo
                            primaryColor="#052A51"
                            secondaryColor="#083D77"
                            logoSize="h-16 w-16"
                        />
                        <h1 className="font-bold text-6xl text-primary-100">
                            Bimpack
                        </h1>
                    </div>
                </div>
                <h1 className="font-bold text-3xl w-full text-center">
                    {t("login")}
                </h1>
                <Form
                    onSubmit={onSubmit}
                    statuses={[status.read]}
                    loadingTitle={t("logging_in")}
                    className="w-full"
                    customSubmitButton={
                        <Button
                            name={t("login")}
                            className="mt-2 w-full"
                            formValidator={isValid}
                            type="submit"
                        ></Button>
                    }
                    submitType={SubmitType.Submit}
                    clearErrors={clearErrors}
                >
                    <Input
                        name="email"
                        setValid={setValid}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        label={t("email")}
                        placeholder="jhon@smith.com"
                        className="w-full"
                    ></Input>
                    <Input
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        label={t("password")}
                        placeholder="***"
                        className="w-full"
                    ></Input>
                    <div className="w-full text-right">
                        <a
                            href="facebook.com"
                            className="text-right ml-auto text-sm"
                        >
                            {t("forgot")}
                        </a>
                    </div>
                </Form>
                <span className="flex w-full justify-center">
                    <p className="mr-1">{t("dont_have_an_account")}?</p>
                    <Link to="/auth/register">
                        <p className="border-b border-b-primary-300 cursor-pointer">
                            {t("register")}
                        </p>
                    </Link>
                </span>
            </div>
        </div>
    )
}
