import { SliceStatus } from "@/common/types"
import { ThreeDotsLoader } from "./ThreeDotsLoader"

interface WithLoaderProps {
    title: string
    statuses: SliceStatus[]
    children: React.ReactNode
    className?: string
}

export const WithLoader: React.FC<WithLoaderProps> = ({
    title,
    statuses,
    children,
    className,
}) => {
    return (
        <div
            className={`${className + " flex flex-col items-center h-full justify-center w-full min-w-[400px]"}`}
        >
            {statuses.some((status) => status === SliceStatus.LOADING) && (
                <div className="flex flex-col items-center w-full h-full justify-center">
                    <ThreeDotsLoader text={title} />
                </div>
            )}
            <div className="w-full h-full flex flex-col items-start justify-start ">
                {statuses.every((status) => status !== SliceStatus.LOADING) &&
                    children}
            </div>
        </div>
    )
}
