import { RootState } from "@/app/store"
import { CalendarZoomModes } from "@/common/types"
import { TaskStatus } from "@/models/Task"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    addDays,
    addQuarters,
    addWeeks,
    addYears,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachWeekOfInterval,
    eachYearOfInterval,
    lastDayOfQuarter,
    lastDayOfWeek,
    lastDayOfYear,
    subDays,
    subQuarters,
    subWeeks,
    subYears,
} from "date-fns"
import {
    CommonFilterFields,
    FilterFields,
    FilterOperators,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters/Filters"

const mapping = {
    [CalendarZoomModes.Day]: {
        secondHeaderUnit: 80,
        firstHeaderIntervalFn: eachWeekOfInterval,
        secondHeaderIntervalFn: eachDayOfInterval,
        initialStartDateFn: (date: Date) =>
            addDays(lastDayOfWeek(subWeeks(new Date(date), 2)), 1),
        initialEndDateFn: (date: Date) =>
            addWeeks(lastDayOfWeek(new Date(date)), 2),
        nextDate: (date: Date) => addWeeks(new Date(date), 1),
    },
    [CalendarZoomModes.Week]: {
        secondHeaderUnit: 32,
        firstHeaderIntervalFn: eachWeekOfInterval,
        secondHeaderIntervalFn: eachDayOfInterval,
        initialStartDateFn: (date: Date) =>
            addDays(lastDayOfWeek(subWeeks(new Date(date), 2)), 1),
        initialEndDateFn: (date: Date) =>
            addWeeks(lastDayOfWeek(new Date(date)), 2),
        nextDate: (date: Date) => addWeeks(new Date(date), 1),
    },
    [CalendarZoomModes.Month]: {
        secondHeaderUnit: 90,
        firstHeaderIntervalFn: eachQuarterOfInterval,
        secondHeaderIntervalFn: eachMonthOfInterval,
        initialStartDateFn: (date: Date) =>
            addDays(lastDayOfQuarter(subQuarters(new Date(date), 3)), 1),
        initialEndDateFn: (date: Date) =>
            addQuarters(lastDayOfQuarter(new Date(date)), 2),
        nextDate: (date: Date) => addDays(lastDayOfQuarter(new Date(date)), 1),
    },
    [CalendarZoomModes.Quarter]: {
        secondHeaderUnit: 120,
        firstHeaderIntervalFn: eachYearOfInterval,
        secondHeaderIntervalFn: eachQuarterOfInterval,
        initialStartDateFn: (date: Date) =>
            addDays(lastDayOfYear(subYears(new Date(date), 2)), 1),
        initialEndDateFn: (date: Date) =>
            addYears(lastDayOfYear(new Date(date)), 2),
        nextDate: (date: Date) => addYears(new Date(date), 1),
    },
}

export interface GanttFilters {
    search: string
    assignees: string[]
    reviewers: string[]
    tags: string[]
    statuses: TaskStatus[]
}

export interface GanttState {
    calendar: {
        startDate: string
        endDate: string
        zoom: CalendarZoomModes
        unit: number
        dates: { date: string; interval: string[] }[]
    }
    filters: FilterValuesType<TaskFilterFields>
    tableColumns: FilterFields[]
}

const filtersInitialState = {
    [TaskFilterFields.WBS]: {
        value: "",
        operator: FilterOperators.StringOperators.Equals,
        isShown: false,
    },
    [CommonFilterFields.Name]: {
        value: "",
        operator: FilterOperators.StringOperators.Equals,
        isShown: false,
    },
    [TaskFilterFields.StartDate]: {
        value: null,
        operator: FilterOperators.DateOperators.DatesEquals,
        isShown: false,
    },
    [TaskFilterFields.EndDate]: {
        value: null,
        operator: FilterOperators.DateOperators.DatesEquals,
        isShown: false,
    },
    [TaskFilterFields.TaskStatus]: {
        value: "",
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [TaskFilterFields.Duration]: {
        value: null,
        operator: FilterOperators.NumberOperators.NumberEquals,
        isShown: false,
    },
    [TaskFilterFields.Progress]: {
        value: null,
        operator: FilterOperators.NumberOperators.NumberEquals,
        isShown: false,
    },
    [TaskFilterFields.Assignees]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [TaskFilterFields.Reviewers]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [TaskFilterFields.Tags]: {
        value: null,
        operator: FilterOperators.ArrayOperators.ArrayContains,
        isShown: false,
    },
    [TaskFilterFields.StatusDetail]: {
        value: "",
        operator: FilterOperators.StringOperators.Equals,
        isShown: false,
    },
    [TaskFilterFields.DelayStatus]: {
        value: "",
        operator: FilterOperators.StringOperators.Equals,
        isShown: false,
    },
    [TaskFilterFields.StartDelay]: {
        value: null,
        operator: FilterOperators.NumberOperators.NumberEquals,
        isShown: false,
    },
    [TaskFilterFields.EndDelay]: {
        value: null,
        operator: FilterOperators.NumberOperators.NumberEquals,
        isShown: false,
    },
}

const initialState: GanttState = {
    calendar: {
        startDate: new Date(
            new Date().setDate(new Date().getDate() - 7),
        ).toISOString(),
        endDate: new Date(
            new Date().setDate(new Date().getDate() + 7),
        ).toISOString(),
        zoom: CalendarZoomModes.Month,
        unit: 120,
        dates: [{ date: "", interval: [] }],
    },
    filters: filtersInitialState,
    tableColumns: [
        TaskFilterFields.WBS,
        CommonFilterFields.Name,
        TaskFilterFields.StartDate,
        TaskFilterFields.EndDate,
        TaskFilterFields.TaskStatus,
        TaskFilterFields.DelayStatus,
        TaskFilterFields.Duration,
        TaskFilterFields.Progress,
    ],
}

export const ganttSlice = createSlice({
    name: "gantt",
    initialState,
    reducers: {
        setZoom: (state, action) => {
            state.calendar.zoom = action.payload
        },
        initCalendar: (state, action) => {
            let startDate = new Date(action.payload.startDate)
            let endDate = new Date(action.payload.endDate)
            state.calendar.startDate = mapping[state.calendar.zoom]
                .initialStartDateFn(startDate)
                .toISOString()
            state.calendar.endDate = mapping[state.calendar.zoom]
                .initialEndDateFn(endDate)
                .toISOString()
            state.calendar.unit = mapping[state.calendar.zoom].secondHeaderUnit
            const mainInterval = mapping[
                state.calendar.zoom
            ].firstHeaderIntervalFn({
                start: new Date(state.calendar.startDate),
                end: new Date(state.calendar.endDate),
            })
            const dates = []
            for (let i = 0; i < mainInterval.length; i++) {
                if (i + 1 < mainInterval.length) {
                    const date = mainInterval[i].toISOString()
                    const interval = mapping[state.calendar.zoom]
                        .secondHeaderIntervalFn({
                            start: mainInterval[i],
                            end: subDays(
                                mapping[state.calendar.zoom].nextDate(
                                    mainInterval[i],
                                ),
                                1,
                            ),
                        })
                        .map((date) => date.toISOString())

                    dates.push({
                        date,
                        interval,
                    })
                }
            }
            state.calendar.dates = dates
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        resetFilters: (state, action) => {
            state.filters = filtersInitialState
        },
        toggleFilter: (state, action: { payload: TaskFilterFields }) => {
            Object.keys(state.filters).forEach((key) => {
                if (key !== action.payload) {
                    state.filters[key as TaskFilterFields].isShown = false
                }
            })
            state.filters[action.payload].isShown =
                !state.filters[action.payload].isShown
        },
        closeAllFilters: (state) => {
            Object.keys(state.filters).forEach((key) => {
                state.filters[key as TaskFilterFields].isShown = false
            })
        },
        closeFilter: (state, action: { payload: TaskFilterFields }) => {
            state.filters[action.payload].isShown = false
        },
        setTableColumns: (state, action) => {
            // order columns by the specified ordier columnOrder initial order
            const columns = action.payload
            const columnOrder = [
                TaskFilterFields.WBS,
                CommonFilterFields.Name,
                TaskFilterFields.StartDate,
                TaskFilterFields.EndDate,
                TaskFilterFields.Duration,
                TaskFilterFields.Progress,
                TaskFilterFields.TaskStatus,
                TaskFilterFields.StatusDetail,
                TaskFilterFields.DelayStatus,
                TaskFilterFields.StartDelay,
                TaskFilterFields.EndDelay,
                TaskFilterFields.Duration,
                TaskFilterFields.Assignees,
                TaskFilterFields.Reviewers,
                TaskFilterFields.Tags,
            ]
            const orderedColumns = columnOrder.filter((column) =>
                columns.includes(column),
            )
            state.tableColumns = orderedColumns
        },
    },
})

const selectRawGanttCalendar = (state: RootState) => state.gantt.calendar
export const selectGanttCalendar = createSelector(
    [selectRawGanttCalendar],
    (calendar) => ({
        ...calendar,
        startDate: new Date(calendar.startDate),
        endDate: new Date(calendar.endDate),
        dates: calendar.dates.map((date) => ({
            date: new Date(date.date),
            interval: date.interval.map((interval) => new Date(interval)),
        })),
    }),
)

export const {
    initCalendar,
    setZoom,
    setFilters,
    toggleFilter,
    closeAllFilters,
    closeFilter,
    resetFilters,
    setTableColumns,
} = ganttSlice.actions
export default ganttSlice.reducer
