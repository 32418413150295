import { axiosAuthenticated } from "@/common/axios"
import { ProjectJsonInterface } from "@/models/Project"
import { TaskFilters, TaskJsonInterface } from "@/models/Task"
import { setImageUploadProgress } from "./projectsSlice"

export const getAuthenticatedUserProjects = () => {
    return new Promise<{ data: ProjectJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get("/projects/all")
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getAuthenticatedUserSharedProjects = () => {
    return new Promise<{ data: ProjectJsonInterface[] }>((resolve, reject) => {
        axiosAuthenticated()
            .get("/projects/shared")
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectByIdApi = (id: string) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${id}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectResourcesByIdApi = (id: string) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${id}/resources`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectUsersByIdApi = (id: string) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${id}/users`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createProjectApi = (payload: any) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/create`, { payload })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateProjectApi = (projectId: string, payload: any) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/`, { payload })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTasksByIdApi = (projectId: string) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTasksFilteredApi = (
    projectId: string,
    filters: TaskFilters,
) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/filtered`, {
                params: {
                    status: filters.status ?? [],
                    tags: filters?.tags?.map((tag) => tag.id) ?? [],
                    assignees: filters?.assignees ?? [],
                    reviewers: filters?.reviewers ?? [],
                    createdBy: filters?.createdBy ?? [],
                    dateInterval: filters.dateInterval ?? {
                        startDate: "",
                        endDate: "",
                    },
                },
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getAllProjectTasksByIdApi = (projectId: string) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/all`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getProjectTasksTreesByIdApi = (projectId: string) => {
    return new Promise<{ data: TaskJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/all/tree`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addImagesApi = (
    projectId: string,
    payload: FormData,
    dispatch: any,
) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/images/upload`, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        dispatch(setImageUploadProgress(percentCompleted))
                    }
                },
            })
            .then((response) => {
                dispatch(setImageUploadProgress(0))
                resolve({ data: response.data })
            })
            .catch((error) => {
                dispatch(setImageUploadProgress(0))
                reject({ data: error.response.data })
            })
    })
}

export const removeImagesApi = (
    projectId: string,
    payload: { fileIds: string[] },
) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/images/delete`, payload)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeProjectApi = (projectId: string) => {
    return new Promise<{ data: ProjectJsonInterface }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
