import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { UsersShow } from "@/components/Other/UsersShow"
import {
    BIMFileStatus,
    ProjectFile,
    taskStatusBimFileMapping,
} from "@/models/File"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { FilterContainer } from "../../Plannings/Gantt/Filters/FilterContainer"
import {
    BimFilesFilterFields,
    CommonFilterFields,
    FilterFields,
    FilterGroup,
    FilterValuesType,
} from "../../Plannings/Gantt/Filters/Filters"
import { getProjectTasksById, getProjectUsersById } from "../../projectsSlice"
import { getProjectGroups } from "../../UserManagement/userManagementSlice"
import {
    getBimNominationFields,
    getProjectBimFiles,
    resetFilters,
    selectFiles,
    setBimTableColumns,
    setFilters,
    toggleFilter,
} from "../fileManagerSlice"
import { BrowserBimFileTableRow } from "./BrowserBimFileTableRow"

interface BrowserBimFileTableProps {}

const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + "..."
}

const fileStatusColorMap = {
    [BIMFileStatus.PENDING]: "text-primary-100",
    [BIMFileStatus.APPROVED]: "text-green-600",
    [BIMFileStatus.APPROVED_WITH_ISSUES]: "text-orange-600",
    [BIMFileStatus.REJECTED]: "text-red-600",
}

export const BimNominationCellMap = {
    [CommonFilterFields.Name]: {
        width: "15%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div className="p-1" style={{ width }}>
                {truncateString(file.name, 30)}
            </div>
        ),
    },
    [BimFilesFilterFields.Task]: {
        width: "15%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className="p-1 text-center text-blue-400 hover:text-blue-600 transition"
                style={{ width }}
            >
                <Link
                    to={`/dashboard/projects/${projectId}/tasks/${file.task.id}`}
                >
                    {file.task && truncateString(file.task.name, 30)}
                </Link>
            </div>
        ),
    },
    [BimFilesFilterFields.Project]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.project}
            </div>
        ),
    },
    [BimFilesFilterFields.Originator]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.originator}
            </div>
        ),
    },
    [BimFilesFilterFields.FunctionalBreakdown]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.functionalBreakdown}
            </div>
        ),
    },
    [BimFilesFilterFields.SpatialBreakdown]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.spatialBreakdown}
            </div>
        ),
    },
    [BimFilesFilterFields.Form]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.form}
            </div>
        ),
    },
    [BimFilesFilterFields.Discipline]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.discipline}
            </div>
        ),
    },
    [BimFilesFilterFields.Number]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.number}
            </div>
        ),
    },
    [BimFilesFilterFields.Phase]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center`}
                style={{
                    width,
                }}
            >
                {file.phase}
            </div>
        ),
    },
    [BimFilesFilterFields.Assignee]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className="p-1 text-center flex items-center justify-center"
                style={{ width }}
            >
                {file.task && <UsersShow users={[file.uploadedBy]} />}
            </div>
        ),
    },
    [BimFilesFilterFields.Reviewers]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className="p-1 text-center flex items-center justify-center"
                style={{ width }}
            >
                {file.task && (
                    <UsersShow
                        users={file.task.reviews
                            .map((review) => review.reviewer)
                            .flat()}
                    />
                )}
            </div>
        ),
    },
    [BimFilesFilterFields.BimFileStatus]: {
        width: "10%",
        getComponent: (file: ProjectFile, width: string, projectId: String) => (
            <div
                className={`p-1 text-center ${fileStatusColorMap[taskStatusBimFileMapping[file.task.statusDetail]]}`}
                style={{ width }}
            >
                {file.task.id !== "-1" && t(file.bimFileStatus)}
            </div>
        ),
    },
}

export const BrowserBimFileTable: React.FC<BrowserBimFileTableProps> = () => {
    const { t } = useTranslation()
    const bimNomination = useAppSelector(
        (state) => state.fileManager.filesBIMNominations,
    )
    const files = useAppSelector(selectFiles)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    const [localFiles, setLocalFiles] = useState<ProjectFile[]>(files)
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
    const [showTableColumnInput, setShowTableColumnInput] =
        useState<boolean>(false)
    const bimTableColumns = useAppSelector(
        (state) => state.fileManager.bimTableColumns,
    )
    const [localTableColumns, setLocalTableColumns] = useState<FilterFields[]>(
        [],
    )
    const fullScreenRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setLocalTableColumns(Object.values(bimTableColumns))
    }, [bimTableColumns])

    useEffect(() => {
        if (fullScreenRef && fullScreenRef.current) {
            if (isFullScreen) {
                fullScreenRef.current.requestFullscreen()
            } else {
                document.exitFullscreen()
            }
        }
    }, [isFullScreen])

    useEffect(() => {
        dispatch(getBimNominationFields(projectId))
        dispatch(getProjectBimFiles(projectId))
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
        dispatch(getProjectTasksById(projectId))
    }, [])
    const filters = useAppSelector((state) => state.fileManager.bimFileFilters)
    const filterGroup = new FilterGroup(filters)

    useEffect(() => {
        const filteredFiles = files.filter((file) =>
            filterGroup.applyFilters(
                file,
                filters as FilterValuesType<FilterFields>,
            ),
        )
        setLocalFiles(filteredFiles)
    }, [filters, files])

    return (
        <div
            className="flex flex-col gap-y-4 bg-secondary-100"
            ref={fullScreenRef}
        >
            <div className="w-full shadow-lg rounded-lg bg-secondary-100 flex p-2 mt-2 gap-2 py-4 justify-center items-center">
                <div className="relative">
                    <FontAwesomeIcon
                        icon="eye"
                        className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                        onClick={() =>
                            setShowTableColumnInput(!showTableColumnInput)
                        }
                    />
                    {showTableColumnInput && (
                        <div className="absolute z-50 bg-secondary-100 py-5 px-8 text-nowrap shadow-lg rounded-lg">
                            <p className="font-bold border-b border-primary-300">
                                {t("show_columns")}:
                            </p>
                            <div className="pl-2">
                                {[...Object.values(BimFilesFilterFields)]
                                    .filter(
                                        (field) =>
                                            ![
                                                BimFilesFilterFields.Task,
                                                CommonFilterFields.Name,
                                            ].includes(field),
                                    )
                                    .map((field) => (
                                        <div
                                            key={field}
                                            className="flex items-center gap-2"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={localTableColumns.includes(
                                                    field as FilterFields,
                                                )}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        dispatch(
                                                            setBimTableColumns([
                                                                ...localTableColumns,
                                                                field,
                                                            ]),
                                                        )
                                                    } else {
                                                        dispatch(
                                                            setBimTableColumns(
                                                                localTableColumns.filter(
                                                                    (column) =>
                                                                        column !==
                                                                        field,
                                                                ),
                                                            ),
                                                        )
                                                    }
                                                }}
                                            />
                                            <p>{t(field)}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
                <FontAwesomeIcon
                    icon="filter-circle-xmark"
                    className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                    onClick={() => dispatch(resetFilters({}))}
                />
                <FontAwesomeIcon
                    icon={true ? "compress" : "expand"}
                    className="text-primary-100 text-xl cursor-pointer transition hover:text-primary-300"
                    onClick={() => setIsFullScreen(!isFullScreen)}
                />
            </div>
            <div className="flex items-center">
                {Object.values(bimTableColumns).map((key) => (
                    <div
                        key={key}
                        className={`flex items-center p-1 font-bold ${["name"].includes(key) ? "justify-start" : "justify-center"} relative`}
                        style={{
                            width: BimNominationCellMap[key].width,
                        }}
                    >
                        <p className="w-fit">{t(key)}</p>
                        <FontAwesomeIcon
                            icon="filter"
                            className={`ml-1 hover:text-yellow-400 transition cursor-pointer ${filters[key as BimFilesFilterFields].isShown || filters[key as BimFilesFilterFields].value ? "text-yellow-400" : "text-primary-300"}`}
                            onClick={() => {
                                dispatch(
                                    toggleFilter(key as BimFilesFilterFields),
                                )
                            }}
                        />
                        {filters[key as BimFilesFilterFields].value && (
                            <FontAwesomeIcon
                                icon="filter-circle-xmark"
                                className="ml-1 text-red-400 hover:text-red-600 transition cursor-pointer"
                                onClick={() => {
                                    dispatch(
                                        setFilters({
                                            ...filters,
                                            [key as BimFilesFilterFields]: {
                                                ...filters[
                                                    key as BimFilesFilterFields
                                                ],
                                                value: "",
                                            },
                                        }),
                                    )
                                }}
                            />
                        )}
                        {filters[key as BimFilesFilterFields].isShown && (
                            <FilterContainer
                                filters={
                                    filters as FilterValuesType<FilterFields>
                                }
                                field={key as BimFilesFilterFields}
                                setFilters={setFilters}
                            />
                        )}
                    </div>
                ))}
            </div>
            <Scrollable>
                {localFiles.map((file) => (
                    <BrowserBimFileTableRow key={file.id} file={file} />
                ))}
            </Scrollable>
        </div>
    )
}
