import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputLabel } from "./InputLabel"

interface DropDownProps {
    value: string | number
    name: string
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    options: { value: string | number; label: string }[]
    required?: boolean
    label?: string
    className?: string
    translate?: boolean
    disabled?: boolean
    errors?: string[]
}

export const DropDown: React.FC<DropDownProps> = ({
    options,
    name,
    onChange,
    className,
    translate = false,
    value,
    disabled = false,
    label,
    required = false,
    errors,
}) => {
    const { t } = useTranslation()
    const [isExpanded, setIsExpanded] = useState(false)
    const [menuWidth, setMenuWidth] = useState(0)
    const inputRef = createRef<HTMLDivElement>()
    const menuRef = createRef<HTMLDivElement>()
    const [nothingSelected, setNothingSelected] = useState(true)
    const [selectedOptionLabel, setSelectedOptionLabel] = useState<
        string | number
    >("")
    useEffect(() => {
        const selectedOption = options.find((option) => option.value === value)
        if (selectedOption) {
            setSelectedOptionLabel(
                translate
                    ? t(selectedOption.label.toString())
                    : selectedOption.label,
            )
            setNothingSelected(false)
        } else {
            setSelectedOptionLabel("")
            setNothingSelected(true)
        }
    }, [value])

    useEffect(() => {
        if (inputRef.current) {
            setMenuWidth(inputRef.current.clientWidth)
        }
    }, [inputRef.current])

    return (
        <div className={className + " w-full relative"}>
            <InputLabel label={label} required={required} />
            <div
                ref={inputRef}
                className={`bg-white flex justify-between items-center outline-none p-1  pl-4 w-full rounded-lg ${errors && errors.length > 0 ? "border-red-600" : "border-secondary-400"} border transition ${
                    disabled
                        ? "opacity-40"
                        : errors && errors.length > 0
                          ? ""
                          : "hover:border-primary-100 focus:border-primary-100"
                }`}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {options.length === 0 ? (
                    <div className="opacity-40">{t("no_options")}</div>
                ) : nothingSelected ? (
                    <div className="opacity-40">{t("select_option")}</div>
                ) : (
                    <p>{selectedOptionLabel}</p>
                )}
                <FontAwesomeIcon icon="chevron-down" className="text-sm" />
            </div>
            {errors &&
                errors.length > 0 &&
                errors.length > 0 &&
                errors.map((error, index) => (
                    <p key={index} className="text-red-600 text-xs">
                        {t(error)}
                    </p>
                ))}
            {isExpanded && options.length > 0 && (
                <div
                    className="z-50 absolute  w-full bg-white border border-secondary-400 rounded-lg mt-1"
                    style={{
                        width: `${menuWidth}px`,
                    }}
                    ref={menuRef}
                >
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`p-2 hover:bg-primary-100 transition hover:text-secondary-100 cursor-pointer ${
                                index === options.length - 1
                                    ? "rounded-b-lg"
                                    : ""
                            } ${index === 0 ? "rounded-t-lg" : ""}`}
                            onClick={() => {
                                onChange({
                                    target: { name, value: option.value },
                                } as React.ChangeEvent<HTMLSelectElement>)
                                setIsExpanded(false)
                            }}
                        >
                            {translate
                                ? t(option.label.toString())
                                : option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
