import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Scrollable } from "./Scrollable"

interface NewModalProps {
    isShown: boolean
    closeModal: () => void
    children: React.ReactNode
    className?: string
}

export const NewModal: React.FC<NewModalProps> = ({
    isShown,
    closeModal,
    children,
    className,
}) => {
    const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }
    return isShown ? (
        <div
            className={`fixed top-0 left-0 w-screen h-screen bg-primary-300 bg-opacity-60 backdrop-blur-sm z-20 ${className}`}
            onClick={closeModal}
        >
            <div className="w-full h-full max-h-screen flex justify-center items-center">
                <div
                    className="px-[15%] max-w-[80%] max-h-[80%] lg:max-w-[90%] lg:max-h-[90%] sm:w-[100%] sm: sm:max-h-[100%] sm:min-h-[100%] overflow-hidden flex flex-col"
                    onClick={stopPropagation}
                >
                    <div
                        className="bg-secondary-100 rounded-lg shadow-lg p-5 overflow-hidden flex flex-col w-full h-full"
                        onClick={stopPropagation}
                    >
                        <Scrollable
                            height="100%"
                            width="100%"
                            className="flex flex-col"
                        >
                            <div className="flex justify-end">
                                <button
                                    className="text-primary-100 hover:text-primary-100"
                                    onClick={closeModal}
                                >
                                    <FontAwesomeIcon icon="times" />
                                </button>
                            </div>
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                {children}
                            </div>
                        </Scrollable>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}
