import { CURRENCY } from "@/models/Finance"

interface CurrencyProps {
    currency: CURRENCY
    classnames?: string
}

export const Currency: React.FC<CurrencyProps> = ({ currency, classnames }) => {
    const currencyMapping: {
        [key in CURRENCY]: string
    } = {
        [CURRENCY.USD]: "$",
        [CURRENCY.EUR]: "€",
        [CURRENCY.TND]: "TND",
        [CURRENCY.GBP]: "£",
    }

    return <div className={classnames}>{currencyMapping[currency]}</div>
}
