import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Form, SubmitType } from "@/features/Form/Form"
import { Task, TaskDependency } from "@/models/Task"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { TaskDependenciesTable } from "../TaskDependenciesTable"
import {
    addTaskDependencies,
    clearErrors,
    removeTaskDependencies,
} from "../tasksSlice"

interface TaskDependencyFormProps {
    task: Task
}

export const TaskDependencyForm: React.FC<TaskDependencyFormProps> = ({
    task,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const [dependenciesToAdd, setDependenciesToAdd] = React.useState<
        TaskDependency[]
    >([])
    const [dependenciesToRemove, setDependenciesToRemove] = React.useState<
        TaskDependency[]
    >([])
    const status = useAppSelector((state) => state.tasks.status)
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (dependenciesToAdd.length > 0) {
            dispatch(
                addTaskDependencies({
                    projectId,
                    taskId,
                    dependencies: dependenciesToAdd.map((dep) =>
                        dep.createPayload(),
                    ),
                }),
            )
        }
        if (dependenciesToRemove.length > 0) {
            dispatch(
                removeTaskDependencies({
                    projectId,
                    taskId,
                    dependencies: dependenciesToRemove.map((dep) => dep.id),
                }),
            )
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            statuses={[status.update]}
            loadingTitle={t("updating_task_dependencies")}
            submitType={SubmitType.Save}
            clearErrors={clearErrors}
        >
            <div className="min-w-[400px] flex flex-col gap-4">
                <h1 className="text-2xl font-bold">
                    {t("manage_dependencies")}
                </h1>
                <TaskDependenciesTable
                    edit={true}
                    setDependenciesToAdd={setDependenciesToAdd}
                    setDependenciesToRemove={setDependenciesToRemove}
                />
            </div>
        </Form>
    )
}
