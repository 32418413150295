import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Popup } from "@/components/Layouts/Popup"
import { WithContextMenu } from "@/components/Menus/WithContextMenu"
import { Form, SubmitType } from "@/features/Form/Form"
import { Folder } from "@/models/Folder"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"
import { FileManagerPermissionForm } from "./Forms/FileManagerPermissionForm"
import { clearErrors, deleteFolder, renameFolder } from "./fileManagerSlice"

interface FolderIconProps {
    folder: Folder
    highlighted?: boolean
    innerRef?: React.RefObject<HTMLDivElement> | null
}

export const FolderIcon: React.FC<FolderIconProps> = ({
    folder,
    highlighted = false,
    innerRef = null,
}) => {
    const { t } = useTranslation()
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showPermissionsModal, setShowPermissionsModal] =
        useState<boolean>(false)
    const [newName, setNewName] = useState<string>(folder.name)
    const [localHighlighted, setLocalHighlighted] =
        useState<boolean>(highlighted)
    const status = useAppSelector((state) => state.fileManager.oneStatus)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const [nameHovered, setNameHovered] = useState<boolean>(false)
    const [cords, setCords] = useState<[number, number]>([0, 0])
    const location = useLocation()
    const errors = useAppSelector((state) => state.fileManager.errors)

    const sliceName = (name: string) => {
        if (name.length > 40) {
            return name.slice(0, 40) + "..."
        }
        return name
    }

    const sharedContextMenuActions = [
        {
            name: t("rename"),
            icon: "signature" as IconProp,
            onClick: () => {
                setShowRenameModal(true)
            },
        },
        {
            name: t("delete"),
            icon: "trash" as IconProp,
            onClick: () => setShowDeleteModal(true),
        },
        {
            name: t("copy_link"),
            icon: "link" as IconProp,
            onClick: () => {
                navigator.clipboard.writeText(
                    `${import.meta.env.VITE_FRONTEND_URL}${location.pathname}?resource=${folder.id}`,
                )
            },
        },
        {
            name: t("permissions"),
            icon: "user-lock" as IconProp,
            onClick: () => setShowPermissionsModal(true),
        },
    ]
    return (
        <WithContextMenu items={sharedContextMenuActions}>
            <div
                className={`w-28 h-36 p-1 rounded-lg ${
                    localHighlighted
                        ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                        : ""
                }`}
                ref={innerRef}
                onMouseEnter={() => {
                    if (localHighlighted) {
                        setLocalHighlighted(false)
                    }
                }}
            >
                <Link
                    to={`/dashboard/projects/${projectId}/file-manager/${folder.id}`}
                    className="w-28 h-36 text-center flex flex-col justify-end items-center"
                >
                    <div className="flex items-start justify-center h-16">
                        <FontAwesomeIcon
                            icon="folder"
                            className="text-6xl text-yellow-500"
                        />
                    </div>
                    <div
                        className="!overflow-visible h-20"
                        onMouseEnter={(e) => {
                            setCords([e.clientX, e.clientY])
                            setNameHovered(true)
                        }}
                        onMouseLeave={() => {
                            setNameHovered(false)
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <p className="text-sm">{sliceName(folder.name)}</p>
                        <Popup
                            isShown={folder.name.length > 40 && nameHovered}
                            x={cords[0]}
                            y={cords[1]}
                        >
                            <p className="">{folder.name}</p>
                        </Popup>
                    </div>
                </Link>
            </div>
            <NewModal
                isShown={showRenameModal}
                closeModal={() => setShowRenameModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("renaming_folder")}
                        statuses={[status.update]}
                        onCancel={() => setShowRenameModal(false)}
                        submitType={SubmitType.Save}
                        onSubmit={async (e) => {
                            e.preventDefault()
                            const { type } = await dispatch(
                                renameFolder({
                                    projectId,
                                    folderId: folder.id,
                                    name: newName,
                                }),
                            )
                            if (type === renameFolder.fulfilled.type) {
                                setShowRenameModal(false)
                            }
                        }}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("renaming_folder")} `}
                                <span className="italic font-normal">
                                    {folder.name}
                                </span>
                            </h1>
                            <Input
                                type="text"
                                name={t("new_name")}
                                label={t("new_name")}
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                className="!m-0"
                                errors={errors.name}
                            />
                        </div>
                    </Form>
                </div>
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("deleting_folder")}
                        statuses={[status.delete]}
                        onCancel={() => setShowDeleteModal(false)}
                        submitType={SubmitType.Delete}
                        onSubmit={() => {
                            dispatch(
                                deleteFolder({
                                    projectId,
                                    folderId: folder.id,
                                }),
                            ).then(() => {
                                setShowDeleteModal(false)
                            })
                        }}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("deleting_folder")} `}
                                <span className="italic font-normal">
                                    {folder.name}
                                </span>
                            </h1>
                            <p>
                                {t(
                                    "are_you_sure_you_want_to_delete_this_folder",
                                )}
                            </p>
                            <p className="text-red-500 text-sm">
                                {t(
                                    "all_files_and_folders_inside_this_folder_will_be_deleted",
                                )}
                            </p>
                        </div>
                    </Form>
                </div>
            </NewModal>
            <NewModal
                isShown={showPermissionsModal}
                closeModal={() => setShowPermissionsModal(false)}
            >
                <FileManagerPermissionForm
                    resource={folder}
                    closeModal={() => setShowPermissionsModal(false)}
                />
            </NewModal>
        </WithContextMenu>
    )
}
