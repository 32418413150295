import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, File as ProjectFile } from "./File"
import { Budget, BudgetInterface } from "./Finance"
import { IfcEntity, IfcEntityInterface } from "./Ifc"
import { Project, ProjectJsonInterface } from "./Project"
import { User, UserInterface } from "./User"

export enum Unit {
    CUBIC_METER = "CUBIC_METER",
    SQUARE_METER = "SQUARE_METER",
    LINEAR_METER = "LINEAR_METER",
    KILOGRAM = "KILOGRAM",
    PIECE = "PIECE",
}

export interface ArticleInterface extends BaseModelInterface {
    name: string
    description: string
    unit: Unit
    unitPrice: number
    totalPrice: string
    createdBy: UserInterface
    project: ProjectJsonInterface
    photos: FileJsonInterface[]
    budget: BudgetInterface
    ifcEntities: IfcEntityInterface[]
}

export class Article extends BaseModel {
    public name: string
    public description: string
    public unit: Unit
    public unitPrice: number
    public totalPrice: string
    public createdBy: User
    public project: Project
    public photos: ProjectFile[]
    public budget: Budget
    public ifcEntities: IfcEntity[]

    constructor(data?: ArticleInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.unit = data?.unit ?? Unit.PIECE
        this.unitPrice = data?.unitPrice ?? 0
        this.totalPrice = data?.totalPrice ?? ""
        this.createdBy = data?.createdBy ? new User(data.createdBy) : new User()
        this.project = data?.project ? new Project(data.project) : new Project()
        this.photos = data?.photos
            ? data.photos.map((photo) => new ProjectFile(photo))
            : []
        this.budget = data?.budget ? new Budget(data.budget) : new Budget()
        this.ifcEntities = data?.ifcEntities
            ? data.ifcEntities.map((ifcEntity) => new IfcEntity(ifcEntity))
            : []
    }

    public createArticlePayload(
        photo: File | null,
        budgetId?: string,
    ): FormData {
        const articlePayload = new FormData()
        articlePayload.append("name", this.name)
        articlePayload.append("description", this.description)
        articlePayload.append("unit", this.unit)
        articlePayload.append("unitPrice", this.unitPrice.toString())
        if (budgetId && budgetId !== "") {
            articlePayload.append("budgetId", budgetId)
        }
        if (photo) {
            articlePayload.append("photos", photo)
        }
        return articlePayload
    }

    public toJson(): ArticleInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            unit: this.unit,
            unitPrice: this.unitPrice,
            totalPrice: this.totalPrice,
            createdBy: this.createdBy.toJson(),
            project: this.project.toJson(),
            photos: this.photos.map((photo) => photo.toJson()),
            budget: this.budget.toJson(),
            ifcEntities: this.ifcEntities.map((ifcEntity) =>
                ifcEntity.toJson(),
            ),
        }
    }
}
