import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { Budget } from "@/models/Finance"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { deleteBudget, getGeneralBudgetInfo } from "../financeSlice"
import { BudgetForm } from "./Forms/BudgetForm"

interface BudgetsActionsProps {
    budget: Budget
}

export const BudgetsActions: React.FC<BudgetsActionsProps> = ({ budget }) => {
    const dispatch = useAppDispatch()
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const projectId = useParams<{ projectId: string }>().projectId
    const ellipsisRef = useRef<HTMLDivElement>(null)
    const status = useAppSelector((state) => state.finance.status)
    const menuItems = [
        {
            title: t("edit_budget"),
            icon: "edit" as IconProp,
            onClick: () => {
                setShowEditModal(true)
            },
        },
        {
            title: t("delete_budget"),
            icon: "trash" as IconProp,
            onClick: () => {
                setShowDeleteModal(true)
            },
        },
        {
            title: t("add_sub_budget"),
            icon: "plus" as IconProp,
            onClick: () => {
                setShowAddModal(true)
            },
        },
    ]
    return (
        <div className="">
            <div
                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                ref={ellipsisRef}
                onClick={() => {
                    setShowMenu(!showMenu)
                }}
            >
                <FontAwesomeIcon icon="ellipsis-v" className="cursor-pointer" />
            </div>
            <Popup
                parentRef={ellipsisRef}
                type={POPUP_TYPES.LEFT_CLICK}
                isShown={showMenu}
                closePopup={() => setShowMenu(false)}
            >
                <ul className="flex flex-col gap-2">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className="flex gap-2 items-center hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b pl-2 pr-8 py-2"
                            onClick={() => {
                                item.onClick()
                                setShowMenu(false)
                            }}
                        >
                            <FontAwesomeIcon icon={item.icon} />
                            <p className="text-nowrap">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popup>
            <NewModal
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <BudgetForm
                    budget={budget}
                    closeModal={() => setShowEditModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showAddModal}
                closeModal={() => setShowAddModal(false)}
            >
                <BudgetForm
                    parentId={budget.id}
                    closeModal={() => setShowAddModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <WithLoader
                    statuses={[status.write]}
                    title={t("deleting_budget", {
                        budget: budget.name,
                    })}
                >
                    <div className="p-4">
                        <h1 className="font-bold text-xl mb-2">
                            {t("deleting_budget", {
                                budget: budget.name,
                            })}
                        </h1>
                        <h2 className="text-red-600 font-bold">
                            {t("delete_budget_notice", {
                                budget: budget.name,
                            })}
                        </h2>
                        <div className="flex items-center justify-end gap-4 mt-6">
                            <Button
                                name={t("cancel")}
                                icon="times"
                                className=""
                                onClick={() => setShowDeleteModal(false)}
                            ></Button>
                            <Button
                                name={t("delete")}
                                className="!bg-red-400 hover:!bg-red-600 !border-red-400 hover:border-red-600 text-white hover:text-white transition"
                                icon={"trash"}
                                onClick={() => {
                                    dispatch(
                                        deleteBudget({
                                            projectId,
                                            budgetId: budget.id,
                                        }),
                                    ).then(() => {
                                        dispatch(
                                            getGeneralBudgetInfo(projectId),
                                        )
                                        setShowDeleteModal(false)
                                    })
                                }}
                            ></Button>
                        </div>
                    </div>
                </WithLoader>
            </NewModal>
        </div>
    )
}
