import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { UserSettings } from "@/components/Forms/UserSettings"
import { CalendarInput } from "@/components/Inputs/CalendarInput"
import { DropDown } from "@/components/Inputs/DropDown"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Form, SubmitType } from "@/features/Form/Form"
import { Ban, BanReason } from "@/models/Admin"
import { User, UserUpdatePayload } from "@/models/User"
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_Table,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    banUser,
    changeUserProfilePicture,
    clearErrors,
    getLatestUsers,
    selectLatestUsers,
    updateUser,
} from "../adminSlice"
import { UnbanForm } from "./Form/UnbanForm"
import { LatestUsersActions } from "./LatestUsersActions"

interface LatestUsersProps {
    minimized?: boolean
}

export const LatestUsers: React.FC<LatestUsersProps> = ({ minimized }) => {
    const users = useAppSelector(selectLatestUsers)
    const [banUserModal, setBanUserModal] = useState(false)
    const [editUserModal, setEditUserModal] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [unbanUserModal, setUnbanUserModal] = useState(false)
    const [localBan, setLocalBan] = useState<Ban>(new Ban())
    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const status = useAppSelector((state) => state.admin.status)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getLatestUsers({}))
    }, [dispatch])
    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                id: "profilePicture",
                accessorKey: "profilePicture",
                header: "",
                Cell: ({ renderedCellValue, row }) => (
                    <img
                        src={row.original.profilePicture.path}
                        alt={row.original.fullName}
                        className="w-10 h-10 rounded-full m-auto"
                    />
                ),
                enableColumnActions: false,
                enableSorting: false,
            },
            ...(minimized
                ? [
                      {
                          accessorKey: "fullName",
                          header: t("user"),
                      },
                  ]
                : [
                      {
                          accessorKey: "firstName",
                          header: t("first_name"),
                      },
                      {
                          accessorKey: "lastName",
                          header: t("last_name"),
                      },
                  ]),
            {
                accessorKey: "email",
                header: t("email"),
            },
            ...(minimized
                ? []
                : [
                      {
                          accessorKey: "phone",
                          header: t("phone"),
                      },
                  ]),
            {
                accessorKey: "createdAt",
                header: t("created_at"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {t("intlDateTime", {
                            val: row.original.createdAt,
                            formatParams: {
                                val: {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </div>
                ),
                filterVariant: "date-range",
            },
            {
                id: "actions",
                header: "",
                Cell: ({ renderedCellValue, row }) => (
                    <LatestUsersActions
                        user={row.original}
                        setBanUserModal={setBanUserModal}
                        setEditUserModal={setEditUserModal}
                        setResetPasswordModal={setResetPasswordModal}
                        setUnbanUserModal={setUnbanUserModal}
                        setSelectedUser={setSelectedUser}
                    />
                ),
                enableColumnActions: false,
                enableSorting: false,
            },
        ],
        [],
    )
    const table = useMantineReactTable<User>({
        columns,
        data: users,
        enableColumnActions: !minimized,
        enableColumnFilters: !minimized,
        enablePagination: !minimized,
        enableSorting: !minimized,
        enableFullScreenToggle: true,
        positionPagination: "top",
        mantineTableContainerProps: {
            sx: {
                width: "100%",
                height: "80%",
            },
        },
        initialState: {
            columnOrder: [
                "profilePicture",
                ...(minimized ? ["fullName"] : ["firstName", "lastName"]),
                "email",
                ...(minimized ? [] : ["phone"]),
                "createdAt",
                "actions",
            ],
        },
    })
    return (
        <div className="w-full h-full overflow-hidden flex flex-col">
            <WithLoader
                title={t("loading_users")}
                statuses={[status.read]}
                className="w-full"
            >
                <Scrollable>
                    {minimized ? (
                        <MRT_Table table={table} />
                    ) : (
                        <MantineReactTable table={table} />
                    )}
                </Scrollable>
            </WithLoader>
            <NewModal
                isShown={banUserModal}
                closeModal={() => setBanUserModal(false)}
            >
                <Form
                    onSubmit={async () => {
                        if (selectedUser) {
                            const { type } = await dispatch(
                                banUser({
                                    userId: selectedUser.id,
                                    payload: localBan.banPayload(),
                                }),
                            )
                            if (type === banUser.fulfilled.type) {
                                setBanUserModal(false)
                            }
                        }
                    }}
                    statuses={[status.update]}
                    loadingTitle={t("banning_user", {
                        user: selectedUser?.fullName,
                    })}
                    onCancel={() => setBanUserModal(false)}
                    className={""}
                    submitType={SubmitType.Submit}
                    clearErrors={clearErrors}
                >
                    <div className="flex flex-col gap-4 p-4">
                        <h1 className="text-xl font-bold">
                            {t("banning_user", {
                                user: selectedUser?.fullName,
                            })}
                        </h1>
                        <p>
                            {t("ban_user_description", {
                                user: selectedUser?.fullName,
                            })}
                        </p>
                        <DropDown
                            label={t("ban_reason")}
                            name={t("ban_reason")}
                            options={Object.values(BanReason).map((reason) => ({
                                label: t(reason),
                                value: reason,
                            }))}
                            value={localBan.reason}
                            onChange={(e) =>
                                setLocalBan(
                                    new Ban({
                                        ...localBan.toJson(),
                                        reason: e.target.value as BanReason,
                                    }),
                                )
                            }
                        />
                        <TextAreaInput
                            label={t("description")}
                            name={t("description")}
                            value={localBan.description}
                            onChange={(e) =>
                                setLocalBan(
                                    new Ban({
                                        ...localBan.toJson(),
                                        description: e.target.value,
                                    }),
                                )
                            }
                        />
                        <CalendarInput
                            label={t("expires_at")}
                            name={t("expires_at")}
                            value={
                                localBan.expiresAt.toISOString().split("T")[0]
                            }
                            onChange={(e) =>
                                setLocalBan(
                                    new Ban({
                                        ...localBan.toJson(),
                                        expiresAt: new Date(e.target.value),
                                    }),
                                )
                            }
                        />
                    </div>
                </Form>
            </NewModal>
            {selectedUser !== null && (
                <div>
                    <NewModal
                        isShown={unbanUserModal}
                        closeModal={() => setUnbanUserModal(false)}
                    >
                        <UnbanForm
                            user={selectedUser}
                            closeModal={() => setUnbanUserModal(false)}
                        />
                    </NewModal>
                    <NewModal
                        isShown={editUserModal}
                        closeModal={() => setEditUserModal(false)}
                    >
                        <UserSettings
                            handleSubmit={async ({
                                userId,
                                user,
                            }: {
                                userId: string
                                user: UserUpdatePayload
                            }) => {
                                const { type } = await dispatch(
                                    updateUser({
                                        userId,
                                        user,
                                    }),
                                )
                                if (type === updateUser.fulfilled.type) {
                                    setEditUserModal(false)
                                }
                            }}
                            handleProfilePictureChange={async ({
                                userId,
                                payload,
                            }) => {
                                const { type } = await dispatch(
                                    changeUserProfilePicture({
                                        userId,
                                        payload,
                                    }),
                                )
                                if (
                                    type ===
                                    changeUserProfilePicture.fulfilled.type
                                ) {
                                    setEditUserModal(false)
                                }
                            }}
                            user={selectedUser}
                            closeModal={() => setEditUserModal(false)}
                        />
                    </NewModal>
                </div>
            )}
        </div>
    )
}
