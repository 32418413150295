import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import {
    User,
    UserInterface,
    UserRegisterInterface,
    UserUpdatePayload,
} from "@/models/User"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    authLogin,
    authRegister,
    authValidate,
    changeUserProfilePictureApi,
    updateUserApi,
    verifyEmailApi,
} from "./authAPI"

export const login = createAsyncThunkWithNotification(
    "auth/login",
    async ({ email, password }: { email: string; password: string }) => {
        const response = await authLogin(email, password)
        localStorage.setItem("accessToken", response.data.data.accessToken)
        return response
    },
)

export const register = createAsyncThunkWithNotification(
    "auth/register",
    async (registerPayload: UserRegisterInterface) => {
        const response = await authRegister(registerPayload)
        localStorage.setItem("accessToken", response.data.data.accessToken)
        return response
    },
)

export const validate = createAsyncThunkWithNotification(
    "auth/validate",
    async () => {
        const response = await authValidate()
        return response
    },
)

export const verifyEmail = createAsyncThunkWithNotification(
    "auth/verifyEmail",
    async (token: string) => {
        const response = await verifyEmailApi(token)
        return response
    },
)

export const updateUser = createAsyncThunkWithNotification(
    "auth/updateUser",
    async ({ userId, user }: { userId: string; user: UserUpdatePayload }) => {
        const response = await updateUserApi(userId, user)
        return response
    },
)

export const changeUserProfilePicture = createAsyncThunkWithNotification(
    "auth/changeUserProfilePicture",
    async (
        { userId, payload }: { userId: string; payload: FormData },
        dispatch: any,
    ) => {
        const response = await changeUserProfilePictureApi(
            userId,
            payload,
            dispatch,
        )
        return response
    },
)

const logoutAndClear = (state: AuthState) => {
    localStorage.removeItem("accessToken")
    state.user = new User().toJson()
    state.isLogged = false
}

export interface AuthState {
    user: UserInterface
    uploadPercentage: number
    isLogged: boolean
    status: ReducerStatus
    errors: FormErrors
}

const initialState: AuthState = {
    user: new User().toJson(),
    uploadPercentage: 0,
    isLogged: false,
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: logoutAndClear,
        setUploadPercentage: (state, action) => {
            state.uploadPercentage = action.payload
        },
        clearErrors: (state) => {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.user = action.payload.data.data.user
                state.isLogged = true
                state.errors = {}
            })
            .addCase(login.rejected, (state, action) => {
                state.status.read = SliceStatus.FAILED
                localStorage.removeItem("accessToken")
                state.user = new User().toJson()
                state.isLogged = false
                state.errors = (action.payload as any).data
            })
            .addCase(register.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(register.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.user = action.payload.data.data.user
                state.isLogged = true
                state.errors = {}
            })
            .addCase(register.rejected, (state, action) => {
                state.status.read = SliceStatus.FAILED
                localStorage.removeItem("accessToken")
                state.isLogged = false
                state.errors = (action.payload as any).data
            })
            .addCase(validate.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(validate.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.user = action.payload.data.data.user
                state.isLogged = true
            })
            .addCase(validate.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                localStorage.removeItem("accessToken")
                state.user = new User().toJson()
                state.isLogged = false
            })
            .addCase(verifyEmail.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
            })
            .addCase(verifyEmail.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(updateUser.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.user = action.payload.data.data
                state.errors = {}
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(changeUserProfilePicture.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(changeUserProfilePicture.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.user = action.payload.data.data
                state.errors = {}
            })
            .addCase(changeUserProfilePicture.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
    },
})

const selectUser = (state: RootState) => state.auth.user
export const selectIsLogged = (state: RootState) => state.auth.isLogged

export const selectAuthUser = createSelector(
    [selectUser],
    (user) => new User(user),
)
export const { logout, setUploadPercentage, clearErrors } = authSlice.actions

export default authSlice.reducer
