import { axiosAuthenticated } from "@/common/axios"
import {
    TaskDependenciesPayload,
    TaskPayload,
    TaskReviewStatus,
} from "@/models/Task"

export const getTaskByIdApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const createTaskApi = (
    projectId: string,
    planningId: string,
    payload: TaskPayload,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `projects/${projectId}/tasks/plannings/${planningId}`,
                payload,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskAssigneesByIdApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/tasks/${taskId}/assignees`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addTaskAssigneesByIdApi = (
    projectId: string,
    taskId: string,
    assignees: string[],
    groups: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`projects/${projectId}/tasks/${taskId}/assignees`, {
                assignees: assignees,
                groupsAssigned: groups,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeTaskAssigneesByIdApi = (
    projectId: string,
    taskId: string,
    assignees: string[],
    groups: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`projects/${projectId}/tasks/${taskId}/assignees/remove`, {
                assignees: assignees,
                groupUnAssigned: groups,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
export const getTaskFollowersByIdApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/followers`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addTaskFollowersByIdApi = (
    projectId: string,
    taskId: string,
    followers: string[],
    groupFollowers: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/followers/add`, {
                followers,
                groupFollowers,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeTaskFollowersByIdApi = (
    projectId: string,
    taskId: string,
    followers: string[],
    groupFollowers: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/followers/remove`, {
                followers,
                groupFollowers,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskReviewsByIdApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/reviews`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addTaskReviewsByIdApi = (
    projectId: string,
    taskId: string,
    reviewers: string[],
    groups: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/reviews`, {
                reviewerIds: reviewers,
                groupIds: groups,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeTaskReviewsByIdApi = (
    projectId: string,
    taskId: string,
    reviewers: string[],
    groups: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/reviews/delete`, {
                reviewerIds: reviewers,
                groupIds: groups,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskAttachmentsByIdApi = (
    projectId: string,
    taskId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/attachments`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskDependenciesByIdApi = (
    projectId: string,
    taskId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/dependencies`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskCommentsByIdApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/comments/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getCommentRepliesApi = (
    projectId: string,
    taskId: string,
    commentId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(
                `/projects/${projectId}/tasks/${taskId}/comments/${commentId}/replies/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addCommentApi = (
    projectId: string,
    taskId: string,
    payload: FormData,
    commentId: string = "",
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/tasks/${taskId}/comments/${commentId}`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const uploadAttachmentApi = (
    projectId: string,
    taskId: string,
    payload: FormData,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/tasks/${taskId}/attachment`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteAttachmentApi = (
    projectId: string,
    taskId: string,
    attachmentId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(
                `/projects/${projectId}/tasks/${taskId}/attachment/${attachmentId}`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addTaskDependenciesApi = (
    projectId: string,
    taskId: string,
    dependencies: TaskDependenciesPayload[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/dependency/`, {
                dependencies,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeTaskDependenciesApi = (
    projectId: string,
    taskId: string,
    dependencies: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/tasks/${taskId}/dependency/`, {
                dependencies,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getUserTaskReviewApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/review/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const updateTaskReviewApi = (
    projectId: string,
    taskId: string,
    payload: { status: TaskReviewStatus; comment: string },
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const mapping = {
            [TaskReviewStatus.APPROVED]: "approve",
            [TaskReviewStatus.REJECTED]: "reject",
            [TaskReviewStatus.PENDING]: "pending",
        }
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/tasks/${taskId}/review/${mapping[payload.status]}/`,
                {
                    comment: payload.comment,
                },
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const resetTaskStatusApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .put(`/projects/${projectId}/tasks/${taskId}/reset/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const deleteTaskApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .delete(`/projects/${projectId}/tasks/${taskId}/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskTagsApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/tags`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const setTaskTagsApi = (
    projectId: string,
    taskId: string,
    tags: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/tags/`, { tags })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const addTaskTagsApi = (
    projectId: string,
    taskId: string,
    tags: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/tags/add`, { tags })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const removeTaskTagsApi = (
    projectId: string,
    taskId: string,
    tags: string[],
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(`/projects/${projectId}/tasks/${taskId}/tags/remove`, {
                tags,
            })
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTaskActivitiesApi = (projectId: string, taskId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`/projects/${projectId}/tasks/${taskId}/activities/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const sendTaskDelayedNotificationsApi = (
    projectId: string,
    taskId: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .post(
                `/projects/${projectId}/tasks/${taskId}/send-delay-notifications/`,
            )
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getMyAssignedTasksApi = (projectId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/tasks/assigned-tasks/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getTasksForReviewApi = (projectId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/tasks/tasks-for-review/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}

export const getMyFollowedTasksApi = (projectId: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        axiosAuthenticated()
            .get(`projects/${projectId}/tasks/followed-tasks/`)
            .then((response) => {
                resolve({ data: response.data })
            })
            .catch((error) => {
                reject({ data: error.response.data })
            })
    })
}
