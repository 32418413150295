import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { compareWBS, formatDate } from "@/common/helpers"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import {
    ContextMenuItem,
    WithContextMenu,
} from "@/components/Menus/WithContextMenu"
import { TagComponent } from "@/components/Other/TagComponent"
import { TaskDelayStatusBar } from "@/components/Other/TaskDelayStatus"
import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { TaskStatusDetailBar } from "@/components/Other/TaskStatusDetailBar"
import { UsersShow } from "@/components/Other/UsersShow"
import { TaskForm } from "@/features/Projects/Tasks/TaskForm"
import { selectUserPermissions } from "@/features/User/userSlice"
import { Task, TaskJsonInterface, TaskStatus } from "@/models/Task"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TailSpin } from "react-loader-spinner"
import { useNavigate, useParams } from "react-router-dom"
import { TaskDeleteForm } from "../../Tasks/TaskForms/TaskDeleteForm"
import {
    addTaskToParent,
    expandTask,
    highlightTask,
    removeTaskFromParent,
    selectTaskMappingById,
    shrinkTask,
    updateTaskInTree,
} from "../planningSlice"
import {
    CommonFilterFields,
    FilterFields,
    FilterGroup,
    FilterValuesType,
    TaskFilterFields,
} from "./Filters/Filters"
import { applyFilterToTasksAndChildren } from "./ganttHelpers"

interface TaskItemProps {
    task: Task
    parentTask?: Task
    outlineLevel?: number
}

export const GanttTaskTableRow: React.FC<TaskItemProps> = ({
    task,
    parentTask,
    outlineLevel = 0,
}) => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const taskMapping = useAppSelector(selectTaskMappingById(task.id))
    const filters = useAppSelector((state) => state.gantt.filters)
    const filterGroup = new FilterGroup(filters)
    const highlightedTaskId = useAppSelector(
        (state) => state.plannings.highlightedTaskId,
    )
    const [highlighted, setHighlighted] = useState<boolean>(false)
    const [childInFilter, setChildInFilter] = useState<boolean>(true)
    const [inFilter, setInFilter] = useState<boolean>(true)
    const userPermissions = useAppSelector(selectUserPermissions)
    const [expanded, setExpanded] = useState<boolean>(
        taskMapping?.isExpanded ?? true,
    )
    const [hasChildren, setHasChildren] = useState<boolean>(false)
    const [showAddChildTaskModal, setShowAddChildTaskModal] =
        useState<boolean>(false)
    const [showAddSiblingTaskModal, setShowAddSiblingTaskModal] =
        useState<boolean>(false)
    const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false)
    const [showDeleteTaskModal, setShowDeleteTaskModal] =
        useState<boolean>(false)
    const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>(
        [],
    )
    const tableColumns = useAppSelector((state) => state.gantt.tableColumns)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const truncateText = (text: string, length: number) => {
        return text.length > length ? text.substring(0, length) + "..." : text
    }
    const navigate = useNavigate()
    useEffect(() => {
        setHighlighted(highlightedTaskId === task.id)
    }, [highlightedTaskId])
    useEffect(() => {
        setHasChildren(task.children.length > 0)
        let items: ContextMenuItem[] = [
            ...(parentTask
                ? [
                      {
                          name: t("add_sibling_task"),
                          icon: "plus" as IconProp,
                          onClick: () => {
                              setShowAddSiblingTaskModal(true)
                          },
                      },
                  ]
                : []),
            {
                name: t("add_child_task"),
                icon: "plus" as IconProp,
                onClick: () => {
                    setShowAddChildTaskModal(true)
                },
            },
            ...(userPermissions.hasAllPermissions([`task.${task.id}.manager`])
                ? [
                      {
                          name: t("edit_task"),
                          icon: "edit" as IconProp,
                          onClick: () => {
                              setShowEditTaskModal(true)
                          },
                      },
                      {
                          name: t("delete_task"),
                          icon: "trash" as IconProp,
                          onClick: () => {
                              setShowDeleteTaskModal(true)
                          },
                      },
                  ]
                : []),
            ...(userPermissions.hasOneOfPermissions([
                `task.${task.id}.manager`,
                `task.${task.id}.assignee`,
                `task.${task.id}.reviewer`,
                `task.${task.id}.follower`,
            ])
                ? [
                      {
                          name: t("see_task_details"),
                          icon: "exclamation-circle" as IconProp,
                          onClick: (e: any) => {
                              if (e.button === 1) {
                                  window.open(
                                      `/dashboard/projects/${projectId}/tasks/${task.id}`,
                                      "_blank",
                                  )
                              } else {
                                  navigate(
                                      `/dashboard/projects/${projectId}/tasks/${task.id}`,
                                  )
                              }
                          },
                      },
                  ]
                : []),
        ]
        setContextMenuItems(items)
    }, [task])
    useEffect(() => {
        if (expanded) {
            dispatch(expandTask(task.id))
        } else {
            dispatch(shrinkTask(task.id))
        }
    }, [expanded])
    useEffect(() => {
        if (taskMapping) {
            setExpanded(taskMapping.isExpanded)
        }
    }, [taskMapping])
    useEffect(() => {
        setChildInFilter(
            applyFilterToTasksAndChildren(task, filters, filterGroup),
        )
        setInFilter(
            filterGroup.applyFilters(
                task,
                filters as FilterValuesType<FilterFields>,
            ),
        )
    }, [filters])

    const tableHeaderMapping = {
        [TaskFilterFields.WBS]: {
            label: TaskFilterFields.WBS,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[120px] flex items-center w-4 text-left">
                    {task.wbs}
                </div>
            ),
        },
        [CommonFilterFields.Name]: {
            label: CommonFilterFields.Name,
            getComponent: (task: Task, outlineLevel: number) => (
                <div
                    className="flex-1 min-w-[370px] flex items-center text-left "
                    style={{ paddingLeft: `${outlineLevel * 30}px` }}
                >
                    <p className="text-sm flex items-center">
                        {hasChildren ? (
                            <FontAwesomeIcon
                                icon={
                                    expanded ? "chevron-down" : "chevron-right"
                                }
                                className={`mr-2`}
                                onClick={() => setExpanded(!expanded)}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={"circle"}
                                className={`mr-2 text-[.3rem]`}
                            />
                        )}
                        {truncateText(task.name, 55)}
                    </p>
                </div>
            ),
        },
        [TaskFilterFields.StartDate]: {
            label: TaskFilterFields.StartDate,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[200px] flex justify-center items-center">
                    {formatDate(task.startDate)}
                </div>
            ),
        },
        [TaskFilterFields.EndDate]: {
            label: TaskFilterFields.EndDate,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[200px] flex justify-center items-center">
                    {formatDate(task.endDate)}
                </div>
            ),
        },
        [TaskFilterFields.TaskStatus]: {
            label: TaskFilterFields.TaskStatus,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    <TaskStatusBar taskStatus={task.status} />
                </div>
            ),
        },
        [TaskFilterFields.StatusDetail]: {
            label: TaskFilterFields.StatusDetail,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[200px] flex justify-center items-center">
                    <TaskStatusDetailBar taskStatusDetail={task.statusDetail} />
                </div>
            ),
        },
        [TaskFilterFields.DelayStatus]: {
            label: TaskFilterFields.DelayStatus,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[200px] flex justify-center items-center">
                    {task.status !== TaskStatus.NOT_STARTED && (
                        <TaskDelayStatusBar
                            taskDelayStatus={task.delayStatus}
                        />
                    )}
                </div>
            ),
        },
        [TaskFilterFields.StartDelay]: {
            label: TaskFilterFields.StartDelay,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    {task.startDelay +
                        " " +
                        (task.startDelay > 1 ? t("days") : t("day"))}
                </div>
            ),
        },
        [TaskFilterFields.EndDelay]: {
            label: TaskFilterFields.EndDelay,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    {task.endDelay +
                        " " +
                        (task.endDelay > 1 ? t("days") : t("day"))}
                </div>
            ),
        },
        [TaskFilterFields.Duration]: {
            label: TaskFilterFields.Duration,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[150px] flex justify-center items-center">
                    {task.duration +
                        " " +
                        (task.duration > 1 ? t("days") : t("day"))}
                </div>
            ),
        },
        [TaskFilterFields.Progress]: {
            label: TaskFilterFields.Progress,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[150px] flex justify-center items-center">
                    {task.progress}%
                </div>
            ),
        },
        [TaskFilterFields.Assignees]: {
            label: TaskFilterFields.Assignees,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    {task.assignees.length > 0 && (
                        <UsersShow users={task.assignees} />
                    )}{" "}
                </div>
            ),
        },
        [TaskFilterFields.Reviewers]: {
            label: TaskFilterFields.Reviewers,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    {task.reviews.map((review) => review.reviewer).length >
                        0 && (
                        <UsersShow
                            users={task.reviews.map(
                                (review) => review.reviewer,
                            )}
                        />
                    )}{" "}
                </div>
            ),
        },
        [TaskFilterFields.Tags]: {
            label: TaskFilterFields.Tags,
            getComponent: (task: Task, outlineLevel: number) => (
                <div className="flex-1 min-w-[170px] flex justify-center items-center">
                    <Scrollable
                        height="40px"
                        width="90px"
                        xScroll={true}
                        yScroll={true}
                        className="flex flex-wrap gap-1"
                    >
                        {task.tags.map((tag) => (
                            <TagComponent key={tag.id} tag={tag} />
                        ))}
                    </Scrollable>
                </div>
            ),
        },
    }
    return childInFilter ? (
        <>
            <WithContextMenu
                items={contextMenuItems}
                className={`select-none flex h-12 w-full min-w-fit border-b border-opacity-30 border-b-primary-300 text-primary-300 hover:bg-secondary-200 cursor-pointer transition  ${
                    hasChildren ? "font-bold" : ""
                } ${highlighted ? "animated-background bg-gradient-to-r from-secondary-400 via-secondary-200 to-secondary-400" : ""} ${
                    inFilter ? "" : "opacity-20"
                }`}
                onMouseEnter={() => {
                    if (task.id === highlightedTaskId) {
                        dispatch(highlightTask(""))
                    }
                }}
                onClick={() => setExpanded(!expanded)}
            >
                {Object.values(tableColumns).map((field) => (
                    <div
                        className={`flex-1 flex items-center justify-center`}
                        key={
                            tableHeaderMapping[field as TaskFilterFields].label
                        }
                    >
                        {tableHeaderMapping[
                            field as TaskFilterFields
                        ].getComponent(task, outlineLevel)}
                    </div>
                ))}
            </WithContextMenu>
            {hasChildren &&
                expanded &&
                !task.isLoading &&
                task.children
                    .sort((a, b) => compareWBS(a.wbs, b.wbs))
                    .map((subTask) => (
                        <GanttTaskTableRow
                            key={subTask.id}
                            task={subTask}
                            parentTask={task}
                            outlineLevel={outlineLevel + 1}
                        />
                    ))}
            {hasChildren &&
                expanded &&
                task.isLoading &&
                task.children.map((_, idx) => (
                    <div
                        className={`text-center h-12 border-b border-b-primary-100 text-primary-100 ${
                            hasChildren ? "font-bold" : ""
                        }`}
                        key={idx}
                    >
                        <div className="text-center">
                            <div
                                style={{
                                    paddingLeft: `${(outlineLevel > 0 ? outlineLevel : 1) * 70}px`,
                                }}
                            >
                                <TailSpin
                                    strokeWidth={5}
                                    height="30"
                                    width="30"
                                    color="#03192F"
                                    radius="3"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            <div className="absolute z-20 font-normal">
                <NewModal
                    isShown={showAddChildTaskModal}
                    closeModal={() => setShowAddChildTaskModal(false)}
                >
                    <TaskForm
                        parentTask={task}
                        createCallback={(task: TaskJsonInterface) => {
                            dispatch(addTaskToParent({ task }))
                            dispatch(highlightTask(task.id))
                            setShowAddChildTaskModal(false)
                        }}
                    />
                </NewModal>
                <NewModal
                    isShown={showAddSiblingTaskModal}
                    closeModal={() => setShowAddSiblingTaskModal(false)}
                >
                    <TaskForm
                        parentTask={parentTask}
                        createCallback={(task: TaskJsonInterface) => {
                            dispatch(addTaskToParent({ task }))
                            dispatch(highlightTask(task.id))
                            setShowAddSiblingTaskModal(false)
                        }}
                    />
                </NewModal>
                <NewModal
                    isShown={showEditTaskModal}
                    closeModal={() => setShowEditTaskModal(false)}
                >
                    <TaskForm
                        edit={true}
                        editTaskId={task.id}
                        updateCallback={(task: TaskJsonInterface) => {
                            dispatch(highlightTask(task.id))
                            dispatch(updateTaskInTree({ task }))
                            setShowEditTaskModal(false)
                        }}
                    />
                </NewModal>
                <NewModal
                    isShown={showDeleteTaskModal}
                    closeModal={() => setShowDeleteTaskModal(false)}
                >
                    <TaskDeleteForm
                        task={task}
                        callback={(taskId: string) => {
                            dispatch(removeTaskFromParent(taskId))
                            setShowDeleteTaskModal(false)
                        }}
                    />
                </NewModal>
            </div>
        </>
    ) : null
}
