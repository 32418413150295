import * as OBC from "@thatopen/components"
import * as OBCF from "@thatopen/components-front"
import * as OBF from "@thatopen/fragments"

import * as THREE from "three"
import * as WEBIFC from "web-ifc"

export class IfcHandler {
    private container: HTMLDivElement
    private components: OBC.Components
    private worlds: OBC.Worlds
    private world: OBC.World
    private loader: OBCF.IfcStreamer
    private fragmentIfcLoader: OBC.IfcLoader
    private grids: OBC.Grids
    private indexer: OBC.IfcRelationsIndexer
    private selectedModel: OBF.FragmentsGroup | null = null
    private highLighter: OBCF.Highlighter
    private classifier: OBC.Classifier
    private firstPerson: OBC.FirstPersonMode

    private clipper: OBC.Clipper
    private exploder: OBC.Exploder
    private angles: OBCF.AngleMeasurement
    private edgeMeasurement: OBCF.EdgeMeasurement
    private faceMeasurement: OBCF.FaceMeasurement
    private volumeMeasurement: OBCF.VolumeMeasurement

    private fragmentsManager: OBC.FragmentsManager
    private plans: OBCF.Plans
    private edges: OBCF.ClipEdges
    private hider: OBC.Hider

    private relationMap: OBC.RelationsMap | null = null

    private defaultMinGloss: number

    public selection: {
        guid: string
        name: string
        properties: { [key: string]: string }[]
        quantities: { [key: string]: string }[]
    }[] = []
    public plansList: OBCF.PlanView[] = []

    private setSelection: (selection: OBF.FragmentIdMap | null) => void

    constructor(
        containerRef: HTMLDivElement,
        setSelection: (selection: OBF.FragmentIdMap | null) => void,
    ) {
        this.container = containerRef
        this.setSelection = setSelection
        this.components = new OBC.Components()

        this.worlds = this.components.get(OBC.Worlds)
        this.world = this.worlds.create<
            OBC.SimpleScene,
            OBC.OrthoPerspectiveCamera,
            OBCF.PostproductionRenderer
        >()
        this.world.scene = new OBC.SimpleScene(this.components)
        this.world.renderer = new OBCF.PostproductionRenderer(
            this.components,
            this.container,
        )

        const camera = new OBC.OrthoPerspectiveCamera(this.components)
        this.world.camera = camera
        this.container.appendChild(this.world.renderer.three.domElement)
        const whiteColor = new THREE.Color("white")
        const three: any = this.world.scene.three
        three.background = whiteColor
        this.highLighter = this.components.get(OBCF.Highlighter)
        this.highLighter.setup({ world: this.world })
        this.initHighLighter()

        this.components.init()
        const renderer: any = this.world.renderer
        renderer.postproduction.enabled = true
        renderer.postproduction.customEffects.outlineEnabled = true
        const scene: any = this.world.scene
        scene.setup()
        this.fragmentIfcLoader = this.components.get(OBC.IfcLoader)
        this.loader = new OBCF.IfcStreamer(this.components)
        this.loader.useCache = true
        this.loader.world = this.world
        this.world.camera.controls?.addEventListener("sleep", () => {
            this.loader.culler.needsUpdate = true
        })
        this.grids = this.components.get(OBC.Grids)
        this.grids.config.color.setHex(0x666666)
        const grid = this.grids.create(this.world)
        grid.three.position.y -= 1
        renderer.postproduction.customEffects.excludedMeshes.push(grid.three)
        this.indexer = this.components.get(OBC.IfcRelationsIndexer)
        this.classifier = this.components.get(OBC.Classifier)

        this.clipper = this.components.get(OBC.Clipper)
        this.exploder = this.components.get(OBC.Exploder)
        this.angles = this.components.get(OBCF.AngleMeasurement)
        this.angles.world = this.world
        this.edgeMeasurement = this.components.get(OBCF.EdgeMeasurement)
        this.edgeMeasurement.world = this.world
        this.faceMeasurement = this.components.get(OBCF.FaceMeasurement)
        this.faceMeasurement.world = this.world
        this.volumeMeasurement = this.components.get(OBCF.VolumeMeasurement)
        this.volumeMeasurement.world = this.world

        this.fragmentsManager = this.components.get(OBC.FragmentsManager)
        this.plans = this.components.get(OBCF.Plans)
        this.plans.world = this.world
        this.edges = this.components.get(OBCF.ClipEdges)
        this.defaultMinGloss = renderer.postproduction.customEffects.minGloss
        this.hider = this.components.get(OBC.Hider)

        this.firstPerson = new OBC.FirstPersonMode(camera)

        this.initEdgeClipper()
        this.initEvents()
    }

    public async loadIfcFile(ifcFileUrl: string) {
        await this.fragmentIfcLoader.setup()
        this.fragmentIfcLoader.settings.wasm = {
            path: "https://unpkg.com/web-ifc@0.0.57/",
            absolute: true,
        }
        console.log(ifcFileUrl)
        const file = await fetch(ifcFileUrl)
        const data = await file.arrayBuffer()
        const buffer = new Uint8Array(data)
        const model = await this.fragmentIfcLoader.load(buffer)
        model.name = "test"
        this.world.scene.three.add(model)
        this.relationMap = await this.indexer.process(model)
        this.selectedModel = model
        await this.plans.generate(this.selectedModel)
        this.plansList = this.plans.list
        this.classifier.byModel(this.selectedModel.uuid, this.selectedModel)
        this.classifier.byEntity(this.selectedModel)
        await this.classifier.bySpatialStructure(this.selectedModel)
    }

    public async loadIfcStreamer(ifcUrls: string[]) {
        this.loader.urls = ifcUrls

        const settingsUrl =
            ifcUrls.find((url) => url.includes("ifc-processed.json")) || ""
        const propertiesUrl =
            ifcUrls.find((url) =>
                url.includes("ifc-processed-properties.json"),
            ) || ""

        let propertiesData
        const streamLoaderSettings = await fetch(settingsUrl).then((res) =>
            res.json(),
        )
        const rawPropertiesData = await fetch(propertiesUrl)
        propertiesData = await rawPropertiesData.json()
        const model = await this.loader.load(
            streamLoaderSettings,
            true,
            propertiesData,
        )

        this.relationMap = await this.indexer.process(model)
        this.selectedModel = model
        await this.plans.generate(this.selectedModel)
        this.plansList = this.plans.list
        this.classifier.byModel(this.selectedModel.uuid, this.selectedModel)
        this.classifier.byEntity(this.selectedModel)
        await this.classifier.bySpatialStructure(this.selectedModel)
    }

    public async getSelectionProperties(selection: {
        id: number
        fragments: OBF.FragmentIdMap
    }) {
        if (!this.selectedModel) {
            throw new Error("No model selected")
        }
        const { name } = await OBC.IfcPropertiesUtils.getEntityName(
            this.selectedModel,
            selection.id,
        )
        const globalId: string =
            (await this.selectedModel.getProperties(selection.id))?.GlobalId
                .value ?? ""
        const propSets = this.indexer.getEntityRelations(
            this.selectedModel,
            selection.id,
            "IsDefinedBy",
        )
        if (propSets) {
            const properties: { [key: string]: string }[] = []
            const quantities: { [key: string]: string }[] = []
            for (const propSet of propSets) {
                await OBC.IfcPropertiesUtils.getPsetProps(
                    this.selectedModel,
                    propSet,
                    async (propId) => {
                        if (this.selectedModel) {
                            const prop =
                                await this.selectedModel.getProperties(propId)
                            if (prop) {
                                properties.push(prop)
                            }
                        }
                    },
                )
                await OBC.IfcPropertiesUtils.getQsetQuantities(
                    this.selectedModel,
                    propSet,
                    async (propId) => {
                        if (this.selectedModel) {
                            const prop =
                                await this.selectedModel.getProperties(propId)
                            if (prop) {
                                quantities.push(prop)
                            }
                        }
                    },
                )
            }
            return {
                name: name ?? "",
                guid: globalId,
                quantities: this.mapQuantities(quantities),
                properties: this.mapProperties(properties),
            }
        }
    }

    public async getSelectionEntities(selection: OBF.FragmentIdMap) {
        const ids = Object.values(selection)
            .map((set) => Array.from(set))
            .flat()
        return Promise.all(
            ids.map(async (id) => {
                if (this.selectedModel) {
                    const props = await this.selectedModel.getProperties(id)
                    return {
                        name: props?.Name.value ?? "",
                        guid: props?.GlobalId.value ?? "",
                        expressId: id,
                    }
                } else {
                    throw new Error("No model selected")
                }
            }),
        )
    }

    public async removeElementFromSelection(
        selection: OBF.FragmentIdMap,
        id: number,
    ) {
        const newSelection = { ...selection }
        for (const key in newSelection) {
            if (newSelection[key].has(id)) {
                newSelection[key].delete(id)
                if (newSelection[key].size === 0) {
                    delete newSelection[key]
                }
            }
        }
        this.setSelection(newSelection)
    }

    public async selectSimilarByEntities(expressId: number) {
        if (!this.selectedModel) {
            throw new Error("No model selected")
        }
        const similarSelectionEntity = Object.values(
            this.classifier.list.entities,
        ).find((selection) =>
            Object.values(selection).find((set) => set.has(expressId)),
        )
        if (similarSelectionEntity) {
            await this.highLighter.highlightByID(
                "click",
                similarSelectionEntity,
            )
            this.setSelection(similarSelectionEntity)
        }
    }

    public async selectSimilarByType(expressIds: number[]) {
        if (!this.selectedModel) {
            throw new Error("No model selected")
        }
        const selection: OBF.FragmentIdMap = {}
        for (const expressId of expressIds) {
            const entityRelations = this.indexer.getEntityRelations(
                this.selectedModel!,
                expressId,
                "IsTypedBy",
            )

            if (entityRelations) {
                for (let j = 0; j < entityRelations.length; j++) {
                    const relationId = entityRelations[j]
                    if (this.relationMap) {
                        const relatedMappedIds =
                            this.relationMap.get(relationId)
                        if (relatedMappedIds) {
                            const relatedArrayIds = new Set(
                                Array.from(relatedMappedIds.values()).flat(),
                            )
                            for (const relatedId of relatedArrayIds) {
                                for (const item of this.selectedModel!.items) {
                                    if (item.ids.has(relatedId)) {
                                        if (selection[item.id]) {
                                            selection[item.id].add(relatedId)
                                        } else {
                                            selection[item.id] = new Set([
                                                relatedId,
                                            ])
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        await this.highLighter.highlightByID("click", selection)
        this.setSelection(selection)
    }

    public async selectSimilarByCategory(expressIds: number[]) {
        if (!this.selectedModel) {
            throw new Error("No model selected")
        }
        const similarSelectionEntity = Object.values(
            this.classifier.list.entities,
        ).find((selection) =>
            expressIds.some((expressId) =>
                Object.values(selection).find((set) => set.has(expressId)),
            ),
        )

        if (similarSelectionEntity) {
            await this.highLighter.highlightByID(
                "click",
                similarSelectionEntity,
            )
            this.setSelection(similarSelectionEntity)
        }
    }

    public async setClipping(enabled: boolean) {
        if (!enabled) {
            this.clipper.deleteAll()
        }
        this.clipper.enabled = enabled
    }

    public async setExplode(enabled: boolean) {
        if (this.selectedModel) {
            if (enabled) {
                this.exploder.enabled = enabled
                this.exploder.set(enabled)
            } else {
                this.exploder.set(enabled)
                this.exploder.enabled = enabled
            }
        }
    }

    public async setAngleMeasurement(enabled: boolean) {
        if (!enabled) {
            this.angles.deleteAll()
        }
        this.angles.enabled = enabled
    }

    public async setDistanceMeasurement(enabled: boolean) {
        if (!enabled) {
            this.edgeMeasurement.deleteAll()
        }
        this.edgeMeasurement.enabled = enabled
    }

    public async setFaceMeasurement(enabled: boolean) {
        if (!enabled) {
            this.faceMeasurement.deleteAll()
        }
        this.faceMeasurement.enabled = enabled
    }

    public async setVolumeMeasurement(enabled: boolean) {
        if (!enabled) {
            this.volumeMeasurement.deleteAll()
        }
        this.volumeMeasurement.enabled = enabled
    }

    public async setPlans(enabled: boolean) {
        if (this.selectedModel) {
            if (!enabled) {
                const whiteColor = new THREE.Color("white")
                this.highLighter.backupColor = null
                this.highLighter.clear()
                const modelItems = this.classifier.find({
                    models: [this.selectedModel.uuid],
                })
                const renderer: any = this.world.renderer
                renderer.postproduction.customEffects.minGloss =
                    this.defaultMinGloss
                this.classifier.resetColor(modelItems)
                const three: any = this.world.scene.three
                three.background = whiteColor
                this.plans.exitPlanView()
            } else if (this.plansList.length > 0) {
                const modelItems = this.classifier.find({
                    models: [this.selectedModel.uuid],
                })
                const thickItems = this.classifier.find({
                    entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
                })

                const thinItems = this.classifier.find({
                    entities: [
                        "IFCDOOR",
                        "IFCWINDOW",
                        "IFCPLATE",
                        "IFCMEMBER",
                        "IFCBEAM",
                    ],
                })
                const whiteItems = this.classifier.find({
                    entities: ["IFCCOLUMN"],
                })
                for (const fragID in thickItems) {
                    const foundFrag = this.fragmentsManager.list.get(fragID)
                    if (!foundFrag) continue
                    const { mesh } = foundFrag
                    this.edges.styles.list.thick.fragments[fragID] = new Set(
                        thickItems[fragID],
                    )
                    this.edges.styles.list.thick.meshes.add(mesh)
                }
                for (const fragID in thinItems) {
                    const foundFrag = this.fragmentsManager.list.get(fragID)
                    if (!foundFrag) continue
                    const { mesh } = foundFrag
                    this.edges.styles.list.thin.fragments[fragID] = new Set(
                        thinItems[fragID],
                    )
                    this.edges.styles.list.thin.meshes.add(mesh)
                }
                const whiteColor = new THREE.Color("white")
                const renderer: any = this.world.renderer
                renderer.postproduction.customEffects.minGloss = 0.1
                this.highLighter.backupColor = whiteColor
                this.classifier.setColor(modelItems, whiteColor)
                this.classifier.setColor(whiteItems, whiteColor)
                const three: any = this.world.scene.three
                three.background = whiteColor
                this.plans.goTo(this.plansList[0].id)
                await this.edges.update(true)
            }
        }
    }

    async setPlanView(planId: string) {
        const planExists = this.plansList.find((plan) => plan.id === planId)
        if (planExists) {
            this.plans.goTo(planId)
        }
    }

    public setFirstPerson(enabled: boolean) {
        this.firstPerson.set(enabled)
        this.firstPerson.enabled = enabled
        if (enabled) {
            document.addEventListener("keydown", this.handleMovement)
        } else {
            document.removeEventListener("keydown", this.handleMovement)
        }
    }

    public getSpacialStructure() {
        if (!this.selectedModel) {
            throw new Error("No model selected")
        }
        return this.classifier.list.spatialStructures
            ? this.classifier.list.spatialStructures
            : {}
    }

    public async getElementName(expressId: number) {
        if (this.selectedModel) {
            let name = (
                await OBC.IfcPropertiesUtils.getEntityName(
                    this.selectedModel,
                    expressId,
                )
            ).name
            return name ? name : ""
        }
        return ""
    }

    public setSelectionVisibility(
        visible: boolean,
        selection: OBF.FragmentIdMap,
    ) {
        this.hider.set(visible, selection)
    }

    public async select(selection: OBF.FragmentIdMap) {
        this.highLighter.highlightByID("click", selection, true, true)
        this.setSelection(selection)
    }

    async test(expressIds: number[]) {}

    async dispose() {
        this.components.dispose()
    }

    private handleMovement = (event: KeyboardEvent) => {
        const camera = this.world.camera as OBC.OrthoPerspectiveCamera
        if (event.code === "KeyW" || event.code === "ArrowUp") {
            camera.controls.forward(0.25, true)
        }
        if (event.code === "KeyS" || event.code === "ArrowDown") {
            camera.controls.forward(-0.25, true)
        }
    }

    private async initEdgeClipper() {
        const grayFill = new THREE.MeshBasicMaterial({ color: "gray", side: 2 })
        const blackLine = new THREE.LineBasicMaterial({ color: "black" })
        const blackOutline = new THREE.MeshBasicMaterial({
            color: "black",
            opacity: 0.5,
            side: 2,
            transparent: true,
        })

        this.edges.styles.create(
            "thick",
            new Set(),
            this.world,
            blackLine,
            grayFill,
            blackOutline,
        )
        this.edges.styles.create("thin", new Set(), this.world)
    }

    private async initHighLighter() {
        const yellowColor = new THREE.Color(0xffff00)
        this.highLighter.add("click", yellowColor)
        this.container.onclick = async (e) => {
            let singleSelection = true
            if (e.ctrlKey) {
                singleSelection = false
            }
            const result = await this.highLighter.highlight(
                "click",
                singleSelection,
            )
            if (result) {
                const selection = singleSelection
                    ? result.fragments
                    : this.highLighter.selection.select
                this.setSelection({ ...selection })
            } else {
                this.setSelection(null)
            }
        }
        this.highLighter.events.select.onHighlight.add((event) => {
            if (this.volumeMeasurement.enabled) {
                this.volumeMeasurement.getVolumeFromFragments(event)
            }
        })
        this.highLighter.events.select.onClear.add(() => {
            this.volumeMeasurement.deleteAll()
        })
    }

    private initEvents() {
        this.container.ondblclick = async () => {
            if (this.clipper.enabled) {
                this.clipper.create(this.world)
            }
            if (this.faceMeasurement.enabled) {
                this.faceMeasurement.create()
            }
        }
        window.onkeydown = (event) => {
            if (
                event.code === "Delete" ||
                event.code === "Backspace" ||
                event.code === "Escape"
            ) {
                if (this.clipper.enabled) {
                    this.clipper.delete(this.world)
                }
                if (this.angles.enabled) {
                    this.angles.cancelCreation()
                    this.angles.create()
                }
                if (this.edgeMeasurement.enabled) {
                    this.edgeMeasurement.deleteAll()
                }
                if (this.faceMeasurement.enabled) {
                    this.faceMeasurement.deleteAll()
                }
                if (this.volumeMeasurement.enabled) {
                    this.volumeMeasurement.deleteAll()
                }
            }
        }
    }

    private mapQuantities(quantities: { [key: string]: any }[]) {
        const mappedQuantities: { [key: string]: number } = {}
        quantities.forEach((quantity) => {
            if (quantity.type === WEBIFC.IFCQUANTITYLENGTH) {
                return (mappedQuantities[quantity.Name.value] =
                    quantity.LengthValue.value)
            } else if (quantity.type === WEBIFC.IFCQUANTITYAREA) {
                return (mappedQuantities[quantity.Name.value] =
                    quantity.AreaValue.value)
            } else if (quantity.type === WEBIFC.IFCQUANTITYVOLUME) {
                return (mappedQuantities[quantity.Name.value] =
                    quantity.VolumeValue.value)
            } else if (quantity.type === WEBIFC.IFCQUANTITYCOUNT) {
                return (mappedQuantities[quantity.Name.value] =
                    quantity.CountValue.value)
            } else {
                console.error("Unknown quantity type", quantity.type)
            }
        })
        return mappedQuantities
    }

    private mapProperties(properties: { [key: string]: any }[]) {
        const mappedProperties: { [key: string]: string | number | boolean } =
            {}
        properties.forEach((property) => {
            mappedProperties[property.Name.value] = property.NominalValue.value
        })
        return mappedProperties
    }
}
