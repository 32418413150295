import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { MODULE_PERMISSIONS } from "@/models/Permission"
import { User } from "@/models/User"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getProjectUsersById } from "../projectsSlice"
import { UserModulePermissionForm } from "./UserModulePermissionForm"
import { clearErrors, removeUserFromProject } from "./userManagementSlice"

interface UserCardProps {
    user: User
}

export const UserCard: React.FC<UserCardProps> = ({ user }) => {
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const userPermissions = useAppSelector(selectUserPermissions)
    const status = useAppSelector((state) => state.userManagement.status)
    const projectStatus = useAppSelector((state) => state.projects.status)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    return (
        <div className="flex items-center gap-2 p-2 shadow-lg rounded-lg break-words relative">
            <img
                className="rounded-full w-[75px] h-[75px]"
                src={user.profilePicture.path}
                alt={user.fullName}
            />
            <div>
                <div className="absolute top-2 right-2 flex items-center gap-1 text-sm">
                    {userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.UserManager.Edit,
                    ]) && (
                        <FontAwesomeIcon
                            icon="cog"
                            className="text-yellow-200 hover:text-yellow-500 transition cursor-pointer"
                            onClick={() => setShowEdit(true)}
                        />
                    )}
                    {userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.UserManager.Remove,
                    ]) && (
                        <FontAwesomeIcon
                            icon="user-minus"
                            className="text-red-200 hover:text-red-500 transition cursor-pointer"
                            onClick={() => setShowDelete(true)}
                        />
                    )}
                </div>
                <div className="mt-2">
                    <h4 className="font-bold tex-lg">{user.fullName}</h4>
                    <div className="flex gap-1 items-center">
                        <FontAwesomeIcon icon="phone" className="text-xs" />
                        <p className="text-ellipsis text-sm break-words">
                            {user.phone}
                        </p>
                    </div>

                    <div className="flex gap-1 items-center">
                        <FontAwesomeIcon icon="envelope" className="text-xs" />
                        <p className="text-ellipsis text-sm break-words">
                            {user.email}
                        </p>
                    </div>
                </div>
            </div>
            <NewModal
                isShown={
                    showDelete &&
                    userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.UserManager.Remove,
                    ])
                }
                closeModal={() => setShowDelete(false)}
            >
                <Form
                    className="w-full"
                    statuses={[status.update, projectStatus.read]}
                    loadingTitle={t("removing_user")}
                    clearErrors={clearErrors}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            removeUserFromProject({
                                projectId,
                                userId: user.id,
                            }),
                        )
                        if (type === removeUserFromProject.fulfilled.type)
                            await dispatch(getProjectUsersById(projectId))
                        setShowDelete(false)
                    }}
                    submitType={SubmitType.Delete}
                >
                    <div className="flex flex-col gap-4 p-4 w-[400px]">
                        <h2 className="text-2xl font-bold">
                            {t("delete_user_notice", { user: user.fullName })}?
                        </h2>
                    </div>
                </Form>
            </NewModal>
            <NewModal
                isShown={
                    showEdit &&
                    userPermissions.hasAllPermissions([
                        MODULE_PERMISSIONS.UserManager.Edit,
                    ])
                }
                closeModal={() => setShowEdit(false)}
            >
                <UserModulePermissionForm
                    user={user}
                    closeModal={() => setShowEdit(false)}
                />
            </NewModal>
        </div>
    )
}
