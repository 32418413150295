import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
    HierarchyDropDown,
    HierarchyOptionProps,
} from "@/components/Inputs/HierarchyDropDown"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Form, SubmitType } from "@/features/Form/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FragmentIdMap } from "@thatopen/fragments"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    getProjectTasksTreesById,
    selectProjectTasksTree,
} from "../../projectsSlice"
import { IfcHandler } from "../IfcHandler"
import {
    attachEntitiesToTask,
    clearErrors,
    selectIfcEntities,
} from "../ifcSlice"

interface AttachToTaskFormProps {
    ifcHandler: IfcHandler | null
    selection: FragmentIdMap | null
    closeModal: () => void
}

export const AttachToTaskForm: FC<AttachToTaskFormProps> = ({
    ifcHandler,
    selection,
    closeModal,
}) => {
    const { t } = useTranslation()
    const projectTasks = useAppSelector(selectProjectTasksTree)
    const status = useAppSelector((state) => state.ifcViewer.status)
    const dispatch = useAppDispatch()
    const ifcEntities = useAppSelector(selectIfcEntities)
    const [selectedEntities, setSelectedEntities] = useState<
        {
            name: string
            guid: string
            expressId: number
        }[]
    >([])
    const [selectedTask, setSelectedTask] = useState<{
        value: string
        label: string
    }>({ value: "-1", label: "" })
    const [tasksHierarchyOptions, setTasksHierarchyOptions] = useState<
        HierarchyOptionProps[]
    >([])
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    useEffect(() => {
        dispatch(getProjectTasksTreesById(projectId))
        if (ifcHandler && selection) {
            ifcHandler.getSelectionEntities(selection).then((entities) => {
                const selectedEntities = entities.filter(
                    (entity) => entity !== undefined,
                )
                setSelectedEntities(selectedEntities)
            })
        }
    }, [selection])
    useEffect(() => {
        setTasksHierarchyOptions(
            projectTasks.map((task) => ({
                value: task.id,
                label: task.name,
                expanded: false,
                item: task,
            })),
        )
    }, [projectTasks])
    const attachEntities = async () => {
        const selectedEntitiesGlobalIds = selectedEntities.map(
            (entity) => entity.guid,
        )
        const entitiesIdsToAttach = ifcEntities
            .filter((entity) =>
                selectedEntitiesGlobalIds.includes(entity.globalId),
            )
            .map((entity) => entity.id)

        const { type } = await dispatch(
            attachEntitiesToTask({
                projectId,
                taskId: selectedTask.value,
                ifcEntitiesIds: entitiesIdsToAttach,
            }),
        )
        if (type === attachEntitiesToTask.fulfilled.type) {
            closeModal()
        }
    }
    return (
        <Form
            statuses={[status.update]}
            loadingTitle={t("attaching_entities")}
            onSubmit={attachEntities}
            submitType={SubmitType.Save}
            clearErrors={clearErrors}
        >
            <div className="flex flex-col gap-4">
                <h1 className="font-bold text-xl">
                    {t("attach_to_task_notice")}
                </h1>
                <div className="flex items-start gap-2">
                    <p className="font-bold text-lg">{t("elements")}: </p>
                    <div className="p-2 rounded-lg border w-full border-primary-100">
                        <Scrollable height="100px">
                            <ul className="w-full">
                                {selectedEntities.map((entity) => (
                                    <li
                                        key={entity.guid}
                                        className="flex items-center gap-2"
                                    >
                                        {entity.name}
                                        <FontAwesomeIcon
                                            icon="times"
                                            className="cursor-pointer text-red-400 hover:text-red-600 transition"
                                            onClick={() => {
                                                if (ifcHandler && selection) {
                                                    ifcHandler.removeElementFromSelection(
                                                        selection,
                                                        entity.expressId,
                                                    )
                                                }
                                            }}
                                        ></FontAwesomeIcon>
                                    </li>
                                ))}
                            </ul>
                        </Scrollable>
                    </div>
                </div>
                <div className="flex items-center gap-2 w-full">
                    <p className="font-bold text-lg">{t("task")}: </p>
                    <HierarchyDropDown
                        className="w-full"
                        value={selectedTask}
                        placeholder={t("select_task")}
                        onChange={
                            setSelectedTask as Dispatch<
                                SetStateAction<{
                                    value: string | number
                                    label: string
                                }>
                            >
                        }
                        options={tasksHierarchyOptions}
                        name={t("parent_task")}
                    ></HierarchyDropDown>
                </div>
            </div>
        </Form>
    )
}
