import { createAsyncThunkWithNotification } from "@/app/common"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import {
    PricingPlan,
    PricingPlanInterface,
    PricingPlanUpdatePayload,
    UserPricingPlan,
    UserPricingPlanInterface,
} from "@/models/Pricing"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    assignUsersToPricingPlanApi,
    createPricingPlanApi,
    deletePricingPlanApi,
    expireUserPricingPlanApi,
    extendUserPricingPlanApi,
    getPricingPlansApi,
    getUsersPricingPlansApi,
    getUsersPricingPlansStatsApi,
    updatePricingPlanApi,
} from "./pricingApi"

export const getPricingPlans = createAsyncThunkWithNotification(
    "pricing/getPricingPlans",
    async () => {
        const response = await getPricingPlansApi()
        return response
    },
)

export const createPricingPlan = createAsyncThunkWithNotification(
    "pricing/createPricingPlan",
    async (data: PricingPlanInterface) => {
        const response = await createPricingPlanApi(data)
        return response
    },
)

export const getUsersPricingPlan = createAsyncThunkWithNotification(
    "pricing/getUsersPricingPlan",
    async (pricingPlanId?: string) => {
        const response = await getUsersPricingPlansApi(pricingPlanId)
        return response
    },
)

export const updatePricingPlan = createAsyncThunkWithNotification(
    "pricing/updatePricingPlan",
    async ({
        pricingPlanId,
        data,
    }: {
        pricingPlanId: string
        data: PricingPlanUpdatePayload
    }) => {
        const response = await updatePricingPlanApi(pricingPlanId, data)
        return response
    },
)

export const assignUsersToPricingPlan = createAsyncThunkWithNotification(
    "pricing/assignUsersToPricingPlan",
    async ({
        userIds,
        pricingPlanId,
        expiresAt,
    }: {
        userIds: string[]
        pricingPlanId: string
        expiresAt: Date
    }) => {
        const response = await assignUsersToPricingPlanApi(
            userIds,
            pricingPlanId,
            expiresAt,
        )
        return response
    },
)

export const deletePricingPlan = createAsyncThunkWithNotification(
    "pricing/deletePricingPlan",
    async (pricingPlanId: string) => {
        const response = await deletePricingPlanApi(pricingPlanId)
        return response
    },
)

export const extendUserPricingPlan = createAsyncThunkWithNotification(
    "pricing/extendUserPricingPlan",
    async ({
        userPricingPlanId,
        expiresAt,
    }: {
        userPricingPlanId: string
        expiresAt: Date
    }) => {
        const response = await extendUserPricingPlanApi(
            userPricingPlanId,
            expiresAt,
        )
        return response
    },
)

export const expireUserPricingPlan = createAsyncThunkWithNotification(
    "pricing/expireUserPricingPlan",
    async (userPricingPlanId: string) => {
        const response = await expireUserPricingPlanApi(userPricingPlanId)
        return response
    },
)

export const getUsersPricingPlansStats = createAsyncThunkWithNotification(
    "pricing/getUsersPricingPlansStats",
    async ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
        const response = await getUsersPricingPlansStatsApi(startDate, endDate)
        return response
    },
)

interface PricingState {
    pricingPlans: PricingPlanInterface[]
    userPricingPlans: UserPricingPlanInterface[]
    usersPricingPlansStats: {
        [key: string]: {
            [key: string]: number
        }
    }[]
    status: ReducerStatus
    errors: FormErrors
}

const initialState: PricingState = {
    pricingPlans: [],
    userPricingPlans: [],
    usersPricingPlansStats: [],
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

const pricingSlice = createSlice({
    name: "pricing",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPricingPlans.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getPricingPlans.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.pricingPlans = action.payload.data.data
            })
            .addCase(getPricingPlans.rejected, (state, action) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(createPricingPlan.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(createPricingPlan.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.pricingPlans = [
                    ...state.pricingPlans,
                    action.payload.data.data,
                ]
            })
            .addCase(createPricingPlan.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data.errors
            })
            .addCase(getUsersPricingPlan.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUsersPricingPlan.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.userPricingPlans = action.payload.data.data
            })
            .addCase(getUsersPricingPlan.rejected, (state, action) => {
                state.status.read = SliceStatus.FAILED
            })
            .addCase(updatePricingPlan.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updatePricingPlan.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.pricingPlans = state.pricingPlans.map((plan) =>
                    plan.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : plan,
                )
            })
            .addCase(updatePricingPlan.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data.errors
            })
            .addCase(assignUsersToPricingPlan.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(assignUsersToPricingPlan.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.userPricingPlans = action.payload.data.data
            })
            .addCase(assignUsersToPricingPlan.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data.errors
            })
            .addCase(deletePricingPlan.pending, (state) => {
                state.status.delete = SliceStatus.LOADING
            })
            .addCase(deletePricingPlan.fulfilled, (state, action) => {
                state.status.delete = SliceStatus.IDLE
                state.pricingPlans = state.pricingPlans.filter(
                    (plan) => plan.id !== action.payload.data.data,
                )
            })
            .addCase(deletePricingPlan.rejected, (state, action) => {
                state.status.delete = SliceStatus.FAILED
            })
            .addCase(extendUserPricingPlan.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(extendUserPricingPlan.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.userPricingPlans = state.userPricingPlans.map((plan) =>
                    plan.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : plan,
                )
            })
            .addCase(extendUserPricingPlan.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(expireUserPricingPlan.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(expireUserPricingPlan.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.userPricingPlans = state.userPricingPlans.map((plan) =>
                    plan.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : plan,
                )
            })
            .addCase(expireUserPricingPlan.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
            })
            .addCase(getUsersPricingPlansStats.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getUsersPricingPlansStats.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.usersPricingPlansStats = action.payload.data.data
            })
            .addCase(getUsersPricingPlansStats.rejected, (state, action) => {
                state.status.read = SliceStatus.FAILED
            })
    },
})

const selectPricingPlansRaw = (state: { pricing: PricingState }) =>
    state.pricing.pricingPlans

export const selectPricingPlans = createSelector(
    [selectPricingPlansRaw],
    (plans) => plans.map((plan) => new PricingPlan(plan)),
)

export const selectUserPricingPlansRaw = (state: { pricing: PricingState }) =>
    state.pricing.userPricingPlans
export const selectUserPricingPlans = createSelector(
    [selectUserPricingPlansRaw],
    (plans) => plans.map((plan) => new UserPricingPlan(plan)),
)

export const { clearErrors } = pricingSlice.actions

export default pricingSlice.reducer
