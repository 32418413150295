import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { ProjectCard } from "@/components/Cards/ProjectCard"
import { NoData } from "@/components/Errors/NoData"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { ProjectForm } from "./Forms/ProjectForm"
import {
    getUserProjects,
    getUserSharedProjects,
    selectProjects,
    selectSharedProjects,
} from "./projectsSlice"

export const Projects = () => {
    const dispatch = useAppDispatch()
    const projects = useAppSelector(selectProjects)
    const status = useAppSelector((state) => state.projects.status)
    const sharedProjects = useAppSelector(selectSharedProjects)
    const [filteredProjects, setFilteredProjects] = React.useState(projects)
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>("")
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getUserProjects({}))
        dispatch(getUserSharedProjects({}))
    }, [])
    useEffect(() => {
        setFilteredProjects(
            projects.filter((project) =>
                project.name.toLowerCase().includes(search.toLowerCase()),
            ),
        )
    }, [search, projects])

    return (
        <div className="max-h-full h-full">
            <div className="shadow-lg bg-secondary-100 rounded-xl h-full flex flex-col">
                <div className="flex justify-between items-center bg-primary-300 text-secondary-100 p-5">
                    <h1 className="text-4xl font-bold">{t("my_projects")}: </h1>
                </div>
                <div className="p-5 bg-secondary-100 flex flex-col h-full overflow-hidden">
                    <div className="w-full mb-5 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center h-full p-5 sm:gap-4 sm:flex-col-reverse">
                            <Input
                                label={t("search") + ":"}
                                type="text"
                                name="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="flex justify-center items-end gap-3 mt-0"
                            ></Input>
                            <div className="flex justify-center items-center gap-4">
                                <FontAwesomeIcon icon="list"></FontAwesomeIcon>
                                <FontAwesomeIcon icon="table"></FontAwesomeIcon>
                                <Button
                                    name={t("create_project")}
                                    icon="plus"
                                    onClick={() => setShowCreateModal(true)}
                                ></Button>
                            </div>
                        </div>
                    </div>
                    <Scrollable className="overflow-x-hidden" height="100%">
                        <h2 className="text-2xl font-bold border-b w-fit border-b-primary-300 my-2">
                            {t("my_projects")}:
                        </h2>
                        <div>
                            <WithLoader
                                statuses={[status.read]}
                                title={t("loading_projects")}
                            >
                                {filteredProjects.length > 0 ? (
                                    <div className="flex  justify-between flex-wrap gap-4">
                                        {filteredProjects.map((project) => (
                                            <ProjectCard
                                                key={project.id}
                                                project={project}
                                            ></ProjectCard>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center p-5 w-full">
                                        <NoData
                                            message={t(
                                                "you_dont_have_any_projects_yet",
                                            )}
                                            className="w-56"
                                        />
                                    </div>
                                )}
                            </WithLoader>
                        </div>
                        <h2 className="text-2xl font-bold border-b w-fit border-b-primary-300 my-4">
                            {t("shared_projects")}:
                        </h2>
                        <div className="flex gap-10 flex-wrap justify-between">
                            <WithLoader
                                statuses={[status.read]}
                                title={t("loading_projects")}
                            >
                                {sharedProjects.length > 0 ? (
                                    sharedProjects.map((project) => (
                                        <ProjectCard
                                            key={project.id}
                                            project={project}
                                        ></ProjectCard>
                                    ))
                                ) : (
                                    <div className="flex flex-col justify-center items-center p-5 w-full">
                                        <NoData
                                            message={t(
                                                "you_dont_have_any_projects_yet",
                                            )}
                                            className="w-56"
                                        />
                                    </div>
                                )}
                            </WithLoader>
                        </div>
                    </Scrollable>
                </div>
            </div>
            <NewModal
                isShown={showCreateModal}
                closeModal={() => setShowCreateModal(false)}
            >
                <ProjectForm
                    closeModal={() => setShowCreateModal(false)}
                ></ProjectForm>
            </NewModal>
            <Outlet></Outlet>
        </div>
    )
}
