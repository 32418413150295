import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { TaskReviewStatusBar } from "@/components/Other/TaskReviewStatusBar"
import { Form, SubmitType } from "@/features/Form/Form"
import {
    getCurrentUserPermissionsInProject,
    selectUserPermissions,
} from "@/features/User/userSlice"
import { TaskReview as TaskReviewModel } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { clearErrors, removeTaskReviewsById } from "./tasksSlice"

interface TaskReviewProps {
    review: TaskReviewModel
    highlighted?: boolean
    highlightedRef?: React.RefObject<HTMLDivElement> | null
}

export const TaskReview: React.FC<TaskReviewProps> = ({
    review,
    highlighted = false,
    highlightedRef = null,
}) => {
    const { t } = useTranslation()
    const userPermissions = useAppSelector(selectUserPermissions)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [localHighlighted, setLocalHighlighted] =
        useState<boolean>(highlighted)
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const status = useAppSelector((state) => state.tasks.status)
    const userPermissionStatus = useAppSelector((state) => state.users.status)
    const removeReview = () => {
        dispatch(
            removeTaskReviewsById({
                projectId,
                taskId,
                reviewers: [review.reviewer.id],
                groups: [],
            }),
        ).then(() => {
            dispatch(getCurrentUserPermissionsInProject(projectId))
            setShowModal(false)
        })
    }
    return (
        <div
            className={`bg-secondary-100 p-4 border-b border-l border-primary-100 border-opacity-25 rounded-bl-lg ${
                localHighlighted
                    ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                    : ""
            }`}
            ref={highlightedRef}
            onMouseEnter={() => setLocalHighlighted(false)}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                    <img
                        src={review.reviewer.profilePicture.path}
                        alt=""
                        className="w-8 h-8 rounded-full"
                    />
                    <h4 className="text-lg font-bold">
                        {review.reviewer.fullName}
                    </h4>
                    <TaskReviewStatusBar reviewStatus={review.status} />
                </div>
                <div className="flex gap-2">
                    <p className="text-sm sm:hidden">
                        {t("intlDateTime", {
                            val: review.updatedAt,
                            formatParams: {
                                val: {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                    {userPermissions.hasAllPermissions([
                        `task.${review.task.id}.manager`,
                    ]) && (
                        <FontAwesomeIcon
                            icon="times"
                            className="text-red-400 cursor-pointer hover:text-red-600 transition"
                            onClick={() => setShowModal(true)}
                        />
                    )}
                </div>
            </div>
            <NewModal isShown={showModal} closeModal={() => setShowModal(true)}>
                <Form
                    statuses={[status.update, userPermissionStatus.read]}
                    loadingTitle={t("removing_reviewer")}
                    clearErrors={clearErrors}
                    onSubmit={removeReview}
                    submitType={SubmitType.Delete}
                >
                    <div className="items-center">
                        <p className="font-bold text-xl">
                            {t("review_delete_notice", {
                                user: review.reviewer.fullName,
                            })}
                        </p>
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
