import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { selectUserPermissions } from "@/features/User/userSlice"
import { PermissionType } from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import { TaskDependencyForm } from "./TaskForms/TaskDependencyForm"
import { getTaskDependenciesById, selectTask } from "./tasksSlice"

interface TaskDependenciesProps {}

export const TaskDependencies: React.FC<TaskDependenciesProps> = ({}) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = React.useState(false)
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const task = useAppSelector(selectTask)
    const userPermissions = useAppSelector(selectUserPermissions)
    const requirePermissions: PermissionType[] = [
        `task.${task.id}.manager`,
        `task.${task.id}.reviewer`,
    ]
    const [queryParams] = useSearchParams()
    const [highlighted, setHighlighted] = React.useState<string>("")
    const containerRef = React.useRef<HTMLDivElement>(null)
    const highlightedRef = React.useRef<HTMLDivElement>(null)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getTaskDependenciesById({ projectId, taskId }))
    }, [])
    useEffect(() => {
        const dependency = queryParams.get("dependency")
        if (dependency) {
            setHighlighted(dependency)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])

    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col">
            <div className="flex justify-between items-center gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("dependencies")}
                </h1>
                {userPermissions.hasOneOfPermissions(requirePermissions) && (
                    <Button
                        name={t("manage_dependencies")}
                        type="button"
                        onClick={() => setShowModal(true)}
                    />
                )}
            </div>

            {task.dependencies.length > 0 ? (
                <Scrollable height="400px" innerRef={containerRef}>
                    {task.dependencies.map((dependency) => (
                        <div
                            key={dependency.id}
                            className={`flex gap-2 items-center ${
                                highlighted === dependency.id
                                    ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                                    : ""
                            }`}
                            onMouseEnter={() => {
                                if (dependency.id === highlighted) {
                                    setHighlighted("")
                                }
                            }}
                            ref={
                                highlighted === dependency.id
                                    ? highlightedRef
                                    : null
                            }
                        >
                            <FontAwesomeIcon icon="link" />
                            <p>
                                <span className="font-bold">
                                    {t(
                                        dependency.dependencyType.toLocaleLowerCase(),
                                    )}
                                    :
                                </span>
                                {" " +
                                    dependency.dependencyTask.name +
                                    ` (${dependency.dependencyTask.wbs})`}
                            </p>
                            <p>
                                <span className="font-bold">
                                    {t(
                                        dependency.relationType.toLocaleLowerCase(),
                                    )}
                                </span>
                            </p>
                            <p>
                                {dependency.lag +
                                    " " +
                                    t("lag").toLowerCase() +
                                    " " +
                                    (dependency.lag > 1 ? t("days") : t("day"))}
                            </p>
                        </div>
                    ))}
                </Scrollable>
            ) : (
                <div className="w-full h-full flex justify-center items-center opacity-20">
                    <h1 className="text-xl flex flex-col items-center">
                        <FontAwesomeIcon
                            icon="chart-gantt"
                            className="text-6xl"
                        />
                        <p>{t("no_dependencies")}...</p>
                    </h1>
                </div>
            )}

            <NewModal
                isShown={
                    showModal &&
                    userPermissions.hasOneOfPermissions(requirePermissions)
                }
                closeModal={() => setShowModal(false)}
            >
                <TaskDependencyForm task={task} />
            </NewModal>
        </div>
    )
}
