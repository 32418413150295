import { ResourcesIds } from "@/app/common"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { UsersOrGroupsInput } from "@/components/Inputs/UsersOrGroupsInput"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Form, SubmitType } from "@/features/Form/Form"
import {
    getCurrentUserPermissionsInProject,
    selectUserPermissions,
} from "@/features/User/userSlice"
import { PermissionType } from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { getProjectUsersById, selectProjectUsers } from "../projectsSlice"
import {
    getProjectGroups,
    selectProjectGroups,
} from "../UserManagement/userManagementSlice"
import {
    addTaskAssigneesById,
    clearErrors,
    getTaskAssigneesById,
    removeTaskAssigneesById,
    selectTask,
} from "./tasksSlice"

interface TaskAssigneesProps {}

export const TaskAssignees: React.FC<TaskAssigneesProps> = ({}) => {
    const dispatch = useAppDispatch()
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const task = useAppSelector(selectTask)
    const userPermissions = useAppSelector(selectUserPermissions)
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)
    const status = useAppSelector((state) => state.tasks.status)
    const userPermissionStatus = useAppSelector((state) => state.users.status)
    const [selectedAssignee, setSelectedAssignee] = useState<ResourcesIds>({
        users: [],
        groups: [],
    })
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [assigneesToAdd, setAssigneesToAdd] = useState<ResourcesIds>({
        users: [],
        groups: [],
    })
    const [queryParams] = useSearchParams()
    const [highlighted, setHighlighted] = useState<string>("")
    const highlightedRef = React.useRef<HTMLDivElement>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const requiredPermissions: PermissionType[] = [
        `task.${task.id}.manager`,
        `task.${task.id}.reviewer`,
    ]
    useEffect(() => {
        dispatch(getTaskAssigneesById({ projectId, taskId }))
    }, [])
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])
    const addAssignees = () => {
        dispatch(
            addTaskAssigneesById({
                projectId,
                taskId,
                assignees: assigneesToAdd.users,
                groups: assigneesToAdd.groups,
            }),
        ).then(() => {
            dispatch(getCurrentUserPermissionsInProject(projectId))
            setAssigneesToAdd({ users: [], groups: [] })
            setShowAddModal(false)
        })
    }
    const removeAssignees = async () => {
        let closeModal = true
        const { type } = await dispatch(
            removeTaskAssigneesById({
                projectId,
                taskId,
                assignees: selectedAssignee.users,
                groups: selectedAssignee.groups,
            }),
        )
        if (type !== removeTaskAssigneesById.fulfilled.type) {
            closeModal = false
        }
        await dispatch(getCurrentUserPermissionsInProject(projectId))

        if (closeModal) {
            setShowRemoveModal(false)
        }
    }

    useEffect(() => {
        const assignee = queryParams.get("assignee")
        if (assignee) {
            setHighlighted(assignee)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col">
            <div className="flex items-center justify-between gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("assignees")}
                </h1>
                {userPermissions.hasOneOfPermissions(requiredPermissions) && (
                    <Button
                        name={t("add_assignee")}
                        onClick={() => setShowAddModal(true)}
                    />
                )}
            </div>
            <div className="w-full mt-4">
                {task.assignees.length > 0 || task.groupsAssigned.length > 0 ? (
                    <Scrollable height="400px" innerRef={containerRef}>
                        {task.assignees.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("users")}
                                </h1>
                                {task.assignees.map((assignee) => (
                                    <div
                                        key={assignee.id}
                                        className={`flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg ${
                                            highlighted === assignee.id
                                                ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                                                : ""
                                        }`}
                                        ref={
                                            highlighted === assignee.id
                                                ? highlightedRef
                                                : null
                                        }
                                        onMouseEnter={() => {
                                            if (highlighted === assignee.id) {
                                                setHighlighted("")
                                            }
                                        }}
                                    >
                                        <div className="flex items-center gap-3 ">
                                            <img
                                                src={
                                                    assignee.profilePicture.path
                                                }
                                                alt=""
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <h4 className="text-lg font-bold">
                                                {assignee.fullName}
                                            </h4>
                                        </div>
                                        <div className="flex gap-2">
                                            <FontAwesomeIcon
                                                icon="times"
                                                className="text-red-400 cursor-pointer hover:text-red-600 transition"
                                                onClick={() => {
                                                    setSelectedAssignee({
                                                        users: [assignee.id],
                                                        groups: [],
                                                    })
                                                    setShowRemoveModal(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {task.groupsAssigned.length > 0 && (
                            <div className="flex flex-col gap-4">
                                <h1 className="text-lg font-bold">
                                    {t("groups")}
                                </h1>
                                {task.groupsAssigned.map((group) => (
                                    <div
                                        key={group.id}
                                        className={`flex justify-between items-center bg-secondary-100 p-4 border-b border-r border-primary-100 border-opacity-25 rounded-br-lg ${
                                            highlighted === group.id
                                                ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                                                : ""
                                        }`}
                                        ref={
                                            highlighted === group.id
                                                ? highlightedRef
                                                : null
                                        }
                                        onMouseEnter={() => {
                                            if (highlighted === group.id) {
                                                setHighlighted("")
                                            }
                                        }}
                                    >
                                        <div className="flex justify-center items-center gap-4">
                                            <div className="flex w-8 h-8 justify-center items-center border-2 border-primary-300 rounded-full">
                                                <FontAwesomeIcon
                                                    icon="user-group"
                                                    className="text-xs"
                                                />
                                            </div>
                                            <h4 className="text-lg font-bold">
                                                {group.name}
                                            </h4>
                                        </div>
                                        <div className="flex gap-2">
                                            <FontAwesomeIcon
                                                icon="times"
                                                className="text-red-400 cursor-pointer hover:text-red-600 transition"
                                                onClick={() => {
                                                    setSelectedAssignee({
                                                        users: [],
                                                        groups: [group.id],
                                                    })
                                                    setShowRemoveModal(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="user-minus"
                                className="mr-2 text-6xl"
                            />
                            <p>{t("no_assignees_yet")}...</p>
                        </h1>
                    </div>
                )}
            </div>
            <NewModal
                isShown={showRemoveModal}
                closeModal={() => setShowRemoveModal(false)}
            >
                <Form
                    statuses={[
                        status.update,
                        userPermissionStatus.read,
                        status.read,
                    ]}
                    loadingTitle={t("removing_assignee")}
                    clearErrors={clearErrors}
                    submitType={SubmitType.Delete}
                    onCancel={() => setShowRemoveModal(false)}
                    onSubmit={removeAssignees}
                >
                    <div className="items-center">
                        <p className="font-bold text-xl">
                            {t("remove_assignee_notice", {
                                name:
                                    projectGroups.find(
                                        (group) =>
                                            group.id ===
                                            selectedAssignee.groups[0],
                                    )?.name ??
                                    projectUsers.find(
                                        (user) =>
                                            user.id ===
                                            selectedAssignee.users[0],
                                    )?.fullName ??
                                    "",
                            })}
                        </p>
                    </div>
                </Form>
            </NewModal>
            <NewModal
                isShown={showAddModal}
                closeModal={() => setShowAddModal(false)}
            >
                <div className="w-full h-full flex flex-col gap-4">
                    <h1 className="text-2xl font-bold">{t("add_assignees")}</h1>
                    <Form
                        statuses={[
                            status.update,
                            userPermissionStatus.read,
                            status.read,
                        ]}
                        loadingTitle={t("adding_new_assignees")}
                        clearErrors={clearErrors}
                        submitType={SubmitType.Save}
                        onCancel={() => setShowAddModal(false)}
                        onSubmit={addAssignees}
                    >
                        <div className="flex flex-col gap-4 ">
                            <UsersOrGroupsInput
                                value={assigneesToAdd}
                                setValue={(value) =>
                                    setAssigneesToAdd((prev) => {
                                        return {
                                            ...prev,
                                            ...value,
                                        }
                                    })
                                }
                                users={projectUsers.filter(
                                    (user) =>
                                        !task.assignees.some(
                                            (assignee) =>
                                                assignee.id === user.id,
                                        ),
                                )}
                                groups={projectGroups.filter(
                                    (group) =>
                                        !task.groupsAssigned.some(
                                            (groupAssigned) =>
                                                groupAssigned.id === group.id,
                                        ),
                                )}
                            />
                        </div>
                    </Form>
                </div>
            </NewModal>
        </div>
    )
}
