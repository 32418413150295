import { NewModal } from "@/components/Layouts/NewModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { TaskForm } from "./TaskForm"

interface TasksProps {}

export const Tasks: React.FC<TasksProps> = () => {
    const { t } = useTranslation()
    const [showCreateTask, setShowCreateTask] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    return (
        <div className="flex flex-col h-full overflow-hidden p-3">
            <div className="flex items-center justify-between py-2">
                <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                    {t("tasks")}
                </h1>
                <FontAwesomeIcon
                    icon="list"
                    className={`text-xl transition hover:bg-primary-100 hover:text-secondary-100 p-2 rounded-full cursor-pointer ${showMenu ? 
                        `bg-primary-100 text-secondary-100` : 
                        `bg-secondary-300 text-primary-300`}`
                    }
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            <Outlet
                context={[
                    showCreateTask,
                    setShowCreateTask,
                    showMenu,
                    setShowMenu,
                ]}
            />
            <NewModal
                isShown={showCreateTask}
                closeModal={() => setShowCreateTask(false)}
            >
                <TaskForm />
            </NewModal>
        </div>
    )
}
