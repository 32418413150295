import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { ProfilePictureInput } from "@/components/Inputs/ProfilePictureInput"
import { FormStep } from "@/components/Layouts/FormStep"
import { FormWithSteps } from "@/components/Layouts/FormWithSteps"
import { User } from "@/models/User"
import { InvitationResponse } from "@/pages/Invitation"
import { updateInvitation } from "@/pages/Invitation/invitationSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { SubmitType } from "../Form/Form"
import { clearErrors, register } from "./authSlice"

export const Register = () => {
    const { t } = useTranslation()
    const invitation = useAppSelector((state) => state.invitation.invitation)
    const status = useAppSelector((state) => state.auth.status)
    const [throughInvitation, setThroughInvitation] =
        React.useState<boolean>(false)
    const [localUser, setLocalUser] = React.useState<User>(new User())
    const [profilePicture, setProfilePicture] = React.useState<File | null>(
        null,
    )
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")
    const [socialRegister, setSocialRegister] = React.useState<boolean>(true)
    const errors = useAppSelector((state) => state.auth.errors)
    const dispatch = useAppDispatch()
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const payload = localUser.registerPayload(
            confirmPassword,
            profilePicture,
        )
        if (throughInvitation) {
            dispatch(
                updateInvitation({
                    token: invitation.token,
                    response: InvitationResponse.ACCEPT,
                }),
            )
        }
        dispatch(register(payload))
    }

    useEffect(() => {
        if (invitation && invitation.email) {
            setThroughInvitation(true)
            setLocalUser(
                (prevState) =>
                    new User({
                        ...prevState.toJson(),
                        email: invitation.email,
                    }),
            )
        }
    }, [invitation])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalUser(
            (prevState) =>
                new User({
                    ...prevState.toJson(),
                    [e.target.name]: e.target.value,
                }),
        )
    }

    return (
        <div className="text-primary-300 bg-secondary-100 w-[500px] px-12 py-8 h-full flex flex-col items-center justify-center lg:w-full">
            <div className="w-full min-h-full flex flex-col items-center justify-center">
                <h1 className="font-bold text-3xl w-full text-center">
                    {throughInvitation ? t("create_an_account") : t("register")}
                </h1>
                <div className="w-full">
                    {socialRegister && (
                        <div className="flex flex-col w-full items-center mt-4">
                            <ul className="flex flex-col w-full items-center justify-center">
                                <li
                                    className="flex items-center justify-center cursor-pointer group transition border border-primary-300 w-full p-2 hover:bg-primary-300 mt-4"
                                    onClick={() => setSocialRegister(false)}
                                >
                                    <FontAwesomeIcon
                                        icon={"envelope"}
                                        className="text-primary-300 group-hover:text-secondary-100 transition text-3xl"
                                    />
                                    <p className="group-hover:text-secondary-100 ml-2">
                                        {t("continue_with_email")}
                                    </p>
                                </li>
                            </ul>
                            {!throughInvitation && (
                                <span className="flex w-full justify-center mt-4">
                                    <p className="mr-1">
                                        {t("already_have_an_account")}?
                                    </p>
                                    <Link to="/auth/login">
                                        <p className="border-b border-b-primary-300 cursor-pointer">
                                            {t("login")}
                                        </p>
                                    </Link>
                                </span>
                            )}
                        </div>
                    )}
                    {!socialRegister && (
                        <FormWithSteps
                            onSubmit={onSubmit}
                            statuses={[status.read]}
                            loadingTitle={t("creating_account")}
                            submitType={SubmitType.Create}
                            customSubmitButton={<div></div>}
                            clearErrors={clearErrors}
                        >
                            <FormStep>
                                <span
                                    className="font-medium flex items-center cursor-pointer hover:font-bold transition"
                                    onClick={() => setSocialRegister(true)}
                                >
                                    <FontAwesomeIcon
                                        icon={"chevron-left"}
                                        className="text-sm"
                                    />
                                    <p className="ml-1">{t("back")}</p>
                                </span>
                                <div className="flex justify-between items-start">
                                    <Input
                                        className="w-2/5"
                                        name="firstName"
                                        value={localUser.firstName}
                                        onChange={handleChange}
                                        type="text"
                                        label={t("first_name")}
                                        placeholder="Jhon"
                                        errors={errors.firstName}
                                    ></Input>
                                    <Input
                                        className="w-2/5"
                                        name="lastName"
                                        value={localUser.lastName}
                                        onChange={handleChange}
                                        type="text"
                                        label={t("last_name")}
                                        placeholder="Smith"
                                        errors={errors.lastName}
                                    ></Input>
                                </div>
                                <Input
                                    name="phone"
                                    value={localUser.phone}
                                    onChange={handleChange}
                                    type="text"
                                    label={t("phone")}
                                    placeholder="+216 54 545 545"
                                    errors={errors.phone}
                                ></Input>
                                <Input
                                    name="email"
                                    value={localUser.email}
                                    onChange={handleChange}
                                    type="email"
                                    label={t("email")}
                                    placeholder="jhon@smith.com"
                                    disabled={throughInvitation}
                                    errors={errors.email}
                                ></Input>
                                <div className="flex gap-16 items-start">
                                    <Input
                                        name="password"
                                        value={localUser?.password ?? ""}
                                        onChange={handleChange}
                                        type="password"
                                        label={t("password")}
                                        placeholder="***"
                                        errors={errors.password}
                                    ></Input>
                                    <Input
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        type="password"
                                        label={t("confirm_password")}
                                        placeholder="***"
                                        errors={errors.confirmPassword}
                                    ></Input>
                                </div>
                            </FormStep>
                            <FormStep>
                                <ProfilePictureInput
                                    image={profilePicture}
                                    setImage={setProfilePicture}
                                />
                            </FormStep>
                        </FormWithSteps>
                    )}
                </div>
            </div>
        </div>
    )
}
