import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ProfilePictureInterface } from "@/models/User"

interface ProfilePictureProps {
    picture?: ProfilePictureInterface
    className?: string
    alt?: string
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
    picture,
    className,
    alt,
}) => {
    return picture ? (
        <img className={`${className}`} src={picture?.path} alt={alt} />
    ) : (
        <FontAwesomeIcon icon="user" className={`${className}`} />
    )
}
