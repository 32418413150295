import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Popup } from "@/components/Layouts/Popup"
import {
    ContextMenuItem,
    WithContextMenu,
} from "@/components/Menus/WithContextMenu"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { ProjectFile, ProjectFileTypeEnum } from "@/models/File"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { Document, Page } from "react-pdf"
import { Link, useLocation, useParams } from "react-router-dom"
import { FileManagerPermissionForm } from "./Forms/FileManagerPermissionForm"
import { clearErrors, deleteFile, renameFile } from "./fileManagerSlice"

interface FileIconProps {
    file: ProjectFile
    highlighted?: boolean
    innerRef?: React.RefObject<HTMLDivElement> | null
}

export const FileIcon: React.FC<FileIconProps> = ({
    file,
    highlighted = false,
    innerRef = null,
}) => {
    const [showPermissionsModal, setShowPermissionsModal] =
        useState<boolean>(false)
    const userPermissions = useAppSelector(selectUserPermissions)
    const errors = useAppSelector((state) => state.fileManager.errors)
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const location = useLocation()
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [localHighlighted, setLocalHighlighted] = useState<boolean>(false)
    const [actions, setActions] = useState<ContextMenuItem[]>([])
    const [showImage, setShowImage] = useState<boolean>(false)
    const [nameHovered, setNameHovered] = useState<boolean>(false)
    const [newName, setNewName] = useState<string>(file.name)
    const status = useAppSelector((state) => state.fileManager.oneStatus)
    const [cords, setCords] = useState<[number, number]>([0, 0])
    const downloadRef = useRef<HTMLAnchorElement>(null)
    const dispatch = useAppDispatch()
    const [numPages, setNumPages] = useState<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [showPdf, setShowPdf] = useState<boolean>(false)

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages)
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (
            [
                ProjectFileTypeEnum.PHOTO,
                ProjectFileTypeEnum.PROJECT_PICTURE,
            ].includes(file.type)
        ) {
            setShowImage(true)
        } else if (file.type === ProjectFileTypeEnum.PDF) {
            setShowPdf(true)
        } else if (file.type === ProjectFileTypeEnum.IFC) {
            // @FIXME: 3d viewer
            // navigate(
            //     `/dashboard/projects/${projectId}/3d-viewer?fileId=${file.id}`,
            // )
        } else {
            downloadRef.current?.click()
        }
    }
    const sliceName = (name: string) => {
        if (name.length > 40) {
            return name.slice(0, 40) + "..."
        }
        return name
    }

    useEffect(() => {
        const actionsItems: ContextMenuItem[] = []
        if (userPermissions.hasAllPermissions([`file.${file.id}.read`])) {
            // @FIXME: 3d viewer
            // if (file.type === ProjectFileTypeEnum.IFC) {
            //     actionsItems.push({
            //         name: t("preview"),
            //         icon: "cube" as IconProp,
            //         onClick: () => {
            //             navigate(
            //                 `/dashboard/projects/${projectId}/3d-viewer?fileId=${file.id}`,
            //             )
            //         },
            //     })
            // }
            if (file.type === ProjectFileTypeEnum.PDF) {
                actionsItems.push({
                    name: t("preview"),
                    icon: "file-pdf" as IconProp,
                    onClick: () => {
                        setShowPdf(true)
                    },
                })
            }
            if (
                file.type === ProjectFileTypeEnum.PHOTO ||
                file.type === ProjectFileTypeEnum.PROJECT_PICTURE
            ) {
                actionsItems.push({
                    name: t("view"),
                    icon: "eye" as IconProp,
                    onClick: () => {
                        setShowImage(true)
                    },
                })
            }
            actionsItems.push({
                name: t("copy_link"),
                icon: "link" as IconProp,
                onClick: () => {
                    navigator.clipboard.writeText(
                        `${import.meta.env.VITE_FRONTEND_URL}${location.pathname}?resource=${file.id}`,
                    )
                },
            })
            actionsItems.push({
                name: t("download"),
                icon: "download" as IconProp,
                onClick: () => {
                    downloadRef.current?.click()
                },
            })
        }
        if (userPermissions.hasAllPermissions([`file.${file.id}.write`])) {
            actionsItems.push({
                name: t("rename"),
                icon: "edit" as IconProp,
                onClick: () => {
                    setShowRenameModal(true)
                },
            })
            actionsItems.push({
                name: t("delete"),
                icon: "trash" as IconProp,
                onClick: () => {
                    setShowDeleteModal(true)
                },
            })
            actionsItems.push({
                name: t("permissions"),
                icon: "user-group" as IconProp,
                onClick: () => {
                    setShowPermissionsModal(true)
                },
            })
        }
        setActions(actionsItems)
    }, [userPermissions])
    useEffect(() => {
        if (highlighted) {
            setLocalHighlighted(true)
        }
    }, [location, highlighted])
    return (
        <WithContextMenu items={actions}>
            <div
                ref={innerRef}
                className={`w-28 h-36 flex flex-col justify-end items-center cursor-pointer gap-y-1 p-1 rounded-lg ${
                    localHighlighted
                        ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                        : ""
                }
                }`}
                onClick={handleClick}
                onMouseEnter={() => {
                    if (localHighlighted) {
                        setLocalHighlighted(false)
                    }
                }}
            >
                <div className="flex justify-center items-start">
                    {file.contentType.includes("image") ? (
                        <img
                            src={file.path}
                            alt={file.name}
                            className="w-16 object-cover object-center h-16"
                        ></img>
                    ) : (
                        <FontAwesomeIcon
                            icon={
                                file.type === ProjectFileTypeEnum.IFC
                                    ? "cube"
                                    : file.type === ProjectFileTypeEnum.PDF
                                      ? "file-pdf"
                                      : "file"
                            }
                            className="text-6xl text-primary-100 h-16 w-16"
                        />
                    )}
                </div>
                <div
                    className="!overflow-visible h-20"
                    onMouseEnter={(e) => {
                        setCords([e.clientX, e.clientY])
                        setNameHovered(true)
                    }}
                    onMouseLeave={() => {
                        setNameHovered(false)
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <p className="text-center text-sm break-all">
                        {sliceName(file.name)}
                    </p>
                    <Popup
                        isShown={file.name.length > 40 && nameHovered}
                        x={cords[0]}
                        y={cords[1]}
                    >
                        <p className="">{file.name}</p>
                    </Popup>
                    <p className="text-xs text-primary-100 text-center">
                        {file.size} {t("kb")}
                    </p>
                </div>
            </div>
            <NewModal
                isShown={showImage}
                closeModal={() => setShowImage(false)}
            >
                <div>
                    <img
                        src={file.path}
                        alt={file.name}
                        className="m-auto object-cover w-2/4"
                    />
                </div>
            </NewModal>
            <NewModal
                isShown={showPermissionsModal}
                closeModal={() => setShowPermissionsModal(false)}
            >
                <FileManagerPermissionForm
                    resource={file}
                    closeModal={() => setShowPermissionsModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showRenameModal}
                closeModal={() => setShowRenameModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("renaming_file")}
                        statuses={[status.update]}
                        onSubmit={async (e) => {
                            e.preventDefault()
                            const { type } = await dispatch(
                                renameFile({
                                    projectId,
                                    fileId: file.id,
                                    name: newName,
                                }),
                            )
                            if (type === renameFile.fulfilled.type) {
                                setShowRenameModal(false)
                            }
                        }}
                        onCancel={() => setShowRenameModal(false)}
                        submitType={SubmitType.Save}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("renaming_file")} `}
                                <span className="italic font-normal">
                                    {file.name}
                                </span>
                            </h1>
                            <Input
                                type="text"
                                name={t("new_name")}
                                label={t("new_name")}
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                className="!m-0"
                                errors={errors.name}
                            />
                        </div>
                    </Form>
                </div>
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <div className="min-w-[300px] w-full">
                    <Form
                        loadingTitle={t("deleting_file")}
                        statuses={[status.delete]}
                        onSubmit={() => {
                            dispatch(
                                deleteFile({ fileId: file.id, projectId }),
                            ).then(() => {
                                setShowDeleteModal(false)
                            })
                        }}
                        submitType={SubmitType.Delete}
                        onCancel={() => setShowDeleteModal(false)}
                        clearErrors={clearErrors}
                    >
                        <div className="flex flex-col gap-2 min-w-[300px] w-full">
                            <h1 className="text-xl font-bold">
                                {`${t("deleting_file")} `}
                                <span className="italic font-normal">
                                    {file.name}
                                </span>
                            </h1>
                            <p>
                                {t("are_you_sure_you_want_to_delete_this_file")}
                            </p>
                        </div>
                    </Form>
                </div>
            </NewModal>
            <Link
                className="hidden"
                ref={downloadRef}
                to={file.path}
                download={true}
                target="_blank"
                rel="noopener noreferrer"
            ></Link>
            <NewModal isShown={showPdf} closeModal={() => setShowPdf(false)}>
                <div className="flex items-center w-full h-full justify-center overflow-hidden">
                    <Document
                        file={file.path}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className={"w-full h-full overflow-auto"}
                    >
                        <Page pageNumber={pageNumber} />
                        <div className="flex items-center gap-2 w-full justify-center  text-primary-100">
                            <FontAwesomeIcon
                                icon={"chevron-left"}
                                className="cursor-pointer hover:text-primary-300 transition hover:scale-105"
                                onClick={() => {
                                    if (pageNumber > 1) {
                                        setPageNumber(pageNumber - 1)
                                    }
                                }}
                            />
                            <p>
                                {t("pdf_pages_info", {
                                    pageNumber,
                                    numPages,
                                })}
                            </p>
                            <FontAwesomeIcon
                                icon={"chevron-right"}
                                className="cursor-pointer hover:text-primary-300 transition hover:scale-105"
                                onClick={() => {
                                    if (pageNumber < numPages) {
                                        setPageNumber(pageNumber + 1)
                                    }
                                }}
                            />
                        </div>
                    </Document>
                </div>
            </NewModal>
        </WithContextMenu>
    )
}
