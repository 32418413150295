import { CURRENCY } from "@/models/Finance"
import { Currency } from "./Currency"

interface AmountProps {
    amount: number
    currency: CURRENCY
}

export const Amount: React.FC<AmountProps> = ({ amount, currency }) => {
    return (
        <div className="flex items-center gap-1">
            {[CURRENCY.USD, CURRENCY.GBP, CURRENCY.EUR].includes(currency) ? (
                <>
                    <span className="font-bold">
                        <Currency currency={currency} />
                    </span>
                    {amount.toLocaleString()}
                </>
            ) : (
                <>
                    {amount.toLocaleString()}
                    <span className="font-bold">
                        <Currency currency={currency} />
                    </span>
                </>
            )}
        </div>
    )
}
