import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Popup, POPUP_TYPES } from "@/components/Menus/Popup"
import { Form, SubmitType } from "@/features/Form/Form"
import { UserPricingPlan } from "@/models/Pricing"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    clearErrors,
    expireUserPricingPlan,
    extendUserPricingPlan,
} from "./pricingSlice"

interface UserPricingTableActionsProps {
    userPricingPlan: UserPricingPlan
}

export const UserPricingTableActions: React.FC<
    UserPricingTableActionsProps
> = ({ userPricingPlan }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.pricing.status)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [showExtendModal, setShowExtendModal] = useState<boolean>(false)
    const [showExpireModal, setShowExpireModal] = useState<boolean>(false)
    const [numberOfMonths, setNumberOfMonths] = useState<number>(1)
    const ellipsisRef = useRef<HTMLDivElement>(null)

    const menuItems = [
        {
            title: t("extend_plan"),
            icon: "calendar-plus" as IconProp,
            onClick: () => {
                setShowExtendModal(true)
            },
        },
        {
            title: t("expire_plan"),
            icon: "calendar-times" as IconProp,
            onClick: () => {
                setShowExpireModal(true)
            },
        },
    ]

    return (
        <div className="">
            <div
                className="flex w-8 items-center gap-1 justify-center h-8 rounded-full hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer"
                ref={ellipsisRef}
                onClick={() => {
                    setShowMenu(!showMenu)
                }}
            >
                <FontAwesomeIcon icon="ellipsis-v" className="cursor-pointer" />
            </div>
            <Popup
                parentRef={ellipsisRef}
                type={POPUP_TYPES.LEFT_CLICK}
                isShown={showMenu}
                closePopup={() => setShowMenu(false)}
            >
                <ul className="flex flex-col gap-2">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className="flex gap-2 items-center hover:bg-primary-100 hover:text-secondary-100 transition cursor-pointer rounded-lg border-b pl-2 pr-8 py-2"
                            onClick={() => {
                                item.onClick()
                                setShowMenu(false)
                            }}
                        >
                            <FontAwesomeIcon icon={item.icon} />
                            <p className="text-nowrap">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popup>
            <NewModal
                isShown={showExtendModal}
                closeModal={() => setShowExtendModal(false)}
            >
                <Form
                    loadingTitle={t("extending_plan")}
                    onSubmit={async () => {
                        const expiresAt = new Date(userPricingPlan.expiresAt)
                        expiresAt.setMonth(expiresAt.getMonth() + 1)
                        const { type } = await dispatch(
                            extendUserPricingPlan({
                                userPricingPlanId: userPricingPlan.id,
                                expiresAt,
                            }),
                        )
                        if (type === extendUserPricingPlan.fulfilled.type) {
                            setShowExtendModal(false)
                        }
                    }}
                    clearErrors={clearErrors}
                    statuses={[status.update]}
                    submitType={SubmitType.Submit}
                >
                    <h1 className="font-bold text-xl">
                        {t("extending_plan_for_user", {
                            user: userPricingPlan.user.fullName,
                        })}
                    </h1>
                    <Input
                        label={t("number_of_months")}
                        required
                        name={t("number_of_months")}
                        type="number"
                        value={numberOfMonths}
                        onChange={(e) => {
                            const value = parseInt(e.target.value)
                            if (value > 0) setNumberOfMonths(value)
                        }}
                    />
                </Form>
            </NewModal>
            <NewModal
                isShown={showExpireModal}
                closeModal={() => setShowExpireModal(false)}
            >
                <Form
                    loadingTitle={t("expiring_plan")}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            expireUserPricingPlan(userPricingPlan.id),
                        )
                        if (type === expireUserPricingPlan.fulfilled.type) {
                            setShowExpireModal(false)
                        }
                    }}
                    clearErrors={clearErrors}
                    statuses={[status.update]}
                    submitType={SubmitType.Submit}
                >
                    <h1 className="font-bold text-xl">
                        {t("expiring_plan_for_user", {
                            user: userPricingPlan.user.fullName,
                        })}
                    </h1>
                    <p>
                        {t("expiring_plan_for_user_warning", {
                            user: userPricingPlan.user.fullName,
                        })}
                    </p>
                </Form>
            </NewModal>
        </div>
    )
}
