import { useAppDispatch } from "@/app/hooks"
import { NewModal } from "@/components/Layouts/NewModal"
import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FragmentIdMap } from "@thatopen/fragments"
import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AttachToTaskForm } from "./Forms/AttachToTaskForm"
import { SetEntityArticleForm } from "./Forms/SetEntityArticleForm"
import { SetEntityProgressForm } from "./Forms/SetEntityProgressForm"
import { IfcHandler } from "./IfcHandler"
import { getFileSignedUrls, getIfcFileEntities } from "./ifcSlice"
import { IfcToolBar } from "./Tools/IfcToolBar"
import { SelectionDetails } from "./Tools/SelectionDetails"

interface IfcViewerProps {
    selectedFile: ProjectFile | null
    selectedModel: IfcModel | null
}

export const IfcViewer: FC<IfcViewerProps> = ({
    selectedFile,
    selectedModel,
}) => {
    const ifcContainerRef = useRef<HTMLDivElement | null>(null)
    const contextMenuRef = useRef<HTMLDivElement | null>(null)
    const [ifcHandler, setIfcHandler] = useState<IfcHandler | null>(null)
    const [selection, setSelection] = useState<FragmentIdMap | null>(null)
    const [showSelectionDetails, setShowSelectionDetails] = useState(false)

    const { t } = useTranslation()
    const [contextMenu, setContextMenu] = useState<{
        x: number
        y: number
    } | null>(null)
    const [modals, setModals] = useState<{
        attachToTask: boolean
        setProgress: boolean
        setArticle: boolean
    }>({
        attachToTask: false,
        setProgress: false,
        setArticle: false,
    })
    const [contextMenuItems, setContextMenuItems] = useState<
        { icon: IconProp; label: string; onClick: () => void }[]
    >([
        {
            icon: "exclamation-circle",
            label: t("see_details"),
            onClick: () => {
                setShowSelectionDetails(true)
            },
        },
        {
            icon: "link",
            label: t("attach_to_task"),
            onClick: () => {
                setModals({
                    attachToTask: true,
                    setProgress: false,
                    setArticle: false,
                })
            },
        },
        {
            icon: "chart-line",
            label: t("set_progress"),
            onClick: () => {
                setModals({
                    attachToTask: false,
                    setProgress: true,
                    setArticle: false,
                })
            },
        },
        {
            icon: "newspaper",
            label: t("set_article"),
            onClick: () => {
                setModals({
                    attachToTask: false,
                    setProgress: false,
                    setArticle: true,
                })
            },
        },
    ])

    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const dispatch = useAppDispatch()
    useEffect(() => {
        setShowSelectionDetails(false)
    }, [selection])

    useEffect(() => {
        if (ifcContainerRef.current) {
            setIfcHandler(
                new IfcHandler(
                    ifcContainerRef.current,
                    (selection: FragmentIdMap | null) =>
                        setSelection(selection),
                ),
            )
        }
        return () => {
            if (ifcHandler) {
                ifcHandler.dispose()
            }
        }
    }, [ifcContainerRef])

    useEffect(() => {
        if (ifcHandler && selectedFile && selectedModel) {
            dispatch(
                getFileSignedUrls({
                    projectId,
                    ifcModelId: selectedModel.id,
                    fileId: selectedFile.id,
                }),
            ).then((response) => {
                ifcHandler.loadIfcFile(
                    response.payload.data.data.find((url: string) =>
                        url.includes(".ifc"),
                    ),
                )
            })
            dispatch(
                getIfcFileEntities({ projectId, ifcFileId: selectedFile.id }),
            )
        }
    }, [selectedFile])

    useEffect(() => {
        const removeContextMenu = (e: MouseEvent) => {
            if (
                contextMenuRef.current &&
                !contextMenuRef.current.contains(e.target as Node)
            ) {
                setContextMenu(null)
            }
        }
        document.addEventListener("click", removeContextMenu)
        return () => {
            document.removeEventListener("click", removeContextMenu)
        }
    }, [])

    return (
        <div className={`relative flex w-full h-full`}>
            {ifcHandler && (
                <div className="absolute top-3 left-1/2 -translate-x-1/2 bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80">
                    <IfcToolBar ifcHandler={ifcHandler} />
                </div>
            )}

            <div
                ref={ifcContainerRef}
                className="w-full h-full"
                onContextMenu={(e) => {
                    if (selection) {
                        e.preventDefault()
                        setContextMenu({ x: e.clientX, y: e.clientY })
                    }
                }}
            ></div>
            {contextMenu && selection !== null && (
                <div
                    className="fixed rounded-lg bg-secondary-100 shadow-lg z-10 overflow-hidden"
                    style={{
                        top: contextMenu.y,
                        left: contextMenu.x,
                    }}
                    ref={contextMenuRef}
                >
                    <>
                        {contextMenuItems.map((item, index) => (
                            <div
                                className="p-2 border-b text-primary-300 border-secondary-200 cursor-pointer hover:bg-primary-100 hover:text-secondary-100 flex items-center gap-2"
                                key={index}
                                onClick={() => {
                                    item.onClick()
                                    setContextMenu(null)
                                }}
                            >
                                <FontAwesomeIcon icon={item.icon} />
                                <p>{item.label}</p>
                            </div>
                        ))}
                        <div
                            className="p-2 border-b text-primary-300 border-secondary-200 cursor-pointer hover:bg-primary-100 hover:text-secondary-100 flex items-center gap-2"
                            onClick={async () => {
                                if (ifcHandler && selection !== null) {
                                    const expressIdsArray = Object.values(
                                        selection,
                                    )
                                        .map((set) => Array.from(set))
                                        .flat()
                                    await ifcHandler.selectSimilarByType(
                                        expressIdsArray,
                                    )
                                }
                                setContextMenu(null)
                            }}
                        >
                            <FontAwesomeIcon icon="clone" />
                            <p>{t("select_similar_by_type")}</p>
                        </div>
                        <div
                            className="p-2 border-b text-primary-300 border-secondary-200 cursor-pointer hover:bg-primary-100 hover:text-secondary-100 flex items-center gap-2"
                            onClick={async () => {
                                if (ifcHandler && selection !== null) {
                                    const expressIdsArray = Object.values(
                                        selection,
                                    )
                                        .map((set) => Array.from(set))
                                        .flat()
                                    await ifcHandler.selectSimilarByCategory(
                                        expressIdsArray,
                                    )
                                }
                                setContextMenu(null)
                            }}
                        >
                            <FontAwesomeIcon icon="object-group" />
                            <p>{t("select_similar_by_category")}</p>
                        </div>
                    </>
                </div>
            )}
            <NewModal
                isShown={modals.attachToTask && selection !== null}
                closeModal={() => {
                    setModals({
                        attachToTask: false,
                        setProgress: false,
                        setArticle: false,
                    })
                }}
            >
                <AttachToTaskForm
                    ifcHandler={ifcHandler}
                    selection={selection}
                    closeModal={() => {
                        setModals({
                            attachToTask: false,
                            setProgress: false,
                            setArticle: false,
                        })
                    }}
                />
            </NewModal>
            {selectedFile && (
                <>
                    <NewModal
                        isShown={modals.setProgress && selection !== null}
                        closeModal={() => {
                            setModals({
                                attachToTask: false,
                                setProgress: false,
                                setArticle: false,
                            })
                        }}
                    >
                        <SetEntityProgressForm
                            ifcHandler={ifcHandler}
                            selection={selection}
                            ifcFileId={selectedFile.id}
                            closeModal={() => {
                                setModals({
                                    attachToTask: false,
                                    setProgress: false,
                                    setArticle: false,
                                })
                            }}
                        />
                    </NewModal>
                    <NewModal
                        isShown={modals.setArticle && selection !== null}
                        closeModal={() => {
                            setModals({
                                attachToTask: false,
                                setProgress: false,
                                setArticle: false,
                            })
                        }}
                    >
                        <SetEntityArticleForm
                            ifcHandler={ifcHandler}
                            selection={selection}
                            ifcFileId={selectedFile.id}
                            closeModal={() => {
                                setModals({
                                    attachToTask: false,
                                    setProgress: false,
                                    setArticle: false,
                                })
                            }}
                        />
                    </NewModal>
                </>
            )}
            {selection && ifcHandler && showSelectionDetails && (
                <div className="absolute top-1/2 right-3 p-2  -translate-y-1/2 bg-primary-100 text-secondary-100 rounded-lg shadow-lg bg-opacity-80">
                    <SelectionDetails
                        ifcHandler={ifcHandler}
                        selection={selection}
                        close={() => setShowSelectionDetails(false)}
                    />
                </div>
            )}
        </div>
    )
}
