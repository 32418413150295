import { t } from "i18next"
import { BaseModel, BaseModelInterface } from "./BaseModel"
import { FileJsonInterface, ProjectFile, ProjectFileTypeEnum } from "./File"
import { PermissionGroup, PermissionGroupJsonInterface } from "./Permission"

import { TabbedWindowInterface } from "@/components/Layouts/TabbedWindow"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { User, UserInterface } from "./User"

export enum ProjectResourceType {
    ALL = "all",
    FILES = "files",
    PLANNINGS = "plannings",
    GROUPS = "groups",
    FOLDERS = "folders",
}

export enum ProjectModules {
    UserManager = "UserManager",
    FileManager = "FileManager",
    Planning = "Planning",
    ModelViewer = "ModelViewer",
    Tasks = "Tasks",
    Public = "Public",
    Finance = "Finance",
}

export interface ProjectJsonInterface extends BaseModelInterface {
    name: string
    description: string
    address: string
    isFrozen: boolean
    createdBy: UserInterface
    files: FileJsonInterface[]
    pictures: FileJsonInterface[]
    permissionGroups: PermissionGroupJsonInterface[]
    projectUsers?: UserInterface[]
    progress?: number
}

export class Project extends BaseModel {
    public name: string
    public description: string
    public address: string
    public isFrozen: boolean
    public createdBy: User
    public files: ProjectFile[]
    public pictures: ProjectFile[]
    public permissionGroups: PermissionGroup[]
    public projectUsers?: User[]
    public progress?: number

    constructor(public data?: ProjectJsonInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.description = data?.description ?? ""
        this.address = data?.address ?? ""
        this.isFrozen = data?.isFrozen ?? false
        this.createdBy = new User(data?.createdBy) ?? new User()
        this.files = data?.files?.map((file) => new ProjectFile(file)) ?? []
        this.pictures = this.files.filter(
            (file) => file.type === ProjectFileTypeEnum.PROJECT_PICTURE,
        )
        this.permissionGroups =
            data?.permissionGroups?.map(
                (group) => new PermissionGroup(group),
            ) ?? []
        this.projectUsers =
            data?.projectUsers?.map((user) => new User(user)) ?? []
        this.progress = data?.progress ?? 0
    }

    public toJson(): ProjectJsonInterface {
        return {
            ...super.toJson(),
            name: this.name,
            description: this.description,
            address: this.address,
            isFrozen: this.isFrozen,
            createdBy: this.createdBy.toJson(),
            files: this.files.map((file) => file.toJson()),
            pictures: this.pictures.map((file) => file.toJson()),
            permissionGroups: this.permissionGroups.map((group) =>
                group.toJson(),
            ),
            projectUsers: this.projectUsers?.map((user) => user.toJson()) ?? [],
            progress: this.progress,
        }
    }

    get ifcFiles(): ProjectFile[] {
        return this.files.filter(
            (file) => file.type === ProjectFileTypeEnum.IFC,
        )
    }

    public createPayload() {
        return {
            name: this.name,
            description: this.description,
            address: this.address,
        }
    }
}

export class UserProjectModules {
    public modules: ProjectModules[]
    private readonly _tabs = [
        {
            name: "details",
            label: t("details"),
            module: ProjectModules.Public,
            icon: "info-circle",
        },
        {
            name: "file-manager",
            label: t("file_manager"),
            module: ProjectModules.FileManager,
            icon: "folder",
        },
        {
            name: "plannings",
            label: t("planning"),
            module: ProjectModules.Planning,
            icon: "calendar",
        },
        {
            name: "tasks",
            label: t("tasks"),
            module: ProjectModules.Public,
            icon: "list",
        },
        {
            name: "users",
            label: t("users"),
            module: ProjectModules.UserManager,
            icon: "users",
        },
        {
            name: "finance",
            label: t("finance"),
            module: ProjectModules.Finance,
            icon: "dollar-sign",
        },
        {
            name: "3d-viewer",
            label: t("3D Viewer"),
            module: ProjectModules.ModelViewer,
            icon: "cube",
        },
    ]

    constructor(data?: ProjectModules[]) {
        this.modules = data ?? []
    }

    getTabs = (projectId: string): TabbedWindowInterface[] => {
        return this._tabs
            .filter(
                (_tab) =>
                    this.modules.includes(_tab.module) ||
                    _tab.module === ProjectModules.Public,
            )
            .map((tab) => ({
                name: tab.label,
                route: `/dashboard/projects/${projectId}/${tab.name}`,
                icon: tab.icon as IconProp,
            }))
    }

    hasModules = (modules: ProjectModules[]): boolean => {
        return modules.some((module) => this.modules.includes(module))
    }
}
