import ProjectPicturePlaceHolder from "@/assets/project-placeholder.png"
import { Project } from "@/models/Project"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface ProjectCardProps {
    project: Project
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const { t } = useTranslation()
    return (
        <div className="rounded-xl shadow-lg w-full flex hover:cursor-pointer hover:bg-primary-300 hover:text-secondary-100 duration-150 bg-contain h-fit items-center">
            <Link
                to={`/dashboard/projects/${project.id}/details`}
                className="flex sm:flex-col sm:items-center"
            >
                <img
                    className="w-[220px] h-[220px] object-cover rounded-tl-xl rounded-bl-xl sm:rounded-none sm:w-full"
                    src={project.pictures[0]?.path ?? ProjectPicturePlaceHolder}
                    alt=""
                />
                <div className="flex flex-wrap justify-between w-full p-3">
                    <div className="flex flex-col h-full">
                        <h1 className="text-2xl text-bold">{project.name}</h1>
                        {project.description && (
                            <p>
                                <span className="font-bold">
                                    {t("description")}:{" "}
                                </span>
                                {project.description}
                            </p>
                        )}
                        {project.address && (
                            <p>
                                <span className="font-bold">
                                    {t("address")}:{" "}
                                </span>
                                {project.address}
                            </p>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    )
}
