import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { Article, ArticleInterface } from "@/models/Article"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import {
    createArticleApi,
    deleteArticleApi,
    getBudgetArticlesApi,
    getProjectArticlesApi,
    updateArticleApi,
} from "./articlesApi"

export const getProjectArticles = createAsyncThunkWithNotification(
    "article/getProjectArticles",
    async (projectId: string) => {
        const response = await getProjectArticlesApi(projectId)
        return response
    },
)

export const createArticle = createAsyncThunkWithNotification(
    "article/createArticle",
    async ({
        projectId,
        articlePayload,
    }: {
        projectId: string
        articlePayload: FormData
    }) => {
        const response = await createArticleApi(projectId, articlePayload)
        return response
    },
)

export const updateArticle = createAsyncThunkWithNotification(
    "article/updateArticle",
    async ({
        projectId,
        articleId,
        articlePayload,
    }: {
        projectId: string
        articleId: string
        articlePayload: FormData
    }) => {
        const response = await updateArticleApi(
            projectId,
            articleId,
            articlePayload,
        )
        return response
    },
)

export const deleteArticle = createAsyncThunkWithNotification(
    "article/deleteArticle",
    async ({
        projectId,
        articleId,
    }: {
        projectId: string
        articleId: string
    }) => {
        const response = await deleteArticleApi(projectId, articleId)
        return response
    },
)

export const getBudgetArticles = createAsyncThunkWithNotification(
    "article/getBudgetArticles",
    async ({
        projectId,
        budgetId,
    }: {
        projectId: string
        budgetId: string
    }) => {
        const response = await getBudgetArticlesApi(projectId, budgetId)
        return response
    },
)

export interface ArticlesState {
    articles: ArticleInterface[]
    article: ArticleInterface
    status: ReducerStatus
    errors: FormErrors
}

const initialState: ArticlesState = {
    articles: [],
    article: new Article().toJson(),
    status: DEFAULT_REDUCER_STATUS,
    errors: {},
}

export const articleSlice = createSlice({
    name: "article",
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectArticles.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getProjectArticles.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.articles = action.payload.data.data
            })
            .addCase(getProjectArticles.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.articles = []
            })
            .addCase(createArticle.pending, (state) => {
                state.status.create = SliceStatus.LOADING
            })
            .addCase(createArticle.fulfilled, (state, action) => {
                state.status.create = SliceStatus.IDLE
                state.articles = [...state.articles, action.payload.data.data]
            })
            .addCase(createArticle.rejected, (state, action) => {
                state.status.create = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(updateArticle.pending, (state) => {
                state.status.update = SliceStatus.LOADING
            })
            .addCase(updateArticle.fulfilled, (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.article = action.payload.data.data
                state.articles = state.articles.map((article) =>
                    article.id === action.payload.data.data.id
                        ? action.payload.data.data
                        : article,
                )
            })
            .addCase(updateArticle.rejected, (state, action) => {
                state.status.update = SliceStatus.FAILED
                state.errors = (action.payload as any).data
            })
            .addCase(deleteArticle.pending, (state) => {
                state.status.delete = SliceStatus.LOADING
            })
            .addCase(deleteArticle.fulfilled, (state, action) => {
                state.status.delete = SliceStatus.IDLE
                state.articles = state.articles.filter(
                    (article) => article.id !== action.payload.data.data,
                )
            })
            .addCase(deleteArticle.rejected, (state) => {
                state.status.delete = SliceStatus.FAILED
            })
            .addCase(getBudgetArticles.pending, (state) => {
                state.status.read = SliceStatus.LOADING
            })
            .addCase(getBudgetArticles.fulfilled, (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.articles = action.payload.data.data
            })
            .addCase(getBudgetArticles.rejected, (state) => {
                state.status.read = SliceStatus.FAILED
                state.articles = []
            })
    },
})

const selectArticlesRaw = (state: RootState) => state.article.articles

export const selectArticles = createSelector([selectArticlesRaw], (articles) =>
    articles.map((article) => new Article(article)),
)

export const { clearErrors } = articleSlice.actions

export default articleSlice.reducer
