import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { TagComponent } from "@/components/Other/TagComponent"
import { Tag } from "@/models/Tag"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { HexColorInput, HexColorPicker } from "react-colorful"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { createTag } from "./tagSlice"

interface TagsFormProps {
    closeModal: () => void
}

export const TagsForm: React.FC<TagsFormProps> = ({closeModal}) => {
    const { t } = useTranslation()
    const [tagsToCreate, setTagsToCreate] = useState<Tag[]>([])
    const [searchIcon, setSearchIcon] = useState<string>("")

    const [showTagIconSelect, setShowTagIconSelect] = useState<boolean>(false)
    const [showBgColorPicker, setShowBgColorPicker] = useState<boolean>(false)
    const [showIconColorPicker, setShowIconColorPicker] =
        useState<boolean>(false)
    const [localTag, setLocalTag] = useState<Tag>(new Tag())
    const [icons, setIcons] = useState<IconProp[]>([])
    const fontAwesomeIcons = [
        ...new Set(Object.keys(fas).map((key) => fas[key].iconName)),
    ]
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const dispatch = useAppDispatch()
    useEffect(() => {
        setIcons(fontAwesomeIcons.slice(0, 200))
    }, [])
    useEffect(() => {
        if (searchIcon !== "") {
            setIcons(
                fontAwesomeIcons.filter((icon) =>
                    icon.toLowerCase().includes(searchIcon.toLowerCase()),
                ),
            )
        }
    }, [searchIcon])
    const handleSubmit = () => {
        dispatch(createTag({ projectId, payload: localTag.createPayload() })).then(
            () => closeModal()
        )
    }
    return (
        <>
            <div className="min-w-96">
                <div className="w-full flex justify-center items-center mb-4">
                    <TagComponent tag={localTag} />
                </div>
                <div className="flex items-center gap-2">
                    <p className="font-bold">{t("tag_name")}:</p>
                    <Input
                        type="text"
                        name={t("tag_name")}
                        value={localTag.name}
                        onChange={(e) =>
                            setLocalTag(
                                new Tag({
                                    ...localTag.toJson(),
                                    name: e.target.value,
                                }),
                            )
                        }
                        placeholder={t("tag_name")}
                        className="!m-0"
                    />
                </div>
                <div className="relative flex gap-2 items-center">
                    <p className="font-bold">{t("icon")}: </p>
                    {localTag.icon === "" && (
                        <p
                            className="cursor-pointer"
                            onClick={() => {
                                if (!showTagIconSelect) {
                                    setShowBgColorPicker(false)
                                    setShowIconColorPicker(false)
                                }
                                setShowTagIconSelect(!showTagIconSelect)
                            }}
                        >
                            {t("select_icon")}
                        </p>
                    )}
                    {localTag.icon !== "" && (
                        <div
                            className="w-8 h-8 rounded-lg flex justify-center items-center cursor-pointer"
                            onClick={() => {
                                if (!showTagIconSelect) {
                                    setShowBgColorPicker(false)
                                    setShowIconColorPicker(false)
                                }
                                setShowTagIconSelect(!showTagIconSelect)
                            }}
                        >
                            <FontAwesomeIcon
                                icon={localTag.icon as IconProp}
                                className="text-lg"
                            />
                        </div>
                    )}
                    {showTagIconSelect && (
                        <div className="absolute rounded-lg shadow-lg bg-secondary-100 p-4 z-20 top-5">
                            <div className="flex items-center justify-center gap-1">
                                <FontAwesomeIcon icon="search" />
                                <Input
                                    type="text"
                                    name={t("search_icon")}
                                    value={searchIcon}
                                    onChange={(e) =>
                                        setSearchIcon(e.target.value)
                                    }
                                    placeholder={t("search_icon")}
                                    className="!m-0"
                                />
                            </div>
                            <div className="flex flex-wrap w-[200px] max-h-[150px] overflow-y-auto">
                                {icons.map((icon, idx) => (
                                    <div
                                        key={idx}
                                        className={`border w-8 h-8 flex justify-center items-center text-primary-300 transition cursor-pointer hover:text-secondary-100 hover:bg-primary-300 ${
                                            localTag.icon === icon
                                                ? "bg-primary-300 text-secondary-100"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            setLocalTag(
                                                new Tag({
                                                    ...localTag.toJson(),
                                                    icon: icon as string,
                                                }),
                                            )
                                            setShowTagIconSelect(false)
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={icon}
                                            className="text-xl"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="relative flex gap-2 items-center">
                    <p className="font-bold">{t("background_color")}:</p>
                    {localTag.backgroundColor === "" && (
                        <p
                            className="cursor-pointer"
                            onClick={() => {
                                if (!showBgColorPicker) {
                                    setShowTagIconSelect(false)
                                    setShowIconColorPicker(false)
                                }
                                setShowBgColorPicker(!showBgColorPicker)
                            }}
                        >
                            {t("select_background_color")}
                        </p>
                    )}
                    {localTag.backgroundColor !== "" && (
                        <div
                            className="w-8 h-8 rounded-lg cursor-pointer border-2 border-primary-300"
                            onClick={() => {
                                if (!showBgColorPicker) {
                                    setShowTagIconSelect(false)
                                    setShowIconColorPicker(false)
                                }
                                setShowBgColorPicker(!showBgColorPicker)
                            }}
                            style={{
                                backgroundColor: localTag.backgroundColor,
                            }}
                        ></div>
                    )}
                    {showBgColorPicker && (
                        <div className="absolute rounded-lg shadow-lg bg-secondary-100 p-8 z-20 top-8">
                            <FontAwesomeIcon
                                icon="times"
                                className="absolute z-30 top-2 right-2 cursor-pointer bg-secondary-100 hover:bg-primary-100 transition hover:text-secondary-100 w-6 h-6 rounded-full p-1"
                                onClick={() => setShowBgColorPicker(false)}
                            />
                            <HexColorPicker
                                color={localTag.backgroundColor}
                                onChange={(color) =>
                                    setLocalTag(
                                        new Tag({
                                            ...localTag.toJson(),
                                            backgroundColor: color,
                                        }),
                                    )
                                }
                            />
                            <HexColorInput
                                color={localTag.backgroundColor}
                                onChange={(color) =>
                                    setLocalTag(
                                        new Tag({
                                            ...localTag.toJson(),
                                            backgroundColor: color,
                                        }),
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="relative flex gap-2 items-center">
                    <p className="font-bold">{t("icon_color")}:</p>
                    {localTag.color === "" && (
                        <p
                            className="cursor-pointer"
                            onClick={() => {
                                if (!showIconColorPicker) {
                                    setShowTagIconSelect(false)
                                    setShowBgColorPicker(false)
                                }
                                setShowIconColorPicker(!showIconColorPicker)
                            }}
                        >
                            {t("select_icon_color")}
                        </p>
                    )}
                    {localTag.color !== "" && (
                        <div
                            className="w-8 h-8 rounded-lg cursor-pointer border-2 border-primary-300"
                            onClick={() => {
                                if (!showIconColorPicker) {
                                    setShowTagIconSelect(false)
                                    setShowBgColorPicker(false)
                                }
                                setShowIconColorPicker(!showBgColorPicker)
                            }}
                            style={{
                                backgroundColor: localTag.color,
                            }}
                        ></div>
                    )}
                    {showIconColorPicker && (
                        <div className="absolute rounded-lg shadow-lg bg-secondary-100 p-8 z-20 top-8">
                            <FontAwesomeIcon
                                icon="times"
                                className="absolute z-30 top-2 right-2 cursor-pointer bg-secondary-100 hover:bg-primary-100 transition hover:text-secondary-100 w-6 h-6 rounded-full p-1"
                                onClick={() => setShowIconColorPicker(false)}
                            />
                            <HexColorPicker
                                color={localTag.color}
                                onChange={(color) =>
                                    setLocalTag(
                                        new Tag({
                                            ...localTag.toJson(),
                                            color: color,
                                        }),
                                    )
                                }
                            />
                            <HexColorInput
                                color={localTag.color}
                                onChange={(color) =>
                                    setLocalTag(
                                        new Tag({
                                            ...localTag.toJson(),
                                            color: color,
                                        }),
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-end gap-4 mt-6">
                    <Button
                        name={t("cancel")}
                        icon="times"
                        className=""
                        onClick={closeModal}
                    ></Button>
                    <Button
                        name={t('create')}
                        className="!bg-green-400 hover:!bg-green-600 !border-green-400 hover:border-green-600 text-white hover:text-white transition"
                        icon="plus"
                        onClick={handleSubmit}
                    ></Button>
                </div>
            </div>
        </>
    )
}
