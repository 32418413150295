import { HorizontalSmoothScroll } from "@/components/Layouts/HorizontalSmoothScroll"
import { Task } from "@/models/Task"
import { CardsBoard } from "./CardsBoard"

interface CardsProps {
    tasks: Task[]
}

export const Cards: React.FC<CardsProps> = ({ tasks }) => {
    return (
        <div className="w-full h-full">
            <div className="sm:hidden flex overflow-hidden h-full">
                <CardsBoard tasks={tasks} />
            </div>
            <div className="hidden h-full w-full sm:block">
                <HorizontalSmoothScroll>
                    <CardsBoard tasks={tasks} mobile={true} />
                </HorizontalSmoothScroll>
            </div>
        </div>
    )
}
