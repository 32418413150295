import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { LatestProjects } from "../Projects/LatestProjects"
import { LatestUsers } from "../Users/LatestUsers"
import { AdminHeader } from "./Header"
import { AdminStats } from "./Statistics"

interface AdminHomeProps {}

export const AdminHome: React.FC<AdminHomeProps> = () => {
    return (
        <Scrollable className="w-full h-full p-4 flex flex-col gap-4">
            <AdminHeader></AdminHeader>
            <AdminStats></AdminStats>
            <div className="flex gap-2">
                <div className="w-full bg-secondary-100 rounded-lg shadow-lg p-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-xl font-bold">
                            {t("latest_users")}
                        </h1>
                    </div>
                    <div className="w-full h-[350px]">
                        <LatestUsers minimized />
                    </div>
                </div>
                <div className="w-full bg-secondary-100 rounded-lg shadow-lg p-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-xl font-bold">
                            {t("latest_projects")}
                        </h1>
                        <FontAwesomeIcon
                            icon="expand"
                            className="cursor-pointer text-xl"
                        />
                    </div>
                    <div className="h-[350px]">
                        <LatestProjects minimized />
                    </div>
                </div>
            </div>
        </Scrollable>
    )
}
