import AvatarPlaceholder from "@/assets/avatar-placeholder.png"
import { BaseModel, BaseModelInterface } from "./BaseModel"
export interface ProfilePictureInterface {
    name: string
    path: string
    contentType: string
    size: number
}

export interface UserInterface extends BaseModelInterface {
    firstName: string
    lastName: string
    // dateOfBirth: string
    phone: string
    isSuperAdmin: boolean
    lastActive: string
    // address: string
    // gender: UserGender
    email: string
    password?: string
    isVerified: boolean
    profilePicture: ProfilePictureInterface
}

export interface UserUpdatePayload {
    firstName?: string
    lastName?: string
    phone?: string
}

export interface UserRegisterInterface {
    firstName: string
    lastName: string
    dateOfBirth: string
    phone: string
    address: string
    gender: UserGender
    email: string
    password: string
    confirmPassword: string
}

export enum UserGender {
    Male = "male",
    Female = "female",
    Other = "other",
    Unknown = "unknown",
}

export class User extends BaseModel {
    public firstName: string
    public lastName: string
    public phone: string
    public isSuperAdmin: boolean
    public lastActive: Date
    // public address: string
    // public dateOfBirth: Date
    // public gender: UserGender
    public email: string
    public password?: string
    public isVerified: boolean
    public profilePicture: ProfilePictureInterface

    constructor(data?: UserInterface) {
        super(data)
        this.firstName = data?.firstName ?? ""
        this.lastName = data?.lastName ?? ""
        this.phone = data?.phone ?? ""
        this.isSuperAdmin = data?.isSuperAdmin ?? false
        this.lastActive = data?.lastActive
            ? new Date(data.lastActive)
            : new Date()
        this.email = data?.email ?? ""
        this.password = data?.password ?? ""
        this.isVerified = data?.isVerified ?? false
        this.profilePicture = data?.profilePicture ?? {
            name: "",
            path: AvatarPlaceholder,
            contentType: "",
            size: -1,
        }
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    public registerPayload(
        confirmPassword: string,
        profilePicture?: File | null,
    ): FormData {
        const formData = new FormData()
        formData.append("firstName", this.firstName)
        formData.append("lastName", this.lastName)
        formData.append("phone", this.phone)
        formData.append("email", this.email)
        formData.append("password", this.password ?? "")
        formData.append("confirmPassword", confirmPassword)
        if (profilePicture) {
            formData.append("profilePicture", profilePicture)
        }
        return formData
    }

    public updatePayload(): UserUpdatePayload {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
        }
    }

    public toJson(): UserInterface {
        return {
            ...super.toJson(),
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            isSuperAdmin: this.isSuperAdmin,
            lastActive: this.lastActive.toISOString(),
            email: this.email,
            password: this.password,
            isVerified: this.isVerified,
            profilePicture: this.profilePicture,
        }
    }

    public getProfilePictureUrl(): string {
        return this.profilePicture.path
    }
}
