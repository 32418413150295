import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { IfcHandler } from "../IfcHandler"
import { PlansList } from "./PlansList"
import { SpatialStructureTree } from "./SpatialStructureTree"

interface IfcToolBarProps {
    ifcHandler: IfcHandler
}

export const IfcToolBar: React.FC<IfcToolBarProps> = ({ ifcHandler }) => {
    const [clipperEnabled, setClipperEnabled] = useState<boolean>(false)
    const [isExploded, setIsExploded] = useState<boolean>(false)
    const [angleMeasurement, setAngleMeasurement] = useState<boolean>(false)
    const [distanceMeasurement, setDistanceMeasurement] =
        useState<boolean>(false)
    const [areaMeasurement, setAreaMeasurement] = useState<boolean>(false)
    // const [volumeMeasurement, setVolumeMeasurement] = useState<boolean>(false)
    const [sectionPlane, setSectionPlane] = useState<boolean>(false)
    // const [advancedSelection, setAdvancedSelection] = useState<boolean>(false)
    const [showPlans, setShowPlans] = useState<boolean>(false)
    const [firstPerson, setFirstPerson] = useState<boolean>(false)
    const [showSpatialStructure, setShowSpatialStructure] =
        useState<boolean>(false)
    useEffect(() => {
        if (showPlans) setShowSpatialStructure(false)
    }, [showPlans])
    useEffect(() => {
        setShowPlans(sectionPlane)
    }, [sectionPlane])
    return (
        <div className="flex relative">
            <div className="flex gap-1 border-r px-2">
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${clipperEnabled ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="scissors"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setClipping(!clipperEnabled)
                                setClipperEnabled(!clipperEnabled)
                            }
                        }}
                    />
                </div>
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${isExploded ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="layer-group"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setExplode(!isExploded)
                                setIsExploded(!isExploded)
                            }
                        }}
                    />
                </div>
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${sectionPlane ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="solar-panel"
                        onClick={async () => {
                            if (ifcHandler) {
                                await ifcHandler.setPlans(!sectionPlane)
                                setSectionPlane(!sectionPlane)
                                setShowPlans(!showPlans)
                            }
                        }}
                    />
                </div>
            </div>
            <div className="flex gap-1 border-r px-2">
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${angleMeasurement ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="ruler-combined"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setAngleMeasurement(
                                    !angleMeasurement,
                                )
                                setAngleMeasurement(!angleMeasurement)
                            }
                        }}
                    />
                </div>
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${distanceMeasurement ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="ruler"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setDistanceMeasurement(
                                    !distanceMeasurement,
                                )
                                setDistanceMeasurement(!distanceMeasurement)
                            }
                        }}
                    />
                </div>
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${areaMeasurement ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="square"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setFaceMeasurement(!areaMeasurement)
                                setAreaMeasurement(!areaMeasurement)
                            }
                        }}
                    />
                </div>
                {/*
                DISABLED BECAUSE BUGGED
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${volumeMeasurement ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="cube"
                        onClick={() => {
                            if (ifcHandler) {
                                ifcHandler.setVolumeMeasurement(
                                    !volumeMeasurement,
                                )
                                setVolumeMeasurement(!volumeMeasurement)
                            }
                        }}
                    />
                </div> */}
            </div>
            <div className="flex gap-1 border-r px-2">
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${showSpatialStructure ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="folder-tree"
                        onClick={() => {
                            setShowSpatialStructure(!showSpatialStructure)
                            ifcHandler.test([])
                        }}
                    />
                </div>
            </div>
            <div className="flex gap-1 px-2">
                <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg ${firstPerson ? "bg-secondary-100 text-primary-100" : ""} hover:bg-secondary-100 hover:text-primary-100 cursor-pointer transition`}
                >
                    <FontAwesomeIcon
                        icon="person"
                        onClick={() => {
                            ifcHandler.setFirstPerson(!firstPerson)
                            setFirstPerson(!firstPerson)
                        }}
                    />
                </div>
            </div>
            {showPlans && (
                <div className="absolute top-[200%] bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-[50%] -translate-x-1/2">
                    <PlansList ifcHandler={ifcHandler} />
                </div>
            )}
            {showSpatialStructure && (
                <div className="absolute top-[200%] bg-primary-100 px-2 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-[50%] -translate-x-1/2">
                    <SpatialStructureTree ifcHandler={ifcHandler} />
                </div>
            )}
            {/* {advancedSelection && !showPlans && (
                <div className="absolute top-[200%] bg-primary-100 px-4 py-3 text-secondary-100 rounded-lg shadow-lg bg-opacity-80 left-[50%] -translate-x-1/2 w-fit w-max-full">
                    <AdvancedSelectionTool ifcHandler={ifcHandler} />
                </div>
            )} */}
        </div>
    )
}
