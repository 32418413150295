import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { TagsForm } from "@/features/Projects/Tags/TagsForm"
import { getProjectTags, selectTags } from "@/features/Projects/Tags/tagSlice"
import { Tag } from "@/models/Tag"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { NewModal } from "../Layouts/NewModal"
import { TagComponent } from "../Other/TagComponent"
import { Input } from "./Input"

interface TagsInputProps {
    value: Tag[]
    onChange: (tags: Tag[]) => void
    filter?: boolean
}

export const TagsInput: React.FC<TagsInputProps> = ({
    value,
    onChange,
    filter = false,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const tags = useAppSelector(selectTags)
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const [tagInput, setTagInput] = useState<string>("")
    const [showCreateTag, setShowCreateTag] = useState<boolean>(false)
    const [showTagsDropdown, setShowTagsDropdown] = useState<boolean>(false)
    const [tagsSearchResult, setTagsSearchResult] = useState<Tag[]>([])

    const [localValue, setLocalValue] = useState<Tag[]>(value ?? [])

    useEffect(() => {
        dispatch(getProjectTags(projectId))
    }, [])

    useEffect(() => {
        onChange(localValue)
    }, [localValue])

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    useEffect(() => {
        setTagsSearchResult(
            tags
                .filter(
                    (tag) => !localValue.map((tag) => tag.id).includes(tag.id),
                )
                .filter((tag) =>
                    tag.name.toLowerCase().includes(tagInput.toLowerCase()),
                ),
        )
    }, [tagInput, localValue, tags])

    return (
        <div className="min-w-56">
            <div className={`${filter ? "flex items-center gap-2" : ""}`}>
                <div className="flex gap-2 items-center">
                    {localValue.map((tag) => (
                        <div
                            key={tag.id}
                            className="cursor-pointer hover:scale-105 transition"
                            onClick={() => {
                                setLocalValue(
                                    localValue.filter(
                                        (value) => value.id !== tag.id,
                                    ),
                                )
                            }}
                        >
                            <TagComponent tag={tag} key={tag.id} />
                        </div>
                    ))}
                </div>
                <div className="flex gap-2 items-center mt-2 relative">
                    {!filter && <p className="font-bold">{t("add_tags")}:</p>}
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={filter ? "cog" : "plus"}
                            className="text-primary-100 cursor-pointer"
                            onClick={() =>
                                setShowTagsDropdown(!showTagsDropdown)
                            }
                        />
                        {showTagsDropdown && (
                            <div className="absolute w-48 border border-primary-300 border-opacity-25 rounded-lg top-8 bg-secondary-100 p-2 shadow-lg z-30">
                                <Input
                                    type="text"
                                    value={tagInput}
                                    onChange={(e) =>
                                        setTagInput(e.target.value)
                                    }
                                    name={t("tags")}
                                    placeholder={t("search_tags")}
                                    className="!m-0"
                                />
                                <div className="flex flex-wrap gap-2 items-center mt-2">
                                    {tagsSearchResult.map((tag) => (
                                        <div
                                            key={tag.id}
                                            onClick={() =>
                                                setLocalValue(
                                                    [...localValue, tag].filter(
                                                        (value, index, self) =>
                                                            self.indexOf(
                                                                value,
                                                            ) === index,
                                                    ),
                                                )
                                            }
                                            className="cursor-pointer transition hover:scale-105"
                                        >
                                            <TagComponent
                                                tag={tag}
                                                key={tag.id}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {tagsSearchResult.length === 0 && (
                                    <div className="flex items-center flex-col">
                                        <p className="text-xs opacity-65 mb-2">
                                            {t("no_tags_found")}
                                        </p>
                                    </div>
                                )}
                                {!filter && (
                                    <div
                                        className="flex items-center gap-2 justify-center text-primary-100 text-xs w-full cursor-pointer transition mt-2"
                                        onClick={() => setShowCreateTag(true)}
                                    >
                                        <FontAwesomeIcon
                                            icon="plus"
                                            onClick={() =>
                                                setShowCreateTag(true)
                                            }
                                        />
                                        <p>{t("create_new_tag")}</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <NewModal
                isShown={showCreateTag}
                closeModal={() => setShowCreateTag(false)}
            >
                <TagsForm closeModal={() => setShowCreateTag(false)} />
            </NewModal>
        </div>
    )
}
