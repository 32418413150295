import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { selectAuthUser } from "@/features/Authentication/authSlice"
import { selectUserPermissions } from "@/features/User/userSlice"
import { PermissionType } from "@/models/Permission"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { TaskComment as TaskCommentComponent } from "./TaskComment"
import { addComment, getTaskCommentsById, selectTask } from "./tasksSlice"

interface TaskCommentsProps {}

export const TaskComments: React.FC<TaskCommentsProps> = ({}) => {
    const [showCommentInput, setShowCommentInput] = useState<boolean>(false)
    const [comment, setComment] = useState<string>("")
    const [commentImages, setCommentImages] = useState<File[]>([])
    const [queryParams] = useSearchParams()
    const commentInputRef = useRef<HTMLInputElement>(null)
    const [highlighted, setHighlighted] = useState<string>("")
    const [highlightedPath, setHighlightedPath] = useState<string[]>([])
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const containerRef = useRef<HTMLDivElement>(null)
    const highlightedRef = useRef<HTMLDivElement>(null)
    const task = useAppSelector(selectTask)
    const userPermissions = useAppSelector(selectUserPermissions)
    const user = useAppSelector(selectAuthUser)
    const status = useAppSelector((state) => state.tasks.status)
    const location = useLocation()
    const commentPermissionsRequired: PermissionType[] = [
        `task.${task.id}.manager`,
        `task.${task.id}.assignee`,
        `task.${task.id}.reviewer`,
        `task.${task.id}.follower`,
    ]

    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTaskCommentsById({ projectId, taskId }))
    }, [])
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            e.key === "Enter" &&
            (comment.trim() !== "" || commentImages.length > 0)
        ) {
            const payload = new FormData()
            commentImages.forEach((image) => {
                payload.append("images", image)
            })
            payload.append("comment", comment)
            dispatch(addComment({ projectId, taskId, payload })).then(() => {
                setComment("")
                setCommentImages([])
            })
        }
    }
    useEffect(() => {
        const comment = queryParams.get("comment")
        if (comment) {
            setHighlighted(comment)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current, location])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col">
            <div className="flex justify-between items-center gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("comments")}
                </h1>
                {userPermissions.hasOneOfPermissions(
                    commentPermissionsRequired,
                ) && (
                    <Button
                        name={t("add_comment")}
                        className="!py-2"
                        onClick={() => setShowCommentInput(!showCommentInput)}
                    />
                )}
            </div>
            {userPermissions.hasOneOfPermissions(commentPermissionsRequired) &&
                showCommentInput && (
                    <div className="w-full">
                        <WithLoader
                            statuses={[status.update]}
                            title=""
                            className="w-full"
                        >
                            <div className="flex items-center gap-2 p-2 w-full">
                                <img
                                    src={user.profilePicture.path}
                                    alt=""
                                    className="w-8 h-8 rounded-full"
                                />
                                <div className="w-full">
                                    <Input
                                        placeholder={t(
                                            "write_your_comment_here",
                                        )}
                                        name={t("reply")}
                                        value={comment}
                                        onChange={(e) =>
                                            setComment(e.target.value)
                                        }
                                        type="text"
                                        className="w-full !mt-0"
                                        onKeyDown={handleKeyPress}
                                        inputClassName="!border-b border-primary-100 !border-opacity-25 outline-none bg-transparent"
                                    />
                                </div>

                                <div
                                    className="cursor-pointer text-primary-100 hover:text-primary-300 transition scale-110"
                                    onClick={() => {
                                        if (commentInputRef.current !== null) {
                                            commentInputRef.current.click()
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon="image" />
                                    <input
                                        type="file"
                                        ref={commentInputRef}
                                        multiple
                                        className="hidden"
                                        accept="image/*"
                                        onChange={(e) => {
                                            if (e.target.files === null) return
                                            setCommentImages(
                                                Array.from(e.target.files),
                                            )
                                        }}
                                    />
                                </div>
                                <div
                                    className="cursor-pointer text-primary-100 hover:text-primary-300 transition scale-110"
                                    onClick={() => {
                                        if (
                                            comment.trim() !== "" ||
                                            commentImages.length > 0
                                        ) {
                                            const payload = new FormData()
                                            commentImages.forEach((image) => {
                                                payload.append("images", image)
                                            })
                                            payload.append("comment", comment)
                                            dispatch(
                                                addComment({
                                                    projectId,
                                                    taskId,
                                                    payload,
                                                }),
                                            ).then(() => {
                                                setComment("")
                                                setCommentImages([])
                                            })
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon="paper-plane" />
                                </div>
                            </div>
                            <div className="flex gap-2 mt-2">
                                {commentImages.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt=""
                                            className="w-20 h-20 rounded-lg border border-primary-100 border-opacity-25"
                                        />
                                        <div
                                            onClick={() =>
                                                setCommentImages(
                                                    commentImages.filter(
                                                        (img) =>
                                                            img !==
                                                            commentImages[
                                                                index
                                                            ],
                                                    ),
                                                )
                                            }
                                            className="z-20 text-xs absolute top-0 right-0 text-red-400 rounded-full p-1 cursor-pointer flex items-center justify-center"
                                        >
                                            <FontAwesomeIcon icon="times" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </WithLoader>
                    </div>
                )}
            <div className="w-full mt-4 h-full">
                {task.comments.length > 0 ? (
                    <Scrollable height="400px" innerRef={containerRef}>
                        <div className="flex flex-col gap-6">
                            {task.comments
                                .sort((a, b) =>
                                    a.createdAt < b.createdAt ? 1 : -1,
                                )
                                .map((comment) => (
                                    <TaskCommentComponent
                                        canComment={userPermissions.hasOneOfPermissions(
                                            commentPermissionsRequired,
                                        )}
                                        comment={comment}
                                        key={comment.id}
                                        innerRef={highlightedRef}
                                        highlightedId={highlighted}
                                        isShown={true}
                                    />
                                ))}
                        </div>
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="comment-dots"
                                className="text-6xl"
                            />
                            <p>{t("no_comments_yet")}...</p>
                        </h1>
                    </div>
                )}
            </div>
        </div>
    )
}
