import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { DropDown } from "@/components/Inputs/DropDown"
import { HierarchyDropDown } from "@/components/Inputs/HierarchyDropDown"
import { Input } from "@/components/Inputs/Input"
import {
    Task,
    TaskDependenciesRelationType,
    TaskDependenciesType,
    TaskDependency,
} from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
    getAllProjectTasksById,
    getProjectTasksTreesById,
    selectProjectTasks,
    selectProjectTasksTree,
} from "../projectsSlice"
import { selectTask } from "./tasksSlice"

interface TaskDependenciesTableProps {
    edit: boolean
    setDependenciesToAdd: (dependencies: TaskDependency[]) => void
    setDependenciesToRemove: (dependencies: TaskDependency[]) => void
}

export const TaskDependenciesTable: React.FC<TaskDependenciesTableProps> = ({
    edit,
    setDependenciesToAdd,
    setDependenciesToRemove,
}) => {
    const { t } = useTranslation()
    const taskTree = useAppSelector(selectProjectTasksTree)
    const tasks = useAppSelector(selectProjectTasks)
    const task = useAppSelector(selectTask)
    const [localDependenciesToAdd, setLocalDependenciesToAdd] = useState<
        TaskDependency[]
    >([])
    const [localDependenciesToRemove, setLocalDependenciesToRemove] = useState<
        TaskDependency[]
    >([])
    const [selectedDependencyTask, setSelectedDependencyTask] = useState<{
        value: string
        label: string
    }>({
        value: "",
        label: "",
    })
    const [localDependencies, setLocalDependencies] = useState<
        TaskDependency[]
    >(edit ? task.dependencies : [])
    const [localDependency, setLocalDependency] = useState<TaskDependency>(
        new TaskDependency(),
    )

    const [showAddDependency, setShowAddDependency] = useState(false)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? ""

    useEffect(() => {
        dispatch(getAllProjectTasksById(projectId))
        dispatch(getProjectTasksTreesById(projectId))
    }, [])
    useEffect(() => {
        setLocalDependency(
            new TaskDependency({
                ...localDependency.toJson(),
                dependencyTask:
                    tasks
                        .find(
                            (task) => task.id === selectedDependencyTask.value,
                        )
                        ?.toJson() ?? new Task().toJson(),
            }),
        )
    }, [selectedDependencyTask])
    useEffect(() => {
        setDependenciesToAdd(localDependenciesToAdd)
    }, [localDependenciesToAdd])
    useEffect(() => {
        setDependenciesToRemove(localDependenciesToRemove)
    }, [localDependenciesToRemove])
    return (
        <div className="flex flex-col gap-2 w-full">
            {[...localDependencies, ...localDependenciesToAdd].map(
                (dependency, index) => (
                    <div
                        key={dependency.id}
                        className="flex gap-2 items-center"
                    >
                        <FontAwesomeIcon icon="link" />
                        <p>
                            <span className="font-bold">
                                {t(
                                    dependency.dependencyType.toLocaleLowerCase(),
                                )}
                                :
                            </span>
                            {" " +
                                dependency.dependencyTask.name +
                                ` (${dependency.dependencyTask.wbs})`}
                        </p>
                        <p>
                            <span className="font-bold">
                                {t(dependency.relationType.toLocaleLowerCase())}
                            </span>
                        </p>
                        <p>
                            {dependency.lag +
                                " " +
                                t("lag").toLowerCase() +
                                " " +
                                (dependency.lag > 1 ? t("days") : t("day"))}
                        </p>
                        <div className="w-1/12 flex items-center justify-end gap-2">
                            <FontAwesomeIcon
                                icon="trash"
                                className="text-red-400 hover:text-red-600 transition cursor-pointer font-bold"
                                onClick={() => {
                                    setLocalDependenciesToRemove([
                                        ...localDependenciesToRemove,
                                        dependency,
                                    ])
                                    setDependenciesToAdd(
                                        localDependenciesToAdd.filter(
                                            (dep) => dep !== dependency,
                                        ),
                                    )
                                    setLocalDependencies(
                                        localDependencies.filter(
                                            (dep) => dep !== dependency,
                                        ),
                                    )
                                }}
                            />
                        </div>
                    </div>
                ),
            )}
            <div>
                {!showAddDependency && (
                    <div
                        className="flex justify-center items-center gap-2 cursor-pointer transition text-green-400 hover:text-green-600 border-y py-2 border-y-green-400 hover:border-y-green-600"
                        onClick={() => setShowAddDependency(true)}
                    >
                        <FontAwesomeIcon icon="plus" />
                        <p>{t("add_dependency")}</p>
                    </div>
                )}
                {showAddDependency && (
                    <div className="flex w-full border-y py-2">
                        <div className="w-1/3 flex items-center">
                            <HierarchyDropDown
                                className="w-full"
                                value={{
                                    value: localDependency.dependencyTask.id,
                                    label: `${localDependency.dependencyTask.wbs} - ${localDependency.dependencyTask.name}`,
                                }}
                                placeholder={t("select_dependency_task")}
                                onChange={(value) => {
                                    setSelectedDependencyTask(value)
                                }}
                                options={taskTree.map((task) => ({
                                    value: task.id,
                                    label: `${task.name}`,
                                    expanded: false,
                                    item: task,
                                }))}
                                name={t("parent_task")}
                            ></HierarchyDropDown>
                        </div>
                        <div className="w-1/6 flex items-center">
                            <DropDown
                                name={t("dependency_type")}
                                onChange={(e) =>
                                    setLocalDependency(
                                        new TaskDependency({
                                            ...localDependency.toJson(),
                                            dependencyType: e.target
                                                .value as TaskDependenciesType,
                                        }),
                                    )
                                }
                                options={Object.keys(TaskDependenciesType)
                                    .filter(
                                        (type) =>
                                            type !== TaskDependenciesType.ERROR,
                                    )
                                    .map((type) => {
                                        return {
                                            label: t(type.toLowerCase()),
                                            value: type,
                                        }
                                    })}
                                value={localDependency.dependencyType}
                            />
                        </div>
                        <div className="w-1/6 flex items-center">
                            <DropDown
                                name={t("relation_type")}
                                onChange={(e) => {
                                    setLocalDependency(
                                        new TaskDependency({
                                            ...localDependency.toJson(),
                                            relationType: e.target
                                                .value as TaskDependenciesRelationType,
                                        }),
                                    )
                                }}
                                options={Object.keys(
                                    TaskDependenciesRelationType,
                                )
                                    .filter(
                                        (type) =>
                                            type !==
                                            TaskDependenciesRelationType.ERROR,
                                    )
                                    .map((type) => {
                                        return {
                                            label: t(type.toLowerCase()),
                                            value: type,
                                        }
                                    })}
                                value={localDependency.relationType}
                            />
                        </div>
                        <div className="w-1/6 flex items-center">
                            <Input
                                name={t("lag")}
                                className="flex items-center gap-2 !m-0"
                                type="number"
                                value={localDependency.lag}
                                onChange={(e) =>
                                    setLocalDependency(
                                        new TaskDependency({
                                            ...localDependency.toJson(),
                                            lag: parseInt(e.target.value),
                                        }),
                                    )
                                }
                            />
                            <p>
                                {localDependency.lag > 1 ? t("days") : t("day")}
                            </p>
                        </div>
                        <div className="w-1/12 flex items-center justify-end">
                            <FontAwesomeIcon
                                icon="plus"
                                className="text-green-400 hover:text-green-600 transition cursor-pointer font-bold text-xl"
                                onClick={() => {
                                    setLocalDependenciesToAdd([
                                        ...localDependenciesToAdd,
                                        localDependency,
                                    ])
                                    setLocalDependency(new TaskDependency())
                                    setSelectedDependencyTask({
                                        value: "",
                                        label: "",
                                    })
                                    setShowAddDependency(false)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
