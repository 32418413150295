import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { selectAuthUser } from "@/features/Authentication/authSlice"
import { TaskComment as TaskCommentModel, TaskCommentType } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { addComment, selectReplies } from "./tasksSlice"

interface TaskCommentProps {
    comment: TaskCommentModel
    canComment: boolean
    isShown: boolean
    innerRef?: React.Ref<HTMLDivElement> | null
    highlightedId?: string
}

export const TaskComment: React.FC<TaskCommentProps> = ({
    comment,
    canComment,
    isShown,
    innerRef = null,
    highlightedId = "",
}) => {
    const { t } = useTranslation()
    const [showReplies, setShowReplies] = useState<boolean>(false)
    const [showReplyInput, setShowReplyInput] = useState<boolean>(false)
    const [reply, setReply] = useState<string>("")
    const [enlargeImage, setEnlargeImage] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<string>("")
    const [commentImages, setCommentImages] = useState<File[]>([])
    const commentInputRef = useRef<HTMLInputElement>(null)
    const [localHighlighted, setLocalHighlighted] = useState<boolean>(false)
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"

    const dispatch = useAppDispatch()
    const user = useAppSelector(selectAuthUser)
    const replies = useAppSelector(selectReplies(comment.id))
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const toggleReplies = () => {
        setShowReplies(!showReplies)
    }
    const location = useLocation()
    const createComment = () => {
        if (reply.trim() !== "" || commentImages.length > 0) {
            const payload = new FormData()
            commentImages.forEach((image) => {
                payload.append("images", image)
            })
            payload.append("comment", reply)
            dispatch(
                addComment({
                    projectId,
                    taskId,
                    payload,
                    commentId: comment.id,
                }),
            ).then(() => {
                setReply("")
                setCommentImages([])
            })
        }
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (canComment && e.key === "Enter") {
            createComment()
        }
    }
    const checkIdInNestedReplies = (replies: TaskCommentModel[]): boolean => {
        let found = false
        replies.forEach((reply) => {
            if (reply.id === highlightedId) {
                found = true
            } else {
                if (reply.replies.length > 0) {
                    found = checkIdInNestedReplies(reply.replies)
                }
            }
        })
        return found
    }
    useEffect(() => {
        setShowReplies(checkIdInNestedReplies(replies))
    }, [comment, location, highlightedId])

    useEffect(() => {
        setLocalHighlighted(highlightedId === comment.id)
    }, [location, highlightedId])

    return (
        <div
            className={`bg-secondary-100 p-4 border-b border-l border-primary-100 border-opacity-25 rounded-bl-lg ${
                !isShown ? "hidden" : ""
            } ${
                localHighlighted
                    ? "animated-background bg-gradient-to-r from-yellow-300 via-yellow-100 to-yellow-300"
                    : ""
            } ${comment.type === TaskCommentType.REVIEW_APPROVED && "!bg-green-200"} ${comment.type === TaskCommentType.REVIEW_REJECTED && "!bg-red-200"}`}
            onMouseEnter={() => {
                if (localHighlighted) {
                    setLocalHighlighted(false)
                }
            }}
            ref={localHighlighted ? innerRef : null}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <img
                        src={comment.user.profilePicture.path}
                        alt=""
                        className="w-8 h-8 rounded-full"
                    />
                    <h4 className="text-lg font-bold">
                        {comment.user.fullName}
                    </h4>
                </div>
                <p className="text-sm">
                    {t("intlDateTime", {
                        val: comment.createdAt,
                        formatParams: {
                            val: {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            },
                        },
                    })}
                </p>
            </div>
            <div>
                <p className="mt-2">{comment.comment}</p>
                <div className="flex flex-wrap gap-2">
                    {comment.images.map((image) => (
                        <img
                            key={image.id}
                            src={image.path}
                            alt=""
                            className="w-32 h-32 mt-2 rounded-md cursor-pointer hover:scale-105 transition transform duration-200 ease-in-out"
                            onClick={() => {
                                setEnlargeImage(true)
                                setSelectedImage(image.path)
                            }}
                        />
                    ))}
                    <NewModal
                        isShown={enlargeImage}
                        closeModal={() => {
                            setEnlargeImage(false)
                            setSelectedImage("")
                        }}
                        className="overflow-y-hidden"
                    >
                        <Scrollable height="100%">
                            <img
                                src={selectedImage}
                                alt=""
                                className="w-full h-full max-h-screen object-contain"
                            />
                        </Scrollable>
                    </NewModal>
                </div>
            </div>
            <div className="flex flex-col gap-3 mt-2">
                {replies.map((reply: TaskCommentModel) => (
                    <TaskComment
                        key={reply.id}
                        comment={reply}
                        canComment={canComment}
                        highlightedId={highlightedId}
                        innerRef={innerRef}
                        isShown={showReplies}
                    />
                ))}
            </div>
            <div className="flex justify-between my-3">
                {canComment && (
                    <div
                        className="w-fit text-primary-100 border-b border-b-primary-100 cursor-pointer flex items-center gap-2"
                        onClick={() => setShowReplyInput(!showReplyInput)}
                    >
                        <FontAwesomeIcon icon="comment" />
                        <p>{t("reply")}</p>
                    </div>
                )}
                {replies.length > 0 && (
                    <div
                        className="w-fit text-primary-100 border-b border-b-primary-100 cursor-pointer flex items-center gap-2"
                        onClick={toggleReplies}
                    >
                        <p>{`${replies.length} ${
                            replies.length === 1
                                ? t("reply").toLowerCase()
                                : t("replies").toLowerCase()
                        }`}</p>
                        <FontAwesomeIcon
                            icon={showReplies ? "chevron-up" : "chevron-down"}
                        />
                    </div>
                )}
            </div>
            {canComment && showReplyInput && (
                <div>
                    <div className="flex items-center gap-2 p-2">
                        <img
                            src={user.profilePicture.path}
                            alt=""
                            className="w-8 h-8 rounded-full"
                        />
                        <div className="w-full">
                            <Input
                                placeholder={t("write_your_comment_here")}
                                name={t("reply")}
                                value={reply}
                                onChange={(e) => setReply(e.target.value)}
                                type="text"
                                className="w-full !mt-0"
                                onKeyDown={handleKeyDown}
                                inputClassName="!border-b border-primary-100 !border-opacity-25 outline-none bg-transparent"
                            />
                        </div>
                        <div
                            className="cursor-pointer text-primary-100 hover:text-primary-300 transition scale-110"
                            onClick={() => {
                                if (commentInputRef.current !== null) {
                                    commentInputRef.current.click()
                                }
                            }}
                        >
                            <FontAwesomeIcon icon="image" />
                            <input
                                type="file"
                                ref={commentInputRef}
                                multiple
                                className="hidden"
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target.files === null) return
                                    setCommentImages(Array.from(e.target.files))
                                }}
                            />
                        </div>
                        <div
                            className="cursor-pointer text-primary-100 hover:text-primary-300 transition scale-110"
                            onClick={() => {
                                if (
                                    reply.trim() !== "" ||
                                    commentImages.length > 0
                                ) {
                                    createComment()
                                }
                            }}
                        >
                            <FontAwesomeIcon icon="paper-plane" />
                        </div>
                    </div>
                    <div className="flex gap-2 mt-2">
                        {commentImages.map((image, index) => (
                            <div key={index} className="relative">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                    className="w-20 h-20 rounded-lg border border-primary-100 border-opacity-25"
                                />
                                <div
                                    onClick={() =>
                                        setCommentImages(
                                            commentImages.filter(
                                                (img) =>
                                                    img !==
                                                    commentImages[index],
                                            ),
                                        )
                                    }
                                    className="z-20 text-xs absolute top-0 right-0 text-red-400 rounded-full p-1 cursor-pointer flex items-center justify-center"
                                >
                                    <FontAwesomeIcon icon="times" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
