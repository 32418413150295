import { useAppDispatch } from "@/app/hooks"
import { DropDown } from "@/components/Inputs/DropDown"
import { Input } from "@/components/Inputs/Input"
import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import { useTranslation } from "react-i18next"
import { FilterFields, FilterOperators, FilterValuesType } from "./Filters"

interface NumberFilterProps {
    filters: FilterValuesType<FilterFields>
    field: FilterFields
    setFilters: ActionCreatorWithPayload<FilterValuesType<FilterFields>, any>
}

export const NumberFilter: React.FC<NumberFilterProps> = ({
    filters,
    field,
    setFilters,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    return (
        <div className="w-full h-full ">
            <div className="flex items-center flex-wrap gap-2">
                <p>{t("operator")}:</p>
                <DropDown
                    value={filters[field].operator}
                    name={t("operator")}
                    options={Object.values(FilterOperators.NumberOperators).map(
                        (operator) => ({
                            value: operator,
                            label: t(operator),
                        }),
                    )}
                    onChange={(e) => {
                        dispatch(
                            setFilters({
                                ...filters,
                                [field]: {
                                    ...filters[field],
                                    operator: e.target.value,
                                },
                            }),
                        )
                    }}
                />
            </div>
            <div className="flex items-center flex-wrap gap-2">
                <p>{t("value")}:</p>
                <Input
                    name={t(field)}
                    type="number"
                    placeholder={t("search")}
                    className="!m-0"
                    value={filters[field].value as number}
                    onChange={(e) => {
                        dispatch(
                            setFilters({
                                ...filters,
                                [field]: {
                                    ...filters[field],
                                    value: e.target.valueAsNumber,
                                },
                            }),
                        )
                    }}
                />
            </div>
        </div>
    )
}
