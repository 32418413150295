import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Currency } from "@/components/Other/Currency"
import { PricingPlan } from "@/models/Pricing"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AddUserToPricingPlan } from "./Forms/AddUserToPricingPlan"
import { DeletePricingPlanForm } from "./Forms/DeletePricingPlanForm"
import { PricingPlanForm } from "./Forms/PricingPlanForm"
import {
    getPricingPlans,
    getUsersPricingPlan,
    selectPricingPlans,
    selectUserPricingPlans,
} from "./pricingSlice"
import { UsersPricingPlanTable } from "./UsersPricingPlanTable"

interface PricingProps {}

export const Pricing: React.FC<PricingProps> = () => {
    const dispatch = useAppDispatch()
    const pricingPlans = useSelector(selectPricingPlans)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [selectedPlanId, setSelectedPlanId] = useState<string>("")
    const userPricingPlans = useSelector(selectUserPricingPlans)
    const plansColors = (plan: PricingPlan) => [
        `bg-primary-100`,
        "bg-secondary-100 !text-primary-300",
        "bg-highlight-100",
    ]
    useEffect(() => {
        dispatch(getPricingPlans({}))
    }, [])
    useEffect(() => {
        const payload = selectedPlanId ? selectedPlanId : ""
        dispatch(getUsersPricingPlan(payload))
    }, [selectedPlanId])
    useEffect(() => {
        if (pricingPlans.length) {
            setSelectedPlanId(pricingPlans[0].id)
        }
    }, [pricingPlans])
    return (
        <div className="w-full h-full p-4 flex flex-col gap-4">
            <div className="w-full h-20 bg-secondary-100 rounded-lg shadow-lg flex items-center p-2">
                <Button
                    name={t("create_pricing_plan")}
                    onClick={() => setShowCreateModal(true)}
                    icon={"plus"}
                ></Button>
            </div>
            <Scrollable className="flex flex-col gap-6 h-full">
                <div className="flex gap-8 p-6">
                    {pricingPlans.map((plan, index) => (
                        <div
                            key={plan.id}
                            className={`${plansColors(plan)[index % plansColors(plan).length]} opacity-70 rounded-lg w-[350px] shadow-lg hover:scale-105 cursor-pointer p-4 text-secondary-100 flex  flex-col gap-4 transition ${
                                selectedPlanId === plan.id
                                    ? "scale-105 !opacity-100"
                                    : ""
                            }`}
                            onClick={() => setSelectedPlanId(plan.id)}
                        >
                            <div className="flex justify-between items-center">
                                <h1 className="font-bold text-2xl border-b-4 w-fit">
                                    {plan.name}
                                </h1>
                                <div className="flex gap-2 items-center">
                                    <FontAwesomeIcon
                                        icon="plus"
                                        className="text-lg cursor-pointer hover:text-green-400 transition scale-105"
                                        onClick={() => {
                                            setSelectedPlanId(plan.id)
                                            setShowAddUserModal(true)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon="edit"
                                        className="text-lg cursor-pointer hover:text-yellow-400 transition scale-105"
                                        onClick={() => {
                                            setSelectedPlanId(plan.id)
                                            setShowEditModal(true)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon="trash"
                                        className="text-lg cursor-pointer hover:text-red-600 transition scale-105"
                                        onClick={() => {
                                            setSelectedPlanId(plan.id)
                                            setShowDeleteModal(true)
                                        }}
                                    />
                                </div>
                            </div>
                            <ul className="pl-4">
                                {plan.features.map((feature, idx) => (
                                    <li
                                        className="flex items-start justify-start gap-2"
                                        key={idx}
                                    >
                                        <FontAwesomeIcon
                                            icon="check"
                                            className="mt-1"
                                        />
                                        <p className="">{t(feature)}</p>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex gap-2 flex-wrap">
                                <p className="text-sm">
                                    <span className="font-bold">
                                        {t("number_of_users")}:
                                    </span>{" "}
                                    {plan.numberOfUsers}
                                </p>
                                <p className="text-sm">
                                    <span className="font-bold">
                                        {t("number_of_projects")}:
                                    </span>{" "}
                                    {plan.numberOfProjects}
                                </p>
                                <p className="text-sm">
                                    <span className="font-bold">
                                        {t("number_of_emails")}:
                                    </span>{" "}
                                    {plan.numberOfEmails}
                                </p>
                                <p>
                                    <span className="font-bold">
                                        {t("storage")}:
                                    </span>{" "}
                                    {plan.storage}GB
                                </p>
                            </div>

                            <h2 className="text-4xl ml-auto flex items-center gap-1">
                                {plan.price}
                                <Currency currency={plan.currency} />
                                <span className="text-sm self-end">
                                    {" "}
                                    / {t("month")}
                                </span>
                            </h2>
                        </div>
                    ))}
                </div>
                <div className="w-full bg-secondary-100 rounded-lg shadow-lg p-4 flex flex-col gap-2">
                    <h1 className="text-xl font-bold mb-4">
                        {
                            pricingPlans.find(
                                (plan) => plan.id === selectedPlanId,
                            )?.name
                        }{" "}
                        {t("users")}:
                    </h1>
                    <UsersPricingPlanTable
                        usersPricingPlan={userPricingPlans}
                    />
                </div>
                {/* <SubscribedStatsUsers /> */}
            </Scrollable>
            <NewModal
                isShown={showCreateModal}
                closeModal={() => setShowCreateModal(false)}
            >
                <PricingPlanForm onCancel={() => setShowCreateModal(false)} />
            </NewModal>
            <NewModal
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <PricingPlanForm
                    pricingPlan={pricingPlans.find(
                        (plan) => plan.id === selectedPlanId,
                    )}
                    onCancel={() => setShowEditModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showAddUserModal}
                closeModal={() => setShowAddUserModal(false)}
            >
                <AddUserToPricingPlan
                    pricingPlan={
                        pricingPlans.find((plan) => plan.id === selectedPlanId)!
                    }
                    onCancel={() => setShowAddUserModal(false)}
                />
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <DeletePricingPlanForm
                    pricingPlan={
                        pricingPlans.find((plan) => plan.id === selectedPlanId)!
                    }
                    onCancel={() => setShowDeleteModal(false)}
                />
            </NewModal>
        </div>
    )
}
