import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import {
    TaskDelayStatus,
    TaskStatus,
    TaskStatusDetail,
    TaskStatusDetailMap,
} from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ArrayFilter } from "../Plannings/Gantt/Filters/ArrayFilter"
import { DateFilter } from "../Plannings/Gantt/Filters/DateFilter"
import {
    CommonFilterFields,
    FilterFields,
    FilterValuesType,
    TaskFilterFields,
} from "../Plannings/Gantt/Filters/Filters"
import { NumberFilter } from "../Plannings/Gantt/Filters/NumberFilter"
import { StringFilter } from "../Plannings/Gantt/Filters/StringFilter"
import { resetFilters, setFilters } from "../Plannings/Gantt/ganttSlice"
import {
    setDelayStatusFilterValues,
    setStatusDetailFilterValues,
    setStatusFilterValues,
} from "./tasksSlice"

interface TaskFiltersProps {}

const FilterFieldMapping = {
    [TaskFilterFields.WBS]: StringFilter,
    [CommonFilterFields.Name]: StringFilter,
    [TaskFilterFields.StartDate]: DateFilter,
    [TaskFilterFields.EndDate]: DateFilter,
    [TaskFilterFields.TaskStatus]: ArrayFilter,
    [TaskFilterFields.Duration]: NumberFilter,
    [TaskFilterFields.Progress]: NumberFilter,
    [TaskFilterFields.Tags]: ArrayFilter,
    [TaskFilterFields.Assignees]: ArrayFilter,
    [TaskFilterFields.Reviewers]: ArrayFilter,
    [TaskFilterFields.DelayStatus]: ArrayFilter,
    [TaskFilterFields.StatusDetail]: ArrayFilter,
    [TaskFilterFields.StartDelay]: NumberFilter,
    [TaskFilterFields.EndDelay]: NumberFilter,
}

export const TaskFilters: React.FC<TaskFiltersProps> = () => {
    const [localStatusFilterValues, setLocalStatusFilterValues] = useState<
        TaskStatus[]
    >([])
    const [localStatusDetailFilterValues, setLocalStatusDetailFilterValues] =
        useState<TaskStatusDetail[]>([])
    const [localDelayStatusFilterValues, setLocalDelayStatusFilterValues] =
        useState<TaskDelayStatus[]>([])
    const [showAdvancedFiltersModal, setShowAdvancedFiltersModal] =
        useState<boolean>(false)
    const filters = useAppSelector((state) => state.gantt.filters)

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(setStatusFilterValues(localStatusFilterValues))
    }, [localStatusFilterValues])

    useEffect(() => {
        dispatch(setStatusDetailFilterValues(localStatusDetailFilterValues))
    }, [localStatusDetailFilterValues])

    useEffect(() => {
        dispatch(setDelayStatusFilterValues(localDelayStatusFilterValues))
    }, [localDelayStatusFilterValues])
    return (
        <div className="w-[250px] bg-secondary-100 rounded-lg shadow-lg p-4 flex flex-col gap-4">
            <div>
                <h1 className="font-bold text-lg">{t("filter_by_status")}</h1>
                <div className="flex flex-col gap-4">
                    {Object.values(TaskStatus).map((status, idx) => (
                        <div key={idx}>
                            <div
                                className="flex gap-2 cursor-pointer"
                                onClick={() => {
                                    setLocalStatusFilterValues(
                                        localStatusFilterValues.includes(status)
                                            ? localStatusFilterValues.filter(
                                                  (s) => s !== status,
                                              )
                                            : [
                                                  ...localStatusFilterValues,
                                                  status,
                                              ],
                                    )
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        localStatusFilterValues.includes(status)
                                            ? "check-square"
                                            : "square"
                                    }
                                    className="mt-1"
                                />
                                <p>{t(status)}</p>
                            </div>
                            <div>
                                {Object.values(TaskStatusDetailMap[status]).map(
                                    (statusDetail, idx) => (
                                        <div
                                            key={idx}
                                            className="flex gap-2 cursor-pointer"
                                            onClick={() => {
                                                if (
                                                    !localStatusFilterValues.includes(
                                                        status,
                                                    ) &&
                                                    !localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                ) {
                                                    setLocalStatusFilterValues([
                                                        ...localStatusFilterValues,
                                                        status,
                                                    ])
                                                }
                                                setLocalStatusDetailFilterValues(
                                                    localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                        ? localStatusDetailFilterValues.filter(
                                                              (s) =>
                                                                  s !==
                                                                  statusDetail,
                                                          )
                                                        : [
                                                              ...localStatusDetailFilterValues,
                                                              statusDetail,
                                                          ],
                                                )
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    localStatusDetailFilterValues.includes(
                                                        statusDetail,
                                                    )
                                                        ? "check-square"
                                                        : "square"
                                                }
                                                className="mt-1"
                                            />
                                            <p>{t(statusDetail)}</p>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h1 className="font-bold text-lg">{t("filter_by_delay")}</h1>
                <div className="flex flex-col">
                    {Object.values(TaskDelayStatus).map((status, idx) => (
                        <div
                            key={idx}
                            className="flex gap-2 cursor-pointer"
                            onClick={() => {
                                setLocalDelayStatusFilterValues(
                                    localDelayStatusFilterValues.includes(
                                        status,
                                    )
                                        ? localDelayStatusFilterValues.filter(
                                              (s) => s !== status,
                                          )
                                        : [
                                              ...localDelayStatusFilterValues,
                                              status,
                                          ],
                                )
                            }}
                        >
                            <FontAwesomeIcon
                                icon={
                                    localDelayStatusFilterValues.includes(
                                        status,
                                    )
                                        ? "check-square"
                                        : "square"
                                }
                                className="mt-1"
                            />
                            <p>{t(status)}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex items-center justify-center">
                <Button
                    icon={"filter"}
                    name={t("advanced_filters")}
                    onClick={() => setShowAdvancedFiltersModal(true)}
                ></Button>
            </div>
            <NewModal
                isShown={showAdvancedFiltersModal}
                closeModal={() => setShowAdvancedFiltersModal(false)}
            >
                <div>
                    <div className="flex flex-wrap justify-between">
                        {Object.values(TaskFilterFields)
                            .filter(
                                (field) =>
                                    ![
                                        TaskFilterFields.TaskStatus,
                                        TaskFilterFields.DelayStatus,
                                        TaskFilterFields.StatusDetail,
                                    ].includes(field),
                            )
                            .map((field, idx) => {
                                const FilterComponent =
                                    FilterFieldMapping[field]
                                return (
                                    <div
                                        className="w-1/2 p-8 border-b"
                                        key={idx}
                                    >
                                        <div className="flex gap-2 items-center">
                                            <p className="font-bold">
                                                {t(field)}:
                                            </p>
                                            {filters[field].value && (
                                                <FontAwesomeIcon
                                                    icon="filter-circle-xmark"
                                                    className="ml-1 text-red-400 hover:text-red-600 transition cursor-pointer"
                                                    onClick={() => {
                                                        dispatch(
                                                            setFilters({
                                                                ...filters,
                                                                [field]: {
                                                                    ...filters[
                                                                        field
                                                                    ],
                                                                    value: "",
                                                                },
                                                            }),
                                                        )
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <FilterComponent
                                            filters={
                                                filters as FilterValuesType<FilterFields>
                                            }
                                            field={field}
                                            setFilters={setFilters}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div className="flex gap-2 justify-end w-full p-2">
                    <Button
                        name={t("cancel")}
                        icon="times"
                        className="!bg-transparent-0 !text-primary-100 !border !border-primary-100 hover:!bg-primary-100 hover:!text-secondary-100 hover:!border-primary-100 transition"
                        onClick={() => setShowAdvancedFiltersModal(false)}
                        type="button"
                    ></Button>
                    <Button
                        name={t("clear_all")}
                        className="!bg-red-400 hover:!bg-red-600 !border-red-400 hover:border-red-600 text-white hover:text-white transition"
                        onClick={() => {
                            dispatch(resetFilters({}))
                        }}
                        icon={"filter-circle-xmark"}
                    ></Button>
                    <Button
                        name={t("apply")}
                        onClick={() => {}}
                        className="!bg-green-400 hover:!bg-green-600 !border-green-400 hover:border-green-600 text-white hover:text-white transition"
                        icon="filter"
                    />
                </div>
            </NewModal>
        </div>
    )
}
