import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Budget } from "@/models/Finance"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
    getBudgets,
    getBudgetsTrees,
    selectBudgetsTrees,
} from "../financeSlice"
import { BudgetGeneralInfo } from "./BudgetGeneralInfo"
import { BudgetsActions } from "./BudgetsActions"
import { BudgetForm } from "./Forms/BudgetForm"

interface BudgetsProps {}

export const Budgets: FC<BudgetsProps> = () => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId
    const navigate = useNavigate()
    const [showBudgetCreateModal, setShowBudgetCreateModal] =
        useState<boolean>(false)
    const budgetsTrees = useAppSelector(selectBudgetsTrees)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const status = useAppSelector((state) => state.finance.status)
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getBudgetsTrees(projectId))
        dispatch(getBudgets(projectId))
    }, [])

    const columns = useMemo<MRT_ColumnDef<Budget>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("name"),
                enableGrouping: true,
            },
            {
                accessorKey: "description",
                header: t("description"),
            },
            {
                accessorKey: "total",
                header: t("total"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.total.toFixed(2)}{" "}
                        {t(row.original.currency)}
                    </div>
                ),
            },
            {
                accessorKey: "engaged",
                header: t("engaged"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.engaged.toFixed(2)}{" "}
                        {t(row.original.currency)}
                    </div>
                ),
            },
            {
                accessorKey: "remaining",
                header: t("remaining"),
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.remaining.toFixed(2)}{" "}
                        {t(row.original.currency)}
                    </div>
                ),
            },
            {
                id: "actions",
                header: "",
                enableColumnFilter: false,
                enableSorting: false,
                enableColumnActions: false,

                Cell: ({ renderedCellValue, row }) => (
                    <div onClick={(e) => e.stopPropagation()}>
                        <BudgetsActions budget={row.original} />
                    </div>
                ),
            },
        ],
        [],
    )
    const table = useMantineReactTable<Budget>({
        columns,
        data: budgetsTrees,
        positionPagination: "top",
        enableExpanding: true,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                navigate(
                    `/dashboard/projects/${projectId}/finance/budgets/${row.original.id}`,
                )
            },
            sx: {
                cursor: "pointer", //you might want to change the cursor too when adding an onClick
            },
        }),
        getSubRows: (row) => row.children,
    })
    return (
        <div className="w-full h-full flex flex-col gap-4">
            <div className="w-full flex justify-end">
                <Button
                    onClick={() => setShowBudgetCreateModal(true)}
                    name={t("create_budget")}
                ></Button>
            </div>
            <Scrollable>
                <div className="flex flex-col gap-4">
                    <BudgetGeneralInfo />

                    <MantineReactTable table={table} />
                </div>
            </Scrollable>
            <NewModal
                isShown={showBudgetCreateModal}
                closeModal={() => setShowBudgetCreateModal(false)}
            >
                <BudgetForm
                    closeModal={() => setShowBudgetCreateModal(false)}
                />
            </NewModal>
        </div>
    )
}
