import { useAppSelector } from "@/app/hooks"
import { compareWBS, GanttFormatterMapping } from "@/common/helpers"
import { Task } from "@/models/Task"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { selectTaskMappingById } from "../planningSlice"
import { FilterFields, FilterGroup, FilterValuesType } from "./Filters/Filters"
import { applyFilterToTasksAndChildren } from "./ganttHelpers"
import { selectGanttCalendar } from "./ganttSlice"

interface TaskItemProps {
    task: Task
}

export const GanttTaskCalendarRow: React.FC<TaskItemProps> = ({ task }) => {
    const calendar = useAppSelector(selectGanttCalendar)
    const [hasChildren, setHasChildren] = useState<boolean>(
        task.children.length > 0,
    )
    const [width, setWidth] = useState<number>(0)
    const [margin, setMargin] = useState<number>(0)
    const [childInFilter, setChildInFilter] = useState<boolean>(true)
    const [inFilter, setInFilter] = useState<boolean>(true)
    const { t } = useTranslation()
    const nextDate = GanttFormatterMapping[calendar.zoom].nextDate
    const taskMapping = useAppSelector(selectTaskMappingById(task.id))
    const filters = useAppSelector((state) => state.gantt.filters)
    const filterGroup = new FilterGroup(filters)
    const startDateOffset = GanttFormatterMapping[
        calendar.zoom
    ].startDateOffsetCalc(task.startDate)
    const endDateOffset = GanttFormatterMapping[
        calendar.zoom
    ].endDateOffsetCalc(task.endDate)

    useEffect(() => {
        const width =
            calendar.unit *
                GanttFormatterMapping[calendar.zoom].intervalFn(
                    task.endDate,
                    task.startDate,
                ) +
            calendar.unit -
            (startDateOffset + endDateOffset)
        const margin =
            calendar.unit *
                GanttFormatterMapping[calendar.zoom].intervalFn(
                    task.startDate,
                    calendar.startDate,
                ) +
            startDateOffset
        setWidth(width)
        setMargin(margin)
    }, [taskMapping, calendar.zoom])
    useEffect(() => {
        setChildInFilter(
            applyFilterToTasksAndChildren(task, filters, filterGroup),
        )
        setInFilter(
            filterGroup.applyFilters(
                task,
                filters as FilterValuesType<FilterFields>,
            ),
        )
    }, [filters])
    return childInFilter ? (
        <>
            <div className={`h-12 pt-4 w-max ${inFilter ? "" : "opacity-20"}`}>
                <div
                    className={`h-3 transition cursor-pointer group  relative ${task.hasChildren ? "bg-yellow-400 hover:bg-yellow-600" : "bg-green-400 hover:bg-green-600"}`}
                    style={{
                        width: width > 20 ? width : 20 + "px",
                        marginLeft: margin + "px",
                        height: width > 20 ? 12 : 20 + "px",
                        ...(width < 20 && { borderRadius: "0.3rem" }),
                    }}
                >
                    {width > 20 && (
                        <div
                            className={`absolute top-2 w-0 h-0  border-l-transparent border-t-[15px] transition border-r-[10px] border-r-transparent ${
                                task.hasChildren
                                    ? "border-t-yellow-400 group-hover:border-t-yellow-600"
                                    : "border-t-green-400 group-hover:border-t-green-600"
                            }`}
                        ></div>
                    )}
                    <div
                        className={`absolute -mt-1 top-0 right-full mr-4 text-nowrap ${task.hasChildren ? "font-bold" : ""}`}
                    >
                        {task.name}
                    </div>
                    {width > 20 && (
                        <div
                            className={`absolute top-2 right-0 w-0 h-0  transition border-t-[15px]  border-l-[10px] border-l-transparent ${
                                task.hasChildren
                                    ? "border-t-yellow-400 group-hover:border-t-yellow-600"
                                    : "border-t-green-400 group-hover:border-t-green-600"
                            }`}
                        ></div>
                    )}
                </div>
            </div>
            {taskMapping &&
                taskMapping.isExpanded &&
                task.children
                    .sort((a, b) => compareWBS(a.wbs, b.wbs))
                    .map((child) => (
                        <GanttTaskCalendarRow key={child.id} task={child} />
                    ))}
        </>
    ) : null
}
