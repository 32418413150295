import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { getUserProjectTasks, selectUserTasks } from "@/features/User/userSlice"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getProjectById, selectProject } from "../projectsSlice"
import { DetailsProjectWidget } from "./DetailsProjectWidget"
import { DetailsTasksWidget } from "./DetailsTasksWidget"
import { DetailsTimelineWidget } from "./DetailsTimelineWidget"

export const Details = () => {
    const dispatch = useAppDispatch()
    const { projectId } = useParams<{ projectId: string }>()
    const project = useAppSelector(selectProject)
    const userTasks = useAppSelector(selectUserTasks)
    useEffect(() => {
        dispatch(getProjectById(projectId))
        dispatch(getUserProjectTasks(projectId))
    }, [projectId, dispatch])
    return (
        <Scrollable width="100%" height="100%">
            <div className="h-full flex flex-col">
                <div className="shadow-lg ">
                    <DetailsProjectWidget project={project} />
                </div>
                <div className="mt-4 flex gap-5 py-4 lg:flex-col">
                    <div className="w-full bg-secondary-100 shadow-lg">
                        <DetailsTasksWidget tasks={userTasks} />
                    </div>
                    <div className="w-full bg-secondary-100 shadow-lg">
                        <DetailsTimelineWidget tasks={userTasks} />
                    </div>
                </div>
            </div>
        </Scrollable>
    )
}
