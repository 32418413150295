import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, Outlet, useLocation } from "react-router-dom"

export interface TabbedWindowInterface {
    name: string
    route: string
    active?: boolean
    icon?: IconProp
}

interface TabbedWindowProps {
    tabs: Array<TabbedWindowInterface>
}

export const TabbedWindow: React.FC<TabbedWindowProps> = ({ tabs }) => {
    const { t } = useTranslation()
    const [localTabs, setLocalTabs] = useState<TabbedWindowInterface[]>(tabs)
    const activeTab = `bg-primary-200 !text-secondary-100 `
    const defaultTabStyle =
        "p-3 flex justify-center duration-150 hover:bg-primary-200 hover:text-secondary-100 cursor-pointer text-primary-300 items-center gap-3"
    const location = useLocation()
    useEffect(() => {
        setLocalTabs(tabs)
    }, [tabs])
    return (
        <div className="w-full h-full shadow-lg overflow-hidden flex flex-col">
            <div className="border-b text-primary-300 border-opacity-50 rounded-t-lg">
                <ul className="flex bg-secondary-100 text-primary-300 sm:overflow-x-auto">
                    {localTabs.map((tab, index) => (
                        <li
                            key={index}
                            className={
                                (location.pathname.includes(tab.route)
                                    ? activeTab + defaultTabStyle
                                    : defaultTabStyle) +
                                (index !== 0 ? " border-l" : "")
                            }
                        >
                            <Link to={tab.route} className="flex items-center gap-2 w-full h-full">
                                {tab.icon && (
                                    <FontAwesomeIcon icon={tab.icon} />
                                )}
                                <p className="text-nowrap">{t(tab.name)}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Outlet />
        </div>
    )
}
