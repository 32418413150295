import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { WithLoader } from "@/components/Loaders/WithLoader"
import { Task } from "@/models/Task"
import { t } from "i18next"
import { TaskCard } from "./Card"

interface CardsBoardProps {
    tasks: Task[]
    mobile?: boolean
}

export const CardsBoard: React.FC<CardsBoardProps> = ({
    tasks,
    mobile = false,
}) => {
    const status = useAppSelector((state) => state.tasks.status)
    return (
        <div className="w-full p-3">
            <WithLoader statuses={[status.read]} title={t("loading_tasks")}>
                <Scrollable>
                    <div className="flex flex-wrap justify-between gap-y-4 gap-x-1">
                        {tasks.map((task) => (
                            <TaskCard key={task.id} task={task} />
                        ))}
                    </div>
                </Scrollable>
            </WithLoader>
        </div>
    )
}
