import { useAppDispatch } from "@/app/hooks"
import { CalendarInput } from "@/components/Inputs/CalendarInput"
import { DropDown } from "@/components/Inputs/DropDown"
import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import { isNull } from "lodash"
import { useTranslation } from "react-i18next"
import { FilterFields, FilterOperators, FilterValuesType } from "./Filters"

interface DateFilterProps {
    filters: FilterValuesType<FilterFields>
    field: FilterFields
    setFilters: ActionCreatorWithPayload<FilterValuesType<FilterFields>, any>
}

export const DateFilter: React.FC<DateFilterProps> = ({
    filters,
    field,
    setFilters,
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    return (
        <div className="w-full h-full ">
            <div className="flex items-center flex-wrap gap-2">
                <p>{t("operator")}:</p>
                <DropDown
                    value={filters[field].operator}
                    name={t("operator")}
                    options={Object.values(FilterOperators.DateOperators).map(
                        (operator) => ({
                            value: operator,
                            label: t(operator),
                        }),
                    )}
                    onChange={(e) => {
                        dispatch(
                            setFilters({
                                ...filters,
                                [field]: {
                                    ...filters[field],
                                    operator: e.target.value,
                                },
                            }),
                        )
                    }}
                />
            </div>
            {filters[field].operator !==
            FilterOperators.DateOperators.Between ? (
                <div className="flex items-center flex-wrap gap-2">
                    <p>{t("value")}:</p>
                    <CalendarInput
                        name={t(field)}
                        value={filters[field].value as string}
                        onChange={(e) => {
                            dispatch(
                                setFilters({
                                    ...filters,
                                    [field]: {
                                        ...filters[field],
                                        value: e.target.value,
                                    },
                                }),
                            )
                        }}
                    />
                </div>
            ) : (
                <div className="flex items-center flex-wrap gap-2">
                    <p>{t("value")}:</p>
                    <CalendarInput
                        name={t(field)}
                        value={
                            !isNull(filters[field].value) &&
                            Array.isArray(filters[field].value)
                                ? (filters[field].value as Date[])[0].toString()
                                : ""
                        }
                        onChange={(e) => {
                            dispatch(
                                setFilters({
                                    ...filters,
                                    [field]: {
                                        ...filters[field],
                                        value: [
                                            e.target.value,
                                            Array.isArray(filters[field].value)
                                                ? (
                                                      filters[field]
                                                          .value as Date[]
                                                  )[1].toString()
                                                : "",
                                        ],
                                    },
                                }),
                            )
                        }}
                    />
                    <CalendarInput
                        name={t(field)}
                        value={
                            !isNull(filters[field].value) &&
                            Array.isArray(filters[field].value)
                                ? (filters[field].value as Date[])[1].toString()
                                : ""
                        }
                        onChange={(e) => {
                            dispatch(
                                setFilters({
                                    ...filters,
                                    [field]: {
                                        ...filters[field],
                                        value: [
                                            Array.isArray(filters[field].value)
                                                ? (
                                                      filters[field]
                                                          .value as Date[]
                                                  )[0].toString()
                                                : "",
                                            e.target.value,
                                        ],
                                    },
                                }),
                            )
                        }}
                    />
                </div>
            )}
        </div>
    )
}
