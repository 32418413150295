import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { ArrayInput } from "@/components/Inputs/ArrayInput"
import { DropDown } from "@/components/Inputs/DropDown"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import { CURRENCY } from "@/models/Finance"
import { PricingPlan } from "@/models/Pricing"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
    clearErrors,
    createPricingPlan,
    updatePricingPlan,
} from "../pricingSlice"

interface PricingPlanInterface {
    pricingPlan?: PricingPlan
    onCancel: () => void
}

export const PricingPlanForm: React.FC<PricingPlanInterface> = ({
    pricingPlan,
    onCancel,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.pricing.status)
    const [localPricingPlan, setLocalPricingPlan] = useState(
        pricingPlan ? new PricingPlan(pricingPlan.toJson()) : new PricingPlan(),
    )

    return (
        <Form
            loadingTitle={
                pricingPlan
                    ? t("editing_pricing_plan")
                    : t("creating_pricing_plan")
            }
            onSubmit={async () => {
                if (!pricingPlan) {
                    const { type } = await dispatch(
                        createPricingPlan(localPricingPlan.createPayload()),
                    )
                    if (type === createPricingPlan.fulfilled.type) {
                        onCancel()
                    }
                } else {
                    const { type } = await dispatch(
                        updatePricingPlan({
                            pricingPlanId: pricingPlan.id,
                            data: localPricingPlan.updatePayload(),
                        }),
                    )
                    if (type === updatePricingPlan.fulfilled.type) {
                        onCancel()
                    }
                }
            }}
            clearErrors={clearErrors}
            statuses={[status.create, status.update]}
            submitType={pricingPlan ? SubmitType.Save : SubmitType.Create}
            onCancel={onCancel}
        >
            <h1 className="font-bold text-lg mb-2">
                {t("creating_new_pricing_plan")}
            </h1>
            <div className="flex gap-2 items-center mt-2">
                <Input
                    label={t("name")}
                    value={localPricingPlan.name}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                name: e.target.value,
                            }),
                        )
                    }
                    required
                    name={t("name")}
                    type="text"
                    className="w-full !mt-0"
                />
                <Input
                    label={t("price")}
                    value={localPricingPlan.price}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                price: parseFloat(e.target.value),
                            }),
                        )
                    }
                    required
                    name={t("price")}
                    type="number"
                    className="w-full !mt-0"
                />
                <DropDown
                    label={t("currency")}
                    value={localPricingPlan.currency}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                currency: e.target.value as any,
                            }),
                        )
                    }
                    required
                    name={t("currency")}
                    options={Object.values(CURRENCY).map((currency) => ({
                        value: currency,
                        label: t(currency),
                    }))}
                />
            </div>
            <div className="flex gap-2 items-center">
                <Input
                    label={t("number_of_users")}
                    value={localPricingPlan.numberOfUsers}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                numberOfUsers: parseInt(e.target.value),
                            }),
                        )
                    }
                    required
                    name={t("number_of_users")}
                    type="number"
                />
                <Input
                    label={t("number_of_projects")}
                    value={localPricingPlan.numberOfProjects}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                numberOfProjects: parseInt(e.target.value),
                            }),
                        )
                    }
                    required
                    name={t("number_of_projects")}
                    type="number"
                />
                <Input
                    label={t("number_of_emails")}
                    value={localPricingPlan.numberOfEmails}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                numberOfEmails: parseInt(e.target.value),
                            }),
                        )
                    }
                    required
                    name={t("number_of_emails")}
                    type="number"
                />
            </div>
            <div className="flex gap-1">
                <Input
                    label={t("storage")}
                    value={localPricingPlan.storage}
                    onChange={(e) =>
                        setLocalPricingPlan(
                            new PricingPlan({
                                ...localPricingPlan.toJson(),
                                storage: parseInt(e.target.value),
                            }),
                        )
                    }
                    required
                    name={t("storage")}
                    type="number"
                    className="w-full"
                />

                <p className="self-end text-2xl">GB</p>
            </div>
            <ArrayInput
                label={t("feature")}
                value={localPricingPlan.features}
                onChange={(value) =>
                    setLocalPricingPlan(
                        new PricingPlan({
                            ...localPricingPlan.toJson(),
                            features: value,
                        }),
                    )
                }
                required
                name={t("features")}
                type="text"
                className="w-full"
            />
        </Form>
    )
}
