import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Currency } from "@/components/Other/Currency"
import { Article } from "@/models/Article"
import { Budget } from "@/models/Finance"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getBudgetArticles, selectArticles } from "../../Articles/articlesSlice"
import { BudgetArticleTableActions } from "./BudgetArticleTableActions"

interface BudgetPerArticleTableProps {
    budget: Budget
}

export const BudgetPerArticleTable: React.FC<BudgetPerArticleTableProps> = ({
    budget,
}) => {
    const { t } = useTranslation()
    const articles = useAppSelector(selectArticles)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"

    useEffect(() => {
        dispatch(getBudgetArticles({ projectId, budgetId }))
    }, [])

    const columns = useMemo<MRT_ColumnDef<Article>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("article"),
            },
            {
                accessorKey: "description",
                header: t("description"),
            },
            {
                accessorKey: "unitPrice",
                header: t("unit_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex gap-1 items-center">
                            {article.unitPrice}{" "}
                            <Currency currency={budget.currency} />
                            {" / "}
                            {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                header: t("items"),
                Cell: ({ row }) => {
                    const article = row.original
                    return <span>{article.ifcEntities.length}</span>
                },
            },
            {
                header: "",
                id: "actions",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ row }) => {
                    return <BudgetArticleTableActions article={row.original} />
                },
            },
        ],
        [],
    )

    const table = useMantineReactTable<Article>({
        columns,
        data: articles,
        positionPagination: "top",
    })

    return <MantineReactTable table={table} />
}
