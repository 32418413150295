import {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    differenceInDays,
    differenceInMonths,
    differenceInQuarters,
    getQuarter,
    getWeekOfMonth,
    lastDayOfMonth,
    lastDayOfQuarter,
    subQuarters,
} from "date-fns"
import { t } from "i18next"
import { CalendarZoomModes } from "./types"

const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
})

export const formatDate = (date: Date | string) => {
    return dateTimeFormat.format(new Date(date))
}

export const dateToHtmlInputValue = (date: Date | string) => {
    return new Date(date).toISOString().split("T")[0]
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export const isDateBefore = (
    firstDate: Date,
    secondDate: Date,
    orEqual: boolean = true,
) => {
    const firstDateWithoutTime = new Date(firstDate.setHours(0, 0, 0, 0))
    const secondDateWithoutTime = new Date(secondDate.setHours(0, 0, 0, 0))
    return orEqual
        ? firstDateWithoutTime <= secondDateWithoutTime
        : firstDateWithoutTime < secondDateWithoutTime
}
export const isDateAfter = (
    firstDate: Date,
    secondDate: Date,
    orEqual: boolean = true,
) => {
    const firstDateWithoutTime = new Date(firstDate.setHours(0, 0, 0, 0))
    const secondDateWithoutTime = new Date(secondDate.setHours(0, 0, 0, 0))
    return orEqual
        ? firstDateWithoutTime >= secondDateWithoutTime
        : firstDateWithoutTime > secondDateWithoutTime
}

export const isDateEqual = (firstDate: Date, secondDate: Date) => {
    const firstDateWithoutTime = new Date(firstDate.setHours(0, 0, 0, 0))
    const secondDateWithoutTime = new Date(secondDate.setHours(0, 0, 0, 0))
    return firstDateWithoutTime.getTime() === secondDateWithoutTime.getTime()
}

export const GanttFormatterMapping = {
    [CalendarZoomModes.Day]: {
        nextDate: (date: Date) => addDays(date, 1),
        intervalFn: (firstDate: Date, secondDate: Date) =>
            differenceInDays(firstDate, secondDate),
        generalFormatter: (date: Date) =>
            `${t("week")} ${getWeekOfMonth(date)} - ${t("intlDateTime", { val: date, year: "numeric", month: "short" })}`,
        detailsFormatter: (date: Date) =>
            t("intlDateTime", {
                val: date,
                formatParams: {
                    val: {
                        weekday: "short",
                        day: "numeric",
                    },
                },
            }),

        startDateOffsetCalc: (taskStartDate: Date) => 0,
        endDateOffsetCalc: (taskEndDate: Date) => 0,
    },
    [CalendarZoomModes.Week]: {
        nextDate: (date: Date) => addWeeks(date, 1),
        intervalFn: (firstDate: Date, secondDate: Date) =>
            differenceInDays(firstDate, secondDate),
        generalFormatter: (date: Date) =>
            t("intlDateTime", {
                val: date,
                day: "numeric",
                month: "short",
                year: "numeric",
            }),
        detailsFormatter: (date: Date) =>
            t("intlDateTime", { val: date, weekday: "short" }).slice(0, 1),
        startDateOffsetCalc: (taskStartDate: Date) => 0,
        endDateOffsetCalc: (taskEndDate: Date) => 0,
    },
    [CalendarZoomModes.Month]: {
        nextDate: (date: Date) => addMonths(date, 1),
        intervalFn: (firstDate: Date, secondDate: Date) =>
            differenceInMonths(
                addDays(lastDayOfMonth(new Date(firstDate)), 1),
                lastDayOfMonth(new Date(secondDate)),
            ),
        generalFormatter: (date: Date) =>
            `Q${getQuarter(date)} ` +
            t("intlDateTime", { val: date, year: "numeric" }),
        detailsFormatter: (date: Date) =>
            t("intlDateTime", { val: date, month: "long" }),
        startDateOffsetCalc: (taskStartDate: Date) => {
            const daysDiff = differenceInDays(
                taskStartDate,
                new Date(
                    taskStartDate.getFullYear(),
                    taskStartDate.getMonth(),
                    1,
                ),
            )
            const daysInTaskMonth = lastDayOfMonth(taskStartDate).getDate()
            return (daysDiff / daysInTaskMonth) * 90
        },
        endDateOffsetCalc: (taskEndDate: Date) => {
            const daysDiff = differenceInDays(
                lastDayOfMonth(taskEndDate),
                taskEndDate,
            )
            const daysInTaskMonth = lastDayOfMonth(taskEndDate).getDate()
            return (daysDiff / daysInTaskMonth) * 90
        },
    },
    [CalendarZoomModes.Quarter]: {
        nextDate: (date: Date) => addQuarters(date, 1),
        intervalFn: (firstDate: Date, secondDate: Date) =>
            differenceInQuarters(
                lastDayOfQuarter(firstDate),
                lastDayOfQuarter(subQuarters(new Date(secondDate), 1)),
            ),
        generalFormatter: (date: Date) =>
            t("intlDateTime", { val: date, year: "numeric" }),
        detailsFormatter: (date: Date) => `Q${getQuarter(date)}`,
        startDateOffsetCalc: (taskStartDate: Date) => {
            const daysDiffFromQuarterStart = differenceInDays(
                taskStartDate,
                new Date(
                    taskStartDate.getFullYear(),
                    Math.floor(taskStartDate.getMonth() / 3) * 3,
                    1,
                ),
            )
            const daysInTaskQuarter = lastDayOfQuarter(taskStartDate).getDate()
            return (daysDiffFromQuarterStart / daysInTaskQuarter) * 120
        },
        endDateOffsetCalc: (taskEndDate: Date) => {
            const daysDiffFromQuarterEnd = differenceInDays(
                lastDayOfQuarter(taskEndDate),
                taskEndDate,
            )
            const daysInTaskQuarter = lastDayOfQuarter(taskEndDate).getDate()
            return (daysDiffFromQuarterEnd / daysInTaskQuarter) * 120
        },
    },
}

export const compareWBS = (wbs1: string, wbs2: string) => {
    const parts1 = wbs1.split(".").map((part) => parseInt(part))
    const parts2 = wbs2.split(".").map((part) => parseInt(part))

    for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
        if (parts1[i] !== parts2[i]) {
            return parts1[i] - parts2[i]
        }
    }

    return parts1.length - parts2.length
}
