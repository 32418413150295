import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { MODULE_PERMISSIONS } from "@/models/Permission"
import { Planning } from "@/models/Planning"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { PlanningForm } from "./PlanningForm"
import { clearErrors, deletePlanning, setPlanningAsMain } from "./planningSlice"

interface PlanningItemProps {
    planning: Planning
}

export const PlanningItem: React.FC<PlanningItemProps> = ({ planning }) => {
    const { projectId } = useParams<{ projectId: string }>()
    const userPermissions = useAppSelector(selectUserPermissions)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSetMainModal, setShowSetMainModal] = useState(false)
    const status = useAppSelector((state) => state.plannings.status)
    const dispatch = useAppDispatch()

    const { t } = useTranslation()
    return (
        <>
            <Link
                to={`/dashboard/projects/${projectId}/plannings/${planning.id}`}
            >
                <div className="w-full flex justify-between items-center rounded-lg p-3 shadow-lg hover:bg-primary-300 hover:text-secondary-100 cursor-pointer transition group sm:flex-col sm:items-start">
                    <span className="w-[65%] sm:w-full">
                        <h3 className="text-xl font-bold">{planning.name}</h3>
                        <span>
                            <p>
                                {t("intlDateTime", {
                                    val: planning.createdAt,
                                    formatParams: {
                                        val: {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        },
                                    },
                                })}
                            </p>
                            <p>{planning.description}</p>
                        </span>
                    </span>
                    <span className="flex gap-5 items-center w-[30%] justify-between sm:w-full sm:mt-2">
                        <div>
                            <p>
                                <span className="font-bold">
                                    {t("progress")}:
                                </span>{" "}
                                {planning.mainTask.progress}%
                            </p>
                            <p>
                                <span className="font-bold">
                                    {t("duration")}:
                                </span>{" "}
                                {planning.mainTask.duration}{" "}
                                {planning.mainTask.duration > 1
                                    ? "days"
                                    : "day"}
                            </p>
                        </div>
                        {userPermissions.hasOneOfPermissions([
                            MODULE_PERMISSIONS.Planning.Delete,
                            MODULE_PERMISSIONS.Planning.Write,
                        ]) && (
                            <div
                                className="flex items-start justify-center gap-3"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                            >
                                {userPermissions.hasAllPermissions([
                                    MODULE_PERMISSIONS.Planning.Write,
                                ]) && (
                                    <>
                                        <FontAwesomeIcon
                                            icon="star"
                                            className={`${planning.isMain ? "opacity-100" : "opacity-30"} text-yellow-400 hover:opacity-100 transition`}
                                            onClick={(e) => {
                                                if (!planning.isMain) {
                                                    setShowSetMainModal(true)
                                                }
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            icon="edit"
                                            className="text-primary-300 hover:text-primary-100 group-hover:text-secondary-100  group-hover:hover:text-secondary-400 transition"
                                            onClick={(e) => {
                                                setShowEditModal(true)
                                            }}
                                        />
                                    </>
                                )}
                                {userPermissions.hasAllPermissions([
                                    MODULE_PERMISSIONS.Planning.Delete,
                                ]) && (
                                    <FontAwesomeIcon
                                        icon="trash"
                                        className="text-red-500 hover:text-red-700 transition"
                                        onClick={(e) => {
                                            setShowDeleteModal(true)
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </span>
                </div>
            </Link>

            <NewModal
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <div className="w-96">
                    <PlanningForm
                        planning={planning}
                        closeModal={() => setShowEditModal(false)}
                    />
                </div>
            </NewModal>
            <NewModal
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <Form
                    statuses={[status.delete]}
                    loadingTitle={t("deleting_planning")}
                    clearErrors={clearErrors}
                    submitType={SubmitType.Delete}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            deletePlanning({
                                projectId,
                                planningId: planning.id,
                            }),
                        )
                        if (type === deletePlanning.fulfilled.type) {
                            setShowDeleteModal(false)
                        }
                    }}
                    onCancel={() => setShowDeleteModal(false)}
                >
                    <div
                        className="w-96 z-20"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <p className="font-bold text-lg">
                            {t("planning_delete_notice", {
                                planning: planning.name,
                            })}
                        </p>
                    </div>
                </Form>
            </NewModal>
            <NewModal
                isShown={showSetMainModal}
                closeModal={() => setShowSetMainModal(false)}
            >
                <Form
                    statuses={[status.update]}
                    loadingTitle={t("setting_main_planning")}
                    className="w-full"
                    submitType={SubmitType.Save}
                    onCancel={() => setShowSetMainModal(false)}
                    clearErrors={clearErrors}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            setPlanningAsMain({
                                projectId,
                                planningId: planning.id,
                            }),
                        )
                        if (type === setPlanningAsMain.fulfilled.type) {
                            setShowSetMainModal(false)
                        }
                    }}
                >
                    <div
                        className="w-96 z-20"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <p className="font-bold text-lg">
                            {t("setting_main_planning_notice", {
                                planning: planning.name,
                            })}
                        </p>
                        <div className="flex mt-5 gap-2 justify-end">
                            <Button name={t("cancel")} icon="times"></Button>
                            <Button
                                name={t("set_as_main")}
                                className="!bg-green-400 hover:!bg-green-600 border-green-400 hover:border-green-600 text-white hover:text-white transition"
                                icon="star"
                                onClick={() =>
                                    dispatch(
                                        setPlanningAsMain({
                                            projectId,
                                            planningId: planning.id,
                                        }),
                                    )
                                }
                            ></Button>
                        </div>
                    </div>
                </Form>
            </NewModal>
        </>
    )
}
