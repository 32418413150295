import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { UserSettings } from "@/components/Forms/UserSettings"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { NotificationContainer } from "@/components/Notifications/NotificationContainer"
import { BimpackLogo } from "@/components/Other/BimpackLogo"
import { UserNotification } from "@/models/Notification"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { logout, selectAuthUser } from "../Authentication/authSlice"
import {
    getUserAllNotifications,
    selectUserAllNotifications,
} from "../User/userSlice"

const menuItems = [
    [
        {
            name: "home",
            icon: "home" as IconProp,
            route: "/dashboard/home/",
        },
        {
            name: "profile",
            icon: "user" as IconProp,
            route: "/dashboard/profile/",
        },
        {
            name: "notifications",
            icon: "bell" as IconProp,
            route: "/dashboard/notifications/",
        },
    ],
    [
        {
            name: "projects",
            icon: "folder" as IconProp,
            route: "/dashboard/projects/",
        },
    ],
]
interface NavbarProps {
    adminDashboard?: boolean
}

export const Navbar: React.FC<NavbarProps> = ({ adminDashboard = false }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const user = useAppSelector(selectAuthUser)
    const notifications = useAppSelector(selectUserAllNotifications)
    const [oldNotifications, setOldNotifications] = useState<
        UserNotification[]
    >([])
    const [newNotifications, setNewNotifications] = useState<
        UserNotification[]
    >([])
    const [showNotifications, setShowNotifications] = useState<boolean>(false)
    const [showSettings, setShowSettings] = useState<boolean>(false)
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch(logout())
        navigate("/auth/login")
    }
    useEffect(() => {
        dispatch(getUserAllNotifications({}))
    }, [])
    useEffect(() => {
        const notSeen = notifications.filter(
            (notification) => notification.seen,
        )
        setOldNotifications(notSeen)
        const seen = notifications.filter((notification) => !notification.seen)
        setNewNotifications(seen)
    }, [notifications])

    return (
        <>
            <div
                className={`w-full flex justify-between p-3 items-center px-10 text-secondary-100 sm:justify-center`}
                style={{
                    backgroundColor: "#03192F",
                }}
            >
                <h1 className="font-bold text-3xl sm:hidden flex items-center gap-1">
                    <BimpackLogo withText={true} />
                    {adminDashboard && (
                        <p className=" text-xs font-medium mt-2">
                            {t("admin")}
                        </p>
                    )}
                </h1>
                <div className="flex gap-2 items-center sm:justify-between sm:w-full">
                    <div className="flex justify-center items-center gap-2 sm:justify-between w-full">
                        <div className="hidden sm:block relative">
                            <FontAwesomeIcon
                                icon="list"
                                onClick={() =>
                                    setShowMobileMenu(!showMobileMenu)
                                }
                            />
                            {showMobileMenu && (
                                <div className="fixed w-screen left-0 z-20">
                                    <ul className="bg-primary-100 p-4">
                                        {menuItems.map((groups, idx) => (
                                            <li
                                                key={idx}
                                                className={`${idx + 1 !== menuItems.length ? "border-b" : ""} border-secondary-100 border-opacity-25 overflow-hidden`}
                                            >
                                                <ul>
                                                    {groups.map((item, idx) => (
                                                        <li key={idx}>
                                                            <div
                                                                onClick={() => {
                                                                    navigate(
                                                                        item.route,
                                                                    )
                                                                    setShowMobileMenu(
                                                                        false,
                                                                    )
                                                                }}
                                                            >
                                                                <div className="cursor-pointer hover:bg-primary-400 transition p-2 rounded-md flex items-center justify-center">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            item.icon
                                                                        }
                                                                        className="mr-2"
                                                                    />
                                                                    {t(
                                                                        item.name,
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        {/* <FontAwesomeIcon icon="moon" /> */}
                        {adminDashboard && (
                            <div>
                                <a href="/dashboard/projects">
                                    <Button
                                        className=" !rounded-lg !bg-green-400  text-green-600 hover:!bg-green-600 hover:!text-white"
                                        name={t("dashboard")}
                                    ></Button>
                                </a>
                            </div>
                        )}

                        {user.isSuperAdmin && !adminDashboard && (
                            <a href="/admin/home">
                                <Button
                                    className=" !rounded-lg !bg-red-400  text-red-600 hover:!bg-red-600 hover:!text-white"
                                    name={t("admin")}
                                ></Button>
                            </a>
                        )}

                        <div className="relative">
                            <FontAwesomeIcon
                                icon="cog"
                                className="cursor-pointer hover:scale-125 transition"
                                onClick={() => {
                                    setShowSettings(!showSettings)
                                    setShowNotifications(false)
                                }}
                            />
                        </div>
                        <div
                            className="relative"
                            onClick={() => {
                                setShowNotifications(!showNotifications)
                                setShowSettings(false)
                            }}
                        >
                            <div className="cursor-pointer hover:scale-125 transition">
                                <FontAwesomeIcon icon="bell" />
                                {newNotifications.length > 0 && (
                                    <div className="absolute w-2 h-2 bg-red-400 text-xs rounded-full flex justify-center items-center top-1 left-2"></div>
                                )}
                            </div>
                            {showNotifications && (
                                <NotificationContainer
                                    newNotifications={newNotifications}
                                    oldNotifications={oldNotifications}
                                    setShowContainer={setShowNotifications}
                                />
                            )}
                        </div>
                        <p className="ml-2 sm:hidden">
                            {t("welcome")}, {user.firstName}!
                        </p>
                        <img
                            src={user.getProfilePictureUrl()}
                            alt="avatar"
                            className="w-10 h-10 rounded-full ml-1 border-2 border-green-500 object-cover sm:hidden"
                        />
                        <p
                            className="border-b cursor-pointer hover:font-semibold transition"
                            onClick={handleLogout}
                        >
                            <FontAwesomeIcon
                                icon="sign-out-alt"
                                className="mr-1"
                            />
                            {t("logout")}
                        </p>
                    </div>
                </div>
            </div>
            <NewModal
                isShown={showSettings}
                closeModal={() => setShowSettings(false)}
            >
                <UserSettings
                    user={user}
                    closeModal={() => setShowSettings(false)}
                />
            </NewModal>
        </>
    )
}
