import { SupportedLanguages } from "@/app/common"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { setAppLanguage } from "@/features/AppSettings/appSettingsSlice"
import {
    changeUserProfilePicture,
    clearErrors,
    updateUser,
} from "@/features/Authentication/authSlice"
import { Form, SubmitType } from "@/features/Form/Form"
import { User, UserUpdatePayload } from "@/models/User"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { DropDown } from "../Inputs/DropDown"
import { Input } from "../Inputs/Input"
import { WithLoader } from "../Loaders/WithLoader"

interface UserSettingsProps {
    user: User
    closeModal: () => void
    handleSubmit?: ({
        userId,
        user,
    }: {
        userId: string
        user: UserUpdatePayload
    }) => void
    handleProfilePictureChange?: ({
        userId,
        payload,
    }: {
        userId: string
        payload: FormData
    }) => void
}

export const UserSettings: React.FC<UserSettingsProps> = ({
    user,
    closeModal,
    handleSubmit,
    handleProfilePictureChange,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [profilePicture, setProfilePicture] = useState<File | null>(null)
    const uploadPercentage = useAppSelector(
        (state) => state.auth.uploadPercentage,
    )
    const status = useAppSelector((state) => state.auth.status)
    const [language, setLanguage] = useState<string>(
        localStorage.getItem("language") || "en",
    )
    const errors = useAppSelector((state) => state.auth.errors)
    const [localUser, setLocalUser] = useState<User>(new User(user.toJson()))
    const inputRef = useRef<HTMLInputElement>(null)
    const handleSubmitDefault = async () => {
        const { type } = await dispatch(
            updateUser({
                userId: user.id,
                user: localUser.updatePayload(),
            }),
        )
        if (type === updateUser.fulfilled.type) {
            closeModal()
        }
    }

    useEffect(() => {
        dispatch(setAppLanguage(language))
    }, [language])
    useEffect(() => {
        if (profilePicture) {
            const formData = new FormData()
            formData.append("profilePicture", profilePicture)
            if (handleProfilePictureChange) {
                handleProfilePictureChange({
                    userId: user.id,
                    payload: formData,
                })
            } else {
                dispatch(
                    changeUserProfilePicture({
                        userId: user.id,
                        payload: formData,
                    }),
                )
            }
        }
    }, [profilePicture])

    return (
        <div className="min-w-96 text-primary-300">
            <div className="flex flex-col items-center">
                <WithLoader
                    statuses={[status.create]}
                    title={`${t("uploading_profile_picture")} ${uploadPercentage}%`}
                >
                    <div className="flex w-full h-full items-center justify-center">
                        <div className="relative">
                            <div
                                onClick={() => {
                                    inputRef.current?.click()
                                }}
                                className="bg-primary-300  text-secondary-100 absolute right-5 bottom-2 rounded-full p-1 w-8 h-8 flex justify-center items-center bg-opacity-70 cursor-pointer hover:bg-opacity-100 hover:scale-110 transition"
                            >
                                <FontAwesomeIcon icon="camera" className="" />
                                <input
                                    type="file"
                                    className="hidden"
                                    ref={inputRef}
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setProfilePicture(e.target.files[0])
                                        }
                                    }}
                                />
                            </div>

                            <img
                                className="w-48 h-48 rounded-full border-4 border-opacity-70 border-primary-300 object-co"
                                src={user.profilePicture.path}
                                alt="profile"
                            />
                        </div>
                    </div>
                </WithLoader>

                <span className="font-bold text-2xl">
                    {user.firstName} {user.lastName}
                </span>
                <div className="text-sm">{user.email}</div>
            </div>

            <Form
                onCancel={closeModal}
                onSubmit={() => {
                    if (handleSubmit) {
                        handleSubmit({
                            userId: user.id,
                            user: localUser.updatePayload(),
                        })
                    } else {
                        handleSubmitDefault()
                    }
                }}
                statuses={[status.update]}
                loadingTitle={t("updating_profile")}
                submitType={SubmitType.Save}
                clearErrors={clearErrors}
            >
                <div className="text-xl font-bold mt-8">
                    {t("profile_settings")}:
                </div>
                <div className="flex justify-between items-center gap-8">
                    <Input
                        type="text"
                        name={t("first_name")}
                        label={t("first_name")}
                        value={localUser.firstName}
                        onChange={(e) => {
                            setLocalUser(
                                new User({
                                    ...localUser.toJson(),
                                    firstName: e.target.value,
                                }),
                            )
                        }}
                        errors={errors.firstName}
                    />
                    <Input
                        type="text"
                        name={t("last_name")}
                        label={t("last_name")}
                        value={localUser.lastName}
                        onChange={(e) => {
                            setLocalUser(
                                new User({
                                    ...localUser.toJson(),
                                    lastName: e.target.value,
                                }),
                            )
                        }}
                        errors={errors.lastName}
                    />
                </div>
                <Input
                    type="text"
                    name={t("phone")}
                    label={t("phone")}
                    value={localUser.phone}
                    onChange={(e) => {
                        setLocalUser(
                            new User({
                                ...localUser.toJson(),
                                phone: e.target.value,
                            }),
                        )
                    }}
                    errors={errors.phone}
                    className="w-full"
                />
                <div className="w-full">
                    <div className="text-xl font-bold mt-8">
                        {t("app_settings")}:
                    </div>
                    <div className="mt-4 ">
                        <p className="font-bold mb-2">{t("language")}</p>
                        <DropDown
                            value={language}
                            name={t("language")}
                            options={SupportedLanguages.map((lang) => ({
                                value: lang.code,
                                label: t(lang.name),
                            }))}
                            onChange={(e) => {
                                setLanguage(e.target.value)
                            }}
                        />
                    </div>
                </div>
            </Form>
        </div>
    )
}
