import { useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { Article } from "@/models/Article"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { selectArticles } from "../../Finance/Articles/articlesSlice"
import { IfcHandler } from "../IfcHandler"

interface element {
    uuid: string
    expressId: number
}

interface SelectionDetailsRowProps {
    ifcHandler: IfcHandler
    element: element
}
interface Properties {
    name: string
    guid: string
    properties: { [key: string]: string | number | boolean }
    quantities: { [key: string]: number }
}

export const SelectionDetailsRow: React.FC<SelectionDetailsRowProps> = ({
    ifcHandler,
    element,
}) => {
    const articles = useAppSelector(selectArticles)
    const [hasArticle, setHasArticle] = useState<boolean>(false)
    const [properties, setProperties] = useState<Properties>({
        name: "",
        guid: "",
        properties: {},
        quantities: {},
    })
    const [article, setArticle] = useState<Article>(new Article())
    useEffect(() => {
        ifcHandler
            .getSelectionProperties({
                id: element.expressId,
                fragments: {
                    [element.uuid]: new Set([element.expressId]),
                },
            })
            .then((properties) => {
                if (properties) {
                    setProperties(properties)
                }
            })
    }, [])

    useEffect(() => {
        const guid = properties.guid
        if (guid) {
            const article = articles.find((article) =>
                article.ifcEntities.find(
                    (ifcEntity) => ifcEntity.globalId === guid,
                ),
            )
            if (article) {
                setArticle(article)
                setHasArticle(true)
            } else {
                setHasArticle(false)
            }
        }
    }, [properties, articles])

    return (
        <div className="w-96 bg-secondary-100 text-primary-300 p-2 rounded-lg shadow-lg">
            <h3 className="font-bold text-lg text-center border-b mb-2">
                {properties.name}
            </h3>

            <div className="border-b">
                <h4 className="font-bold text-center">{t("article")}</h4>
                {hasArticle ? (
                    <>
                        <p>
                            <span className="font-bold">
                                {t("name") + ": "}
                            </span>
                            {article.name}
                        </p>
                        <p>
                            <span className="font-bold">
                                {t("description") + ": "}
                            </span>
                            {article.description}
                        </p>
                        <p>
                            <span className="font-bold">
                                {t("unit_price") + ": "}
                            </span>
                            {article.unitPrice}/
                            {t("unit." + article.unit.toLowerCase())}
                        </p>
                    </>
                ) : (
                    <p className="text-center">{t("no_article_selected")}</p>
                )}
            </div>
            <div className="border-b">
                <h4 className="font-bold text-center">{t("properties")}</h4>
                {Object.keys(properties.properties).length > 0 ? (
                    <Scrollable height="200px">
                        {Object.keys(properties.properties).map((key) => (
                            <p key={key}>
                                <span className="font-bold">{key + ": "}</span>
                                {typeof properties.properties[key] === "boolean"
                                    ? properties.properties[key]
                                        ? t("yes")
                                        : t("no")
                                    : properties.properties[key]}
                            </p>
                        ))}
                    </Scrollable>
                ) : (
                    <p className="text-center">{t("no_properties")}</p>
                )}
            </div>
            <div>
                <h4 className="font-bold text-center">{t("quantities")}</h4>
                {Object.keys(properties.quantities).length > 0 ? (
                    <Scrollable height="200px">
                        {Object.keys(properties.quantities).map((key) => (
                            <p key={key}>
                                <span className="font-bold">{key + ": "}</span>
                                {properties.quantities[key]}
                            </p>
                        ))}
                    </Scrollable>
                ) : (
                    <p className="text-center">{t("no_quantities")}</p>
                )}
            </div>
        </div>
    )
}
