import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { Input } from "@/components/Inputs/Input"
import { NewModal } from "@/components/Layouts/NewModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { FileExplorer } from "./FileExplorer"
import { FileManagerCreateForm } from "./Forms/FileManagerCreateForm"
import {
    getProjectRootFolders,
    searchDescendantFiles,
    searchDescendantFolders,
    selectFolder,
} from "./fileManagerSlice"

interface FileManagerProps {}

export const FileManager: React.FC<FileManagerProps> = () => {
    const [showCreateFolder, setShowCreateFolder] = useState<boolean>(false)
    const [showUploadFile, setShowUploadFile] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")
    const [showMenu, setShowMenu] = useState<boolean>(false)

    const folder = useAppSelector(selectFolder)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const folderId = useParams<{ folderId: string }>().folderId ?? "-1"

    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getProjectRootFolders(projectId))
    }, [])

    return (
        <div className="flex flex-col h-full p-2 overflow-hidden">
            <div className="flex justify-between sm:flex-wrap sm:gap-3">
                <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                    {t("files")}
                </h1>
                <FontAwesomeIcon
                    icon="list"
                    className={`text-xl transition hover:bg-primary-100 hover:text-secondary-100 p-2 rounded-full cursor-pointer ${
                        showMenu
                            ? `bg-primary-100 text-secondary-100`
                            : `bg-secondary-300 text-primary-300`
                    }`}
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            {showMenu && (
                <div className="bg-secondary-100 shadow-lg rounded-lg p-4 mt-3 flex justify-between sm:flex-wrap items-center sm:gap-y-2">
                    <div className="flex items-center gap-2 min-h-9">
                        <FontAwesomeIcon icon="search" />
                        <p>{t("search")}:</p>
                        <Input
                            name={t("search")}
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="!m-0 !bg-primary-300"
                        />
                        <Button
                            name={t("search")}
                            icon="search"
                            onClick={() => {
                                dispatch(
                                    searchDescendantFolders({
                                        projectId,
                                        query: search,
                                    }),
                                )
                                dispatch(
                                    searchDescendantFiles({
                                        projectId,
                                        query: search,
                                    }),
                                )
                            }}
                        />
                    </div>
                    <div className="flex gap-4 sm:justify-between sm:w-full">
                        <Button
                            name={t("create_folder")}
                            icon="plus"
                            onClick={() => setShowCreateFolder(true)}
                        />
                        <Button
                            name={t("upload_file")}
                            icon="file-upload"
                            onClick={() => setShowUploadFile(true)}
                        />
                    </div>
                </div>
            )}
            <div className="h-full mt-3 flex flex-col overflow-hidden">
                <FileExplorer />
            </div>
            <NewModal
                isShown={showCreateFolder}
                closeModal={() => setShowCreateFolder(false)}
            >
                <FileManagerCreateForm
                    resourceType="folder"
                    parentFolderId={folderId ?? null}
                    closeModal={() => setShowCreateFolder(false)}
                />
            </NewModal>
            <NewModal
                isShown={showUploadFile}
                closeModal={() => setShowUploadFile(false)}
            >
                <FileManagerCreateForm
                    resourceType="file"
                    parentFolderId={folderId ?? null}
                    closeModal={() => setShowUploadFile(false)}
                    multiple={true}
                />
            </NewModal>
        </div>
    )
}
