import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { FileInput } from "@/components/Inputs/FileInput"
import { NewModal } from "@/components/Layouts/NewModal"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { ProjectFile } from "@/models/File"
import { IfcModel } from "@/models/Ifc"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { clearErrors, deleteIfcModel, uploadIfcFileToModel } from "./ifcSlice"

interface IfcModelProps {
    ifcModel: IfcModel
    setSelectedFile: (file: ProjectFile | null) => void
    setSelectedModel: (model: IfcModel | null) => void
}

export const IfcModelComponent: React.FC<IfcModelProps> = ({
    ifcModel,
    setSelectedFile,
    setSelectedModel,
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [showDeleteNotice, setShowDeleteNotice] = useState<boolean>(false)
    const [showPermissionForm, setShowPermissionForm] = useState<boolean>(false)
    const [showUploadIfc, setShowUploadIfc] = useState<boolean>(false)
    const [fileToDelete, setFileToDelete] = useState<ProjectFile | null>(null)
    const [fileToEdit, setFileToEdit] = useState<ProjectFile | null>(null)
    const [fileToUpload, setFileToUpload] = useState<File | null>(null)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const status = useAppSelector((state) => state.ifcViewer.status)

    const userPermissions = useAppSelector(selectUserPermissions)
    const uploadFile = async () => {
        const payload = new FormData()
        payload.append("file", fileToUpload as Blob)
        const { type } = await dispatch(
            uploadIfcFileToModel({
                projectId,
                modelId: ifcModel.id,
                payload,
            }),
        )
        if (type === uploadIfcFileToModel.fulfilled.type) {
            setFileToUpload(null)
            setShowUploadIfc(false)
        }
    }
    return (
        <div className="shadow-lg rounded-lg p-2">
            <div
                className={`flex justify-between cursor-pointer ${isExpanded ? "border-b" : ""}`}
            >
                <div
                    className="flex gap-2 items-center"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {ifcModel.files.length > 0 && (
                        <FontAwesomeIcon
                            icon={isExpanded ? "chevron-up" : "chevron-down"}
                            className="text-primary-500"
                        />
                    )}
                    <p>{ifcModel.name}</p>
                </div>
                <div className="gap-2 flex items-center">
                    {userPermissions.hasAllPermissions([
                        `ifcModel.${ifcModel.id}.write`,
                    ]) && (
                        <>
                            <FontAwesomeIcon
                                icon="plus"
                                className="text-green-500 hover:text-green-700 transition"
                                onClick={() => setShowUploadIfc(true)}
                            />
                            <FontAwesomeIcon
                                icon="trash"
                                className="text-red-500 hover:text-red-700 transition"
                                onClick={() => setShowDeleteNotice(true)}
                            />
                        </>
                    )}
                </div>
            </div>
            {isExpanded &&
                ifcModel.files.map((file) => (
                    <div
                        key={file.id}
                        className="pl-6 flex items-center p-2 rounded-lg justify-between cursor-pointer hover:bg-primary-100 transition hover:text-secondary-100"
                        onClick={() => {
                            setSelectedModel(ifcModel)
                            setSelectedFile(file)
                        }}
                    >
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon="file"
                                className="text-primary-500"
                            />
                            <p>{file.name}</p>
                        </div>
                    </div>
                ))}
            <NewModal
                isShown={showDeleteNotice}
                closeModal={() => setShowDeleteNotice(false)}
            >
                <Form
                    statuses={[status.delete]}
                    loadingTitle={t("deleting_3d_model", {
                        model: ifcModel.name,
                    })}
                    className="w-full"
                    submitType={SubmitType.Delete}
                    onCancel={() => setShowDeleteNotice(false)}
                    onSubmit={async () => {
                        const { type } = await dispatch(
                            deleteIfcModel({
                                projectId,
                                ifcModelId: ifcModel.id,
                            }),
                        )
                        if (type === deleteIfcModel.fulfilled.type) {
                            setSelectedFile(null)
                            setShowDeleteNotice(false)
                        }
                    }}
                    clearErrors={clearErrors}
                >
                    <div className="w-full">
                        <h1 className="text-lg font-bold">
                            {t("deleting_3d_model", {
                                model: ifcModel.name,
                            })}
                        </h1>
                        <p className="text-red-400 mb-4 font-bold">
                            {t("delete_3d_model_notice")}
                        </p>
                    </div>
                </Form>
            </NewModal>
            <NewModal
                isShown={showUploadIfc}
                closeModal={() => setShowUploadIfc(false)}
            >
                <Form
                    statuses={[status.create]}
                    loadingTitle={t("uploading_ifc_file")}
                    className="w-full"
                    submitType={SubmitType.Submit}
                    onCancel={() => setShowUploadIfc(false)}
                    clearErrors={clearErrors}
                    onSubmit={uploadFile}
                >
                    <div className="w-full">
                        <FileInput
                            label={t("upload_ifc")}
                            name={t("ifc_file")}
                            value={fileToUpload}
                            setState={(file) => setFileToUpload(file as File)}
                            className="w-full"
                        />
                    </div>
                </Form>
            </NewModal>
        </div>
    )
}
